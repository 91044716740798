import React, { useState, useEffect } from "react";
import { Jeep, Boat, Airplane, TrainSimple } from "phosphor-react";
import { addDays, endOfMonth, addMonths } from "date-fns";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import { differenceInDays } from "date-fns";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Card, Form, Container, Row, Col } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function EwayBillsNew({ navigation }) {
  const [row, setRow] = useState([
    {
      itemDetails: "",
      hsnSac: "",
      size: "",
      quantity: "",
      rate: "",
      discount: 0,
      discountType: "₹",
      tax: 0,
      amount: 0,
      rowDiscount: 0,
    },
  ]);

  const jwtToken = localStorage.getItem("jwtToken");

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };

  const handleCancleClick = () => {
    handleNavigation("EwayBills");
  };

  const [bussinessRegistered, setBussinessRegistered] = useState("");
  const [discountCheck, setDiscountCheck] = useState("");
  const [sizeCheck, setSizeCheck] = useState("");
  const calculateAmount = (data, taxRate) => {
    const quantity = parseFloat(data.quantity || 0);
    const rate = parseFloat(data.rate || 0);
    const discount = parseFloat(data.discount || 0);
    const amount = quantity * rate;
    let discountedAmount = amount;
    if (data.discountType === "%") {
      discountedAmount -= (amount * discount) / 100;
    } else if (data.discountType === "₹") {
      discountedAmount -= discount;
    }
    let calculatedTaxAmount = 0;
    if (taxRate > 0) {
      calculatedTaxAmount = discountedAmount * taxRate;
    }
    const calculatedAmount = discountedAmount + calculatedTaxAmount;
    return calculatedAmount.toFixed(2);
  };

  const [custNames, setCustNames] = useState([]);
  const [fetchedInv, setFetchedInv] = useState([]);
  const [fetchedCreditNote, setFetchedCreditNote] = useState([]);
  const [fetchedDeliveryChallans, setFetchedDeliveryChallans] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState("");
  const [selectedCreditNoteNumber, setSelectedCreditNoteNumber] = useState("");
  const [selectedDeliveryChallan, setSelectedDeliveryChallan] = useState("");
  const [selectedAddressDetails, setSelectedAddressDetails] = useState("");
  const [selectedTransactionType, setSelectedTransactionType] = useState("");
  const [distance, setDistance] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [docNo, setDocNo] = useState("");
  const [docDate, setDocDate] = useState("");
  const [rrNo, setRrNo] = useState("");
  const [rrDate, setRrDate] = useState("");
  const [landingNo, setLandingNo] = useState("");
  const [landingDate, setLandingDate] = useState("");
  const [airbillNo, setAirbillNo] = useState("");
  const [airbillDate, setAirbillDate] = useState("");
  const [fetchedDate, setFetchedDate] = useState("");

  useEffect(() => {
    fetchAllCustomers();
  }, []);

  const fetchAllCustomers = () => {
    fetch(BASE_URL + `/getAllCustomerName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setCustNames(data.responseMessage);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };

  const fetchAllInvoices = (id) => {
    fetch(BASE_URL + `/fetchInvoiceByCustomerId/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        setFetchedInv(data.responseMessage);
      })
      .catch((error) => {
        console.error("Error fetching invoice numbers:", error);
      });
  };

  const fetchAllCreditNotes = (id) => {
    fetch(BASE_URL + `/fetchCreditNotesByCustomerId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, customerId: id }),
    })
      .then((response) => response.json())
      .then((data) => {
        setFetchedCreditNote(data.responseMessage);
      })
      .catch((error) => {
        console.error("Error fetching credit note:", error);
      });
  };

  const fetchAllDeliveryChalllan = (id) => {
    fetch(BASE_URL + `/fetchDeliveryChallanByCustomerId/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        setFetchedDeliveryChallans(data.responseMessage);
      })
      .catch((error) => {
        console.error("Error fetching Deliver Challan", error);
      });
  };

  const handleCustomerChange = (value, id) => {
    const selectedValue = value;
    setSelectedCustomer(selectedValue);
    const selectedCustomerObject = custNames.find(
      (person) => person.customerName === selectedValue
    );

    if (selectedDocumentType.value == "invoices") {
      fetchAllInvoices(selectedCustomerObject.id);
    }

    if (selectedDocumentType.value == "creditNotes") {
      fetchAllCreditNotes(selectedCustomerObject.id);
    }

    if (selectedDocumentType.value == "deliveryChallans") {
      fetchAllDeliveryChalllan(selectedCustomerObject.id);
    }
  };

  const handleInvoiceChange = (selectedOption) => {
    const selectedValue = selectedOption;
    setSelectedInvoiceNumber(selectedValue);
    fetch(BASE_URL + `/fetchEwayBillsByNumber`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        documentType: "Invoice",
        invoiceNumber: selectedValue.value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setFetchedDate(data.responseMessage[0].invoiceDate);
        setRow(data.responseMessage);
      })
      .catch((error) => {
        console.error("Error fetching ewaybill by number - invoice", error);
      });
  };

  const handleCreditNoteChange = (selectedOption) => {
    const selectedValue = selectedOption;
    setSelectedCreditNoteNumber(selectedValue);
    fetch(BASE_URL + `/fetchEwayBillsByNumber`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        documentType: "Credit Note",
        creditNoteNumber: selectedValue.value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setFetchedDate(data.responseMessage[0].creditNoteDate);
        setRow(data.responseMessage);
      })
      .catch((error) => {
        console.error("Error fetching ewaybill by number - credit note", error);
      });
  };

  const handleDeliveryChallanChange = (selectedOption) => {
    const selectedValue = selectedOption;
    setSelectedDeliveryChallan(selectedValue);
    fetch(BASE_URL + `/fetchEwayBillsByNumber`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        documentType: "Delivery Challan",
        deliveryChallan: selectedValue.value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setFetchedDate(data.responseMessage[0].deliveryChallanDate);
        setRow(data.responseMessage);
      })
      .catch((error) => {
        console.error("Error fetching ewaybill by number - invoice", error);
      });
  };

  const handleAddressChange = (selectedOption) => {
    const selectedValue = selectedOption;
    setSelectedAddressDetails(selectedValue);
  };

  const handleTransactionTypeChange = (selectedOption) => {
    const selectedValue = selectedOption;
    setSelectedTransactionType(selectedValue);
  };

  const handleDistanceChange = (selectedOption) => {
    const selectedValue = selectedOption.target.value;
    setDistance(selectedValue);
  };

  const handleRadioClick = (value) => {
    setVehicleType(value);
  };

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchGst`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const Data = data.responseMessage[0].businessRegistered;
          setBussinessRegistered(Data);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    fetch(BASE_URL + `/fetchDiscountCheck`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const Data = data.responseMessage.discountCheck;
          const sizeCheck = data.responseMessage.sizeCheck;
          setDiscountCheck(Data);
          setSizeCheck(sizeCheck);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const insertDataIntoDatabase = async () => {
    const itemDetails = row.map((data) => {
      return {
        itemDetails: data.itemDetails || "", // Assuming you have an input for itemDetails
        hsnSac: data.hsnSac,
        size: data.size,
        quantity: data.quantity,
        rate: data.rate,
        discount: data.discount || 0,
        discountType: data.discountType,
        tax: data.tax, // Assuming the tax rate is the same for all items
        amount: "",
        rowDiscount: "",
      };
    });

    const formData = {
      uniqueId: decryptedUniqueId,
      documentType: selectedDocumentType?.value || "",
      transactionSubType: selectedTransactionSubType?.value || "",
      customerName: selectedCustomer || "",
      invoice: selectedInvoiceNumber?.value || "",
      creditNote: selectedCreditNoteNumber?.value || "",
      deliveryChallan: selectedDeliveryChallan?.value || "",
      date: fetchedDate || "",
      transactionType: selectedTransactionType?.value || "",
      placeOfDelivery: selectedState?.value || "",
      addressDetails: selectedAddressDetails?.value || "",
      itemDetails: itemDetails,
      transporter: selectedTransporter?.value || "",
      distance: distance || 0,
      modeOfTransportation:
        (road === true && "Road") ||
        (rail === true && "Rail") ||
        (ship === true && "Ship") ||
        (air === true && "Air"),
      vehicleType: vehicleType,
      vehicleNumber: vehicleNumber || "",
      transportersDocNo: docNo || "",
      transportersDocDate: docDate || normalDateFormat(startDate),
      RRNo: rrNo || "",
      RRDate: rrDate || "",
      billOflandingNo: landingNo || "",
      billOflandingDate: landingDate || "",
      airwayBillNo: airbillNo || "",
      airwayBillNDate: airbillDate || "",
    };

    try {
      const response = await fetch(BASE_URL + `/insertEwayBill`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        swal({
          icon: "success",
          title: "EwayBill Create Successfully",
        });
        handleCancleClick();
      } else {
        swal({
          icon: "error",
          title: "Failed to insert data",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = () => {
    insertDataIntoDatabase();
  };

  const [dropdownData, setDropdownData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const results = dropdownData.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm, dropdownData]);

  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    setStartDate(new Date());
  }, []);

  const [dateValue, setDateValue] = useState("");

  const handleDate = (date) => {
    setStartDate(date);
    let normalFormat = date === null ? "" : normalDateFormat(date);
    setDateValue(normalFormat);
  };

  const normalDateFormat = (date) => {
    if (date) {
      return (
        ("0" + date.getDate()).slice(-2) +
        "-" +
        ("0" + (Number(date.getMonth()) + 1)).slice(-2) +
        "-" +
        date.getFullYear()
      );
    }
    return date;
  };

  const optionAddressDetails = [
    { value: "Address 1", label: "Address 1" },
    { value: "Address 2", label: "Address 2" },
    { value: "Address 3", label: "Address 3" },
  ];

  const options = custNames.map((customer) => ({
    value: customer.customerName,
    label: customer.customerName,
  }));

  const optionInvoices = fetchedInv.map((inv) => ({
    value: inv.invoiceNumber,
    label: inv.invoiceNumber,
  }));

  const optionCreditNotes = fetchedCreditNote.map((inv) => ({
    value: inv.creditNoteNumber,
    label: inv.creditNoteNumber,
  }));

  const optionDeliveryChallans = fetchedDeliveryChallans.map((del) => ({
    value: del.deliveryChallan,
    label: del.deliveryChallan,
  }));

  const [selectedDocumentType, setselectedDocumentType] = useState("invoies");
  const [selectedTransactionSubType, setSelectedTransactionSubType] =
    useState("");
  const [addressDetails, setAddressDetails] = useState(true);
  const [selectedState, setSelectedState] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [selectedTransporter, setSelectedTransporter] = useState(false);
  const [road, setRoad] = useState(true);
  const [rail, setRail] = useState(false);
  const [ship, setShip] = useState(false);
  const [air, setAir] = useState(false);

  const optionTransactionType = [
    { value: "Trans 1", label: "Trans 1" },
    { value: "Trans 2", label: "Trans 2" },
    { value: "Trans 3", label: "Trans 3" },
  ];

  const optionDocumentType = [
    { value: "invoices", label: "Invoices" },
    { value: "creditNotes", label: "Credit Notes" },
    { value: "deliveryChallans", label: "Delivery Challans" },
  ];

  const optionTransactionSubType = [
    { value: "suply", label: "Suply" },
    { value: "export", label: "Export" },
    { value: "skd/ckd", label: "SKD/CKD" },
  ];

  const optionTransactionSubType1 = [
    { value: "Sales Return", label: "Sales Return" },
    { value: "Job Work Return", label: "Job Work Return" },
  ];

  const optionTransactionSubType2 = [
    { value: "Job Work", label: "Job Work" },
    { value: "SKD/CKD", label: "SKD/CKD" },
    { value: "Recipient Not Known", label: "Recipient Not Known" },
    { value: "For Own Use", label: "For Own Use" },
    { value: "Exhibition or Fairs", label: "Exhibition or Fairs" },
    { value: "Line Sales", label: "Line Sales" },
    { value: "Others", label: "Others" },
  ];

  const statesOfIndia = [
    {
      label: "Andaman and Nicobar Islands",
      value: "andaman_and_nicobar_islands",
    },
    { label: "Andhra Pradesh", value: "andhra_pradesh" },
    { label: "Arunachal Pradesh", value: "arunachal_pradesh" },
    { label: "Assam", value: "assam" },
    { label: "Bihar", value: "bihar" },
    { label: "Chandigarh", value: "chandigarh" },
    { label: "Chhattisgarh", value: "chhattisgarh" },
    {
      label: "Dadra and Nagar Haveli and Daman and Diu",
      value: "dadra_and_nagar_haveli_and_daman_and_diu",
    },
    { label: "Delhi", value: "delhi" },
    { label: "Goa", value: "goa" },
    { label: "Gujarat", value: "gujarat" },
    { label: "Haryana", value: "haryana" },
    { label: "Himachal Pradesh", value: "himachal_pradesh" },
    { label: "Jammu and Kashmir", value: "jammu_and_kashmir" },
    { label: "Jharkhand", value: "jharkhand" },
    { label: "Karnataka", value: "karnataka" },
    { label: "Kerala", value: "kerala" },
    { label: "Ladakh", value: "ladakh" },
    { label: "Lakshadweep", value: "lakshadweep" },
    { label: "Madhya Pradesh", value: "madhya_pradesh" },
    { label: "Maharashtra", value: "maharashtra" },
    { label: "Manipur", value: "manipur" },
    { label: "Meghalaya", value: "meghalaya" },
    { label: "Mizoram", value: "mizoram" },
    { label: "Nagaland", value: "nagaland" },
    { label: "Odisha", value: "odisha" },
    { label: "Puducherry", value: "puducherry" },
    { label: "Punjab", value: "punjab" },
    { label: "Rajasthan", value: "rajasthan" },
    { label: "Sikkim", value: "sikkim" },
    { label: "Tamil Nadu", value: "tamil_nadu" },
    { label: "Telangana", value: "telangana" },
    { label: "Tripura", value: "tripura" },
    { label: "Uttar Pradesh", value: "uttar_pradesh" },
    { label: "Uttarakhand", value: "uttarakhand" },
    { label: "West Bengal", value: "west_bengal" },
  ];

  const optionTransporter = [
    { label: "Transporter 1", value: "Transporter 1" },
    { label: "Transporter 2", value: "Transporter 2" },
  ];

  const handleDocumentTypeChange = (value) => {
    const selectedValue = value;
    setselectedDocumentType(selectedValue);

    if (selectedValue.value === "invoices") {
    }
  };

  const handleTransactionSubTypeChange = (selectedOption) => {
    const selectedValue = selectedOption;
    setSelectedTransactionSubType(selectedValue);
  };

  const handleTableChange = () => {
    if (showTable) setShowTable(false);
    else setShowTable(true);
  };

  const handleTransporterChange = (selectedOption) => {
    const selectedValue = selectedOption;
    setSelectedTransporter(selectedValue);
  };

  const handleStateChange = (selectedOption) => {
    const selectedValue = selectedOption;
    setSelectedState(selectedValue);
  };

  return (
    <Container fluid className="">
      <Row>
        <Col>
          <div className="my-3">
            <div className="px-2 d-flex">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                }}
              >
                <ArrowBackIosIcon
                  width={2}
                  onClick={handleCancleClick}
                  className="arrow-child"
                />
              </div>
              <h4
                className="mt-1 mx-2 text-primary"
                style={{ letterSpacing: "1px" }}
              >
                New E-Way Billl
              </h4>
            </div>
          </div>
          <Card className="card-style p-2">
            <Card.Body>
              <Form>
                <Row>
                  <Col className="" md="4">
                    <Form.Group>
                      <label
                        className="label-title"
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Document Type
                      </label>
                      <Select
                        options={optionDocumentType}
                        value={selectedDocumentType}
                        onChange={handleDocumentTypeChange}
                        placeholder="Select Document Type"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            padding: "5px",
                          }),
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="" md="4">
                    <Form.Group>
                      {selectedDocumentType.value && (
                        <label
                          className=""
                          style={{ fontSize: "14px", color: "grey" }}
                        >
                          Transaction Sub Type
                        </label>
                      )}
                      {selectedDocumentType.value === "invoices" && (
                        <Select
                          options={optionTransactionSubType}
                          placeholder="Select Tranasaction Sub Type"
                          onChange={handleTransactionSubTypeChange}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              padding: "5px",
                            }),
                          }}
                        />
                      )}

                      {selectedDocumentType.value === "creditNotes" && (
                        <Select
                          options={optionTransactionSubType1}
                          placeholder="Select Tranasaction Sub Type"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              padding: "5px",
                            }),
                          }}
                        />
                      )}

                      {selectedDocumentType.value === "deliveryChallans" && (
                        <Select
                          options={optionTransactionSubType2}
                          placeholder="Select Tranasaction Sub Type"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              padding: "5px",
                            }),
                          }}
                        />
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="" md="4">
                    <Form.Group>
                      <label
                        className=""
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Customer Name <span className="valid"> *</span>
                      </label>
                      <Select
                        className="mt-0"
                        options={options}
                        value={options.find(
                          (option) => option.value === selectedCustomer
                        )}
                        onChange={(selectedOption) =>
                          handleCustomerChange(
                            selectedOption.label,
                            selectedOption.value
                          )
                        }
                        placeholder="Select Customer"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            borderRadius: "7px",
                            height: "46px",
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            height: "19px",
                          }),
                        }}
                      />
                    </Form.Group>
                  </Col>

                  {(selectedDocumentType === "invoices" ||
                    selectedDocumentType.value === "invoices") && (
                    <Col className="" md="4">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Invoices
                        </label>
                        <Select
                          options={optionInvoices}
                          value={optionInvoices.find(
                            (option) => option.value === selectedInvoiceNumber
                          )}
                          onChange={handleInvoiceChange}
                          placeholder="Select Invoice"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              padding: "5px",
                            }),
                          }}
                        />
                      </Form.Group>
                    </Col>
                  )}

                  {selectedDocumentType.value === "creditNotes" && (
                    <Col className="" md="4">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Credit Notes
                        </label>
                        <Select
                          options={optionCreditNotes}
                          value={optionCreditNotes.find(
                            (option) =>
                              option.value === selectedCreditNoteNumber
                          )}
                          placeholder="Select Credit Note"
                          onChange={handleCreditNoteChange}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              padding: "5px",
                            }),
                          }}
                        />
                      </Form.Group>
                    </Col>
                  )}

                  {selectedDocumentType.value === "deliveryChallans" && (
                    <Col className="" md="4">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Delivery Challan
                        </label>
                        <Select
                          options={optionDeliveryChallans}
                          value={optionDeliveryChallans.find(
                            (option) => option.value === selectedDeliveryChallan
                          )}
                          placeholder="Select Delivery Challan"
                          onChange={handleDeliveryChallanChange}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              padding: "5px",
                            }),
                          }}
                        />
                      </Form.Group>
                    </Col>
                  )}

                  <Col className="" md="4">
                    <Form.Group>
                      <label style={{ fontSize: "14px", color: "grey" }}>
                        Date
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          value={fetchedDate}
                          style={{
                            backgroundColor: "#F6F6F6",
                            padding: "12px",
                            borderRadius: "5px",
                          }}
                          readOnly
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="" md="4">
                    <Form.Group>
                      <label style={{ fontSize: "14px", color: "grey" }}>
                        Transaction Type
                      </label>
                      <Select
                        options={optionTransactionType}
                        value={optionTransactionType.find(
                          (option) => option.value === selectedTransactionType
                        )}
                        onChange={handleTransactionTypeChange}
                        placeholder="Select Customer"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            padding: "5px",
                          }),
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col className="" md="4">
                    <Form.Group>
                      <label style={{ fontSize: "14px", color: "grey" }}>
                        Place of Delivery
                      </label>
                      <Select
                        options={statesOfIndia}
                        value={statesOfIndia.find(
                          (option) => option.value === selectedState
                        )}
                        onChange={handleStateChange}
                        placeholder="Select State"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            padding: "5px",
                          }),
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="my-3" md="4">
                    <Form.Group>
                      <label style={{ fontSize: "14px", color: "grey" }}>
                        Address Details
                      </label>
                      {addressDetails ? (
                        <div>
                          <b>DISPATCH FROM</b>
                          <p
                            className="text-primary mt-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setAddressDetails(false);
                            }}
                          >
                            Select Address
                          </p>
                        </div>
                      ) : (
                        <Select
                          options={optionAddressDetails}
                          value={optionAddressDetails.find(
                            (option) => option.value === selectedAddressDetails
                          )}
                          onChange={handleAddressChange}
                          placeholder="Select Customer"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              padding: "5px",
                            }),
                          }}
                        />
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="" md="3">
                    <Form.Group>
                      <label
                        className="text-primary"
                        style={{ fontSize: 14, fontWeight: "600" }}
                        onClick={handleTableChange}
                      >
                        ITEM DETAILS
                      </label>
                    </Form.Group>
                  </Col>
                </Row>

                {showTable && (
                  <Row>
                    <Col>
                      <Card>
                        <div className="table-responsive">
                          <table className="table table-bordered mb-0">
                            <thead className="text-center">
                              <tr>
                                <th scope="col" style={{ color: "grey" }}>
                                  ITEM DETAILS
                                </th>
                                {bussinessRegistered === "YES" && (
                                  <th scope="col" style={{ color: "grey" }}>
                                    HSN/SAC
                                  </th>
                                )}
                                {sizeCheck === "YES" && (
                                  <th scope="col" style={{ color: "grey" }}>
                                    SIZE
                                  </th>
                                )}
                                <th scope="col" style={{ color: "grey" }}>
                                  QUANTITY
                                </th>
                                <th scope="col" style={{ color: "grey" }}>
                                  RATE
                                </th>
                                {discountCheck === "YES" && (
                                  <th scope="col" style={{ color: "grey" }}>
                                    DISCOUNT
                                  </th>
                                )}
                                {bussinessRegistered === "YES" && (
                                  <th scope="col" style={{ color: "grey" }}>
                                    Tax
                                  </th>
                                )}
                                <th scope="col" style={{ color: "grey" }}>
                                  AMOUNT
                                </th>
                                <th scope="col" style={{ color: "grey" }}>
                                  ACTION
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {row.map((data, index) => {
                                const amount = calculateAmount(data, data.tax);
                                const totalAmountWithTax = parseFloat(amount);

                                return (
                                  <tr key={index}>
                                    <td style={{ width: "35%" }}></td>
                                    <td style={{ width: "10%" }}></td>
                                    <td style={{ width: "10%" }}></td>
                                    <td style={{ width: "10%" }}></td>
                                    <td style={{ width: "10%" }}></td>
                                    <td
                                      style={{ width: "10%", padding: "0px" }}
                                    ></td>
                                    <td style={{ width: "10%" }}></td>
                                    <td style={{ width: "10%" }}></td>
                                    <td
                                      className="text-center"
                                      style={{ width: "7%" }}
                                    ></td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                )}

                <hr />

                <Row>
                  <Col className="" md="3">
                    <Form.Group>
                      <label
                        className="text-primary"
                        style={{ fontSize: 14, fontWeight: "600" }}
                      >
                        TRANSPORTATION DETAILS
                      </label>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="" md="4">
                    <Form.Group>
                      <label
                        className="label-title"
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Transporter
                      </label>
                      <Select
                        options={optionTransporter}
                        value={optionTransporter.find(
                          (option) => option.value === selectedTransporter
                        )}
                        onChange={handleTransporterChange}
                        placeholder="Select Transporter"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            padding: "5px",
                          }),
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="" md="4">
                    <Form.Group>
                      <label
                        className=""
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Distance (in Km)
                      </label>
                      <Form.Control
                        placeholder="Distance"
                        type="text"
                        name="distance"
                        value={distance}
                        onChange={handleDistanceChange}
                        style={{
                          padding: "12px",
                          backgroundColor: "#F6F6F6",
                          color: "black",
                        }}
                      />
                      <label
                        className="mt-1"
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        If you enter 0 as the distance, E-Way Bill system will
                        automatically calculate it based on the dispatch and
                        delivery locations.
                      </label>
                    </Form.Group>
                  </Col>
                  <Col className="" md="4">
                    <Form.Group>
                      <label
                        className=""
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        <a href="#">- Calculate Distance</a>
                      </label>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="" md="3">
                    <Form.Group>
                      <label
                        className="text-primary"
                        style={{ fontSize: 14, fontWeight: "600" }}
                      >
                        PART B
                      </label>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="mt-3" md="4">
                    <label
                      className="form-label"
                      style={{ fontSize: "14px", color: "grey" }}
                    >
                      Mode of Transportation
                    </label>
                  </Col>
                  <Col className="" md="4">
                    <div className="d-flex">
                      <button
                        type="button"
                        className="btn px-2 btn-light icon-border btn-sm mx-2"
                        onClick={() => {
                          setRoad(true);
                          setRail(false);
                          setShip(false);
                          setAir(false);
                        }}
                      >
                        <Jeep size={28} /> Road
                      </button>
                      <button
                        type="button"
                        className="btn px-2 btn-light icon-border btn-sm mx-2"
                        onClick={() => {
                          setRoad(false);
                          setRail(true);
                          setShip(false);
                          setAir(false);
                        }}
                      >
                        <TrainSimple size={28} /> Rail
                      </button>
                      <button
                        type="button"
                        className="btn px-2 btn-light icon-border btn-sm mx-2"
                        onClick={() => {
                          setRoad(false);
                          setRail(false);
                          setShip(true);
                          setAir(false);
                        }}
                      >
                        <Boat size={28} /> Ship
                      </button>
                      <button
                        type="button"
                        className="btn px-2 btn-light icon-border btn-sm"
                        onClick={() => {
                          setRoad(false);
                          setRail(false);
                          setShip(false);
                          setAir(true);
                        }}
                      >
                        <Airplane size={28} /> Air
                      </button>
                    </div>
                  </Col>
                </Row>

                {road && (
                  <Row className="mt-5">
                    <Col md="4" className="mb-3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Vehicle Type
                        </label>
                        <div className="d-flex my-2">
                          <label className="rdiobox">
                            <input
                              className="mx-1"
                              type="radio"
                              name="cus_type"
                              id="cus_bus"
                              value="Regular"
                              onClick={() => handleRadioClick("Regular")}
                            />
                            <span>Regular</span>
                          </label>
                          <label className="rdiobox ml-3">
                            <input
                              className="mx-1"
                              type="radio"
                              name="cus_type"
                              id="cus_indivi"
                              value="Over Dimensional Cargo"
                              onClick={() =>
                                handleRadioClick("Over Dimensional Cargo")
                              }
                            />
                            <span>Over Dimensional Cargo</span>
                          </label>
                        </div>
                      </Form.Group>
                    </Col>

                    <Col className="" md="4">
                      <Form.Group>
                        <label
                          className=""
                          style={{ fontSize: "14px", color: "grey" }}
                        >
                          Vehicle Number
                        </label>
                        <Form.Control
                          placeholder="Vehicle Number"
                          type="text"
                          name="vehiclenumber"
                          value={vehicleNumber}
                          onChange={(e) => setVehicleNumber(e.target.value)}
                          style={{
                            padding: "12px",
                            backgroundColor: "#F6F6F6",
                            color: "black",
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                {road && (
                  <Row className="mt-3">
                    <Col md="4" className="mb-3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Transporter's Doc No
                        </label>
                        <Form.Control
                          placeholder="Document Number"
                          type="text"
                          value={docNo}
                          onChange={(e) => setDocNo(e.target.value)}
                          style={{
                            padding: "12px",
                            backgroundColor: "#F6F6F6",
                            color: "black",
                          }}
                        />
                      </Form.Group>
                    </Col>

                    <Col className="" md="4">
                      <Form.Group>
                        <label
                          className=""
                          style={{ fontSize: "14px", color: "grey" }}
                        >
                          Transporter's Doc Date
                        </label>
                        <div>
                          <DatePicker
                            className="custom-datepicker"
                            selected={startDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => handleDate(date)}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                {rail && (
                  <Row className="mt-3">
                    <Col md="4" className="mb-3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          RR No
                        </label>
                        <Form.Control
                          placeholder="RR Number"
                          type="text"
                          value={rrNo}
                          onChange={(e) => setRrNo(e.target.value)}
                          style={{
                            padding: "12px",
                            backgroundColor: "#F6F6F6",
                            color: "black",
                          }}
                        />
                      </Form.Group>
                    </Col>

                    <Col className="" md="4">
                      <Form.Group>
                        <label
                          className=""
                          style={{ fontSize: "14px", color: "grey" }}
                        >
                          RR Date
                        </label>
                        <div>
                          <DatePicker
                            className="custom-datepicker"
                            selected={rrDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => setRrDate(date)}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                {ship && (
                  <Row className="mt-3">
                    <Col md="4" className="mb-3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Bill of Lading No
                        </label>
                        <Form.Control
                          placeholder="Landing Number"
                          type="text"
                          value={landingNo}
                          onChange={(e) => setLandingNo(e.target.value)}
                          style={{
                            padding: "12px",
                            backgroundColor: "#F6F6F6",
                            color: "black",
                          }}
                        />
                      </Form.Group>
                    </Col>

                    <Col className="" md="4">
                      <Form.Group>
                        <label
                          className=""
                          style={{ fontSize: "14px", color: "grey" }}
                        >
                          Bill of Lading Date
                        </label>
                        <div>
                          <DatePicker
                            className="custom-datepicker"
                            selected={landingDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => setLandingDate(date)}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                {air && (
                  <Row className="mt-3">
                    <Col md="4" className="mb-3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Airway Bill No
                        </label>
                        <Form.Control
                          placeholder="Airway Bill Number"
                          type="text"
                          value={airbillNo}
                          onChange={(e) => setAirbillNo(e.target.value)}
                          style={{
                            padding: "12px",
                            backgroundColor: "#F6F6F6",
                            color: "black",
                          }}
                        />
                      </Form.Group>
                    </Col>

                    <Col className="" md="4">
                      <Form.Group>
                        <label
                          className=""
                          style={{ fontSize: "14px", color: "grey" }}
                        >
                          Airway Bill Date
                        </label>
                        <div>
                          <DatePicker
                            className="custom-datepicker"
                            selected={airbillDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => setAirbillDate(date)}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <div className="text-right bottom-buttons">
                  <button
                    type="button"
                    className="btn-1 btn mt-0"
                    onClick={handleCancleClick}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn-3 btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Create
                  </button>
                </div>

                <div className="clearfix"></div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default EwayBillsNew;
