import React, { useState, useEffect } from "react";
import { XCircle, CaretLeft } from "phosphor-react";
import { AES, enc } from "crypto-js";
import swal from "sweetalert";
import { ArrowCircleDown } from "phosphor-react";
import { BASE_URL } from "../CommonApi";
import "./CustomerNew.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Alert from "@mui/material/Alert";
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import Select from "react-select";

function CustomerUpdate(props) {
  const [billAttention, setBillingAttention] = useState("");
  const [billCountry, setBillingCountry] = useState("");
  const [billAddress1, setBillingAddress1] = useState("");
  const [billAddress2, setBillingAddress2] = useState("");
  const [billCity, setBillingCity] = useState("");
  const [billState, setBillingState] = useState("");
  const [billZipcode, setBillingZipcode] = useState("");
  const [billPhone, setBillingPhone] = useState("");
  const [billFax, setBillingFax] = useState("");

  const [shipAttention, setShippingAttention] = useState("");
  const [shipCountry, setShippingCountry] = useState("");
  const [shipAddress1, setShippingAddress1] = useState("");
  const [shipAddress2, setShippingAddress2] = useState("");
  const [shipCity, setShippingCity] = useState("");
  const [shipState, setShippingState] = useState("");
  const [shipZipcode, setShippingZipcode] = useState("");
  const [shipPhone, setShippingPhone] = useState("");
  const [shipFax, setShippingFax] = useState("");

  const copyData = () => {
    setShippingAttention(formData.billAttention);
    setShippingCountry(billCountry);
    setShippingAddress1(formData.billAddress1);
    setShippingAddress2(formData.billAddress2);
    setShippingCity(formData.billCity);
    setShippingState(formData.billState);
    setShippingZipcode(formData.billZipcode);
    setShippingPhone(formData.billPhone);
    setShippingFax(formData.billFax);
    setFormData((prevFormData) => ({
      ...prevFormData,
      shipAttention: formData.billAttention,
      shipCountry: billCountry,
      shipAddress1: formData.billAddress1,
      shipAddress2: formData.billAddress2,
      shipCity: formData.billCity,
      shipState: billState,
      shipZipcode: formData.billZipcode,
      shipPhone: formData.billPhone,
      shipFax: formData.billFax,
    }));
  };

  const { id } = props;
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const jwtToken = localStorage.getItem("jwtToken");

  const [selectedOption, setSelectedOption] = useState("");
  const [selectedTcsOption, setSelectedTcsOption] = useState(null);
  const [selectedOther, setSelectedOther] = useState("");
  const [fetchedEmail, setFetchedEmail] = useState("");
  const [formData, setFormData] = useState({
    uniqueId: decryptedUniqueId,
    customerType: "",
    status: "Active",
    someName: "",
    firstName: "",
    lastName: "",
    companyName: "",
    customerName: "",
    email: "",
    workphone: "",
    mobile: "",
    designation: "",
    department: "",
    website: "",
    currency: "",
    openingBalance: "",
    paymentTerms: "",
    facebook: "",
    twitter: "",

    billAttention: "",
    billCountry: "",
    billAddress1: "",
    billAddress2: "",
    billCity: "",
    billState: "",
    billZipcode: "",
    billPhone: "",
    billFax: "",

    shipAttention: "",
    shipCountry: "",
    shipAddress1: "",
    shipAddress2: "",
    shipCity: "",
    shipState: "",
    shipZipcode: "",
    shipPhone: "",
    shipFax: "",

    gstin: "",
    pan: "",
    placeOfSupply: "",
    taxable: "",
    reason: "",
    activeStatus: "Active",
    gstTreatment: "",
    contactPerson: [
      {
        other: selectedOther,
        otherFirstname: "",
        otherLastname: "",
        otherEmail: "",
        otherWorkphone: "",
        otherMobile: "",
      },
    ],
  });

  const fetchData = () => {
    const postUrl = BASE_URL + `/fetchCustomerDetails/${id}`;

    fetch(postUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const Data = data.responseMessage;
        setFormData({
          customerType: Data.customerType,
          status: Data.status,
          someName: Data.someName,
          firstName: Data.firstName,
          lastName: Data.lastName,
          companyName: Data.companyName,
          customerName: Data.customerName,
          email: Data.email,
          workphone: Data.workphone,
          mobile: Data.mobile,
          designation: Data.designation,
          department: Data.department,

          website: Data.website,
          currency: Data.currency,
          openingBalance: Data.openingBalance,
          paymentTerms: Data.paymentTerms,
          facebook: Data.facebook,
          twitter: Data.twitter,

          billAttention: Data.billAttention,
          billCountry: Data.billCountry,
          billAddress1: Data.billAddress1,
          billAddress2: Data.billAddress2,
          billCity: Data.billCity,
          billState: Data.billState,
          billZipcode: Data.billZipcode,
          billPhone: Data.billPhone,
          billFax: Data.billFax,

          shipAttention: Data.shipAttention,
          shipCountry: Data.shipCountry,
          shipAddress1: Data.shipAddress1,
          shipAddress2: Data.shipAddress2,
          shipCity: Data.shipCity,
          shipState: Data.shipState,
          shipZipcode: Data.shipZipcode,
          shipPhone: Data.shipPhone,
          shipFax: Data.shipFax,

          gstin: Data.gstin,
          pan: Data.pan,
          placeOfSupply: Data.placeOfSupply,
          taxable: Data.taxable,
          reason: "N/A",
          gstTreatment: Data.gstTreatment,
          activeStatus: "Active",
        });
        setFetchedEmail(Data.email);
        fetchContactPerson();
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  const fetchContactPerson = () => {
    const postUrl = BASE_URL + `/getCustomerAllContactPerson/${id}`;

    fetch(postUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const Data = data.responseMessage;
        Data.map((contact) => ({
          other: contact.other,
          otherFirstname: contact.firstName,
          otherLastname: contact.lastName,
          otherEmail: contact.email,
          otherWorkphone: contact.workphone,
          otherMobile: contact.mobile,
        }));

        const extractedData = Data.map((item) => ({
          other: item.other,
          otherFirstname: item.otherFirstname,
          otherLastname: item.otherLastname,
          otherEmail: item.otherEmail,
          otherWorkphone: item.otherWorkphone,
          otherMobile: item.otherMobile,
        }));

        const contactPersonData = data.responseMessage;
        // setFormData({
        //   ...formData,
        //   contactPerson: contactPersonData,
        // });

        setFormData((prevData) => ({
          ...prevData,
          contactPerson: extractedData,
        }));
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [row, setRow] = useState([1]);

  //   const handleAdd = () => {
  //     const val = [...row, []];
  //     setRow(val);
  //   };
  const handleAdd = () => {
    const newContactPerson = {
      other: "",
      otherFirstname: "",
      otherLastname: "",
      otherEmail: "",
      otherWorkphone: "",
      otherMobile: "",
    };

    setFormData((prevData) => {
      const contactPersonArray = Array.isArray(prevData.contactPerson)
        ? prevData.contactPerson
        : [];
      return {
        ...prevData,
        contactPerson: [...contactPersonArray, newContactPerson],
      };
    });
  };

  //   const handleDelete = (i) => {
  //     const val = [...row];
  //     val.splice(i, 1);
  //     setRow(val);
  //   };

  const handleDelete = (i) => {
    const updatedContactPerson = [...formData.contactPerson];
    updatedContactPerson.splice(i, 1);
    setFormData((prevData) => ({
      ...prevData,
      contactPerson: updatedContactPerson,
    }));
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      props.handleCancle();
    } else {
      console.log("Cancellation not confirmed");
    }
  };

  const [new1, showNew1] = useState(true);
  const [new2, showNew2] = useState(false);
  const [new3, showNew3] = useState(false);

  const [errors, setErrors] = useState({});

  const validation = () => {
    let errors = {};
    const namePattern = /^[A-Za-z\s]+$/; // Allows spaces
    const passPattern = /^[A-Za-z0-9_@./#&+-]*$/;
    const contactPattern = /^[6-9]{1}[0-9]{9}$/;
    const emailPattern = /^[a-z0-9._%+-]+@[a-z]+\.[a-z]{2,4}$/;

    if (!formData.customerName) {
      errors.customerName = "Enter a valid Customer Name";
    } else if (!namePattern.test(formData.customerName)) {
      errors.customerName = "Enter a valid Customer Name";
    }
    return errors;
  };

  const [validationMessage, setValidationMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleSaveClick = () => {
    const { id } = props;
    let newValidationMessage = "";
    if (!formData.customerName) {
      newValidationMessage += "Please enter a customer display name.\n";
    }
    if (
      formData.email &&
      !/^[a-z0-9._%+-]+@[a-z]+\.[a-z]{2,4}(\.[a-z]{2,4})?$/.test(formData.email)
    ) {
      newValidationMessage +=
        "Invalid Email Address. Please enter a valid email.\n";
    }
    if (formData.mobile && !/^\d{10}$/.test(formData.mobile)) {
      newValidationMessage +=
        "Invalid Mobile Number. Please enter a 10-digit number.\n";
    }
    if (formData.workphone && !/^\d{10}$/.test(formData.workphone)) {
      newValidationMessage +=
        "Invalid Phone Number. Please enter a 10-digit number.\n";
    }
    if (formData.billPhone && !/^\d{10}$/.test(formData.billPhone)) {
      newValidationMessage +=
        "Invalid Billing Phone Number. Please enter a 10-digit number.\n";
    }
    if (formData.billZipcode && !/^\d{5,6}$/.test(formData.billZipcode)) {
      newValidationMessage +=
        "Invalid Zipcode. Please enter a valid 5 or 6-digit zipcode.\n";
    }

    // if (formData.shipPhone && !/^\d{10}$/.test(formData.shipPhone)) {
    //   newValidationMessage += "Invalid Shipping Phone Number. Please enter a 10-digit number.\n";
    // }

    formData.contactPerson.forEach((person, index) => {
      if (person.otherMobile && !/^\d{10}$/.test(person.otherMobile)) {
        newValidationMessage += `Invalid Contact Person Mobile Number for contact ${
          index + 1
        }. Please enter a 10-digit number.\n`;
      }
      if (person.otherWorkphone && !/^\d{10}$/.test(person.otherWorkphone)) {
        newValidationMessage += `Invalid Contact Person Work Phone Number for contact ${
          index + 1
        }. Please enter a 10-digit number.\n`;
      }
      if (
        person.otherEmail &&
        !/^[a-z0-9._%+-]+@[a-z]+\.[a-z]{2,4}(\.[a-z]{2,4})?$/.test(
          person.otherEmail
        )
      ) {
        newValidationMessage +=
          "Invalid Contact Person Email Address. Please enter a valid email.\n";
      }
    });

    setValidationMessage(newValidationMessage);
    if (newValidationMessage) {
      window.scrollTo(0, 0);
      return; // Exit function if validation fails
    }
    const requestData = {
      ...formData,
      oldEmail: fetchedEmail || "",
      id: id,
    };

    setIsLoading(true);

    fetch(BASE_URL + "/updateCustomer", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          swal({
            icon: "success",
            title: "Updated Customer",
            text: "",
          });
          props.handleCancle();
          setIsLoading(false);
        } else if (data.responseCode === "400") {
          swal({
            icon: "error",
            title: "This email already exists, please insert another email.",
            text: "",
          });
          setIsLoading(false);
        } else {
          swal({
            icon: "error",
            title: "Failed to Update Customer!",
            text: "",
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Network Error",
          text: "An error occurred while communicating with the server.",
        });
        setIsLoading(false);
      });
  };

  const handleRadioChange = (e, value) => {
    setFormData({
      ...formData,
      customerType: value,
    });
  };

  const [showTaxable, setShowTaxable] = useState(true);

  const handleTaxable = (e, value) => {
    setFormData({
      ...formData,
      taxable: value,
    });
    if (value === "Taxable") {
      setShowTaxable(true);
    }
    if (value === "Non-Taxable") {
      setShowTaxable(false);
    }
  };

  const handleOtherChange = (event, index) => {
    const { name, value } = event.target;
    const updatedFormData = { ...formData };
    updatedFormData.contactPerson[index][name] = value;
    setFormData(updatedFormData);
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePanChange = (event) => {
    setFormData({
      ...formData,
      pan: event.target.value,
    });
  };

  const handleGSTtreatment = (selectedOption) => {
    setSelectedOption(selectedOption.value); // Assuming you want to store the selected value in selectedOption state
    setFormData({
      ...formData,
      gstTreatment: selectedOption.value,
    });
  };

  const handleGstChange = (event) => {
    setFormData({
      ...formData,
      gstin: event.target.value,
    });
  };
  const generateNamePairs = () => {
    const { someName, firstName, lastName, companyName } = formData;
    const pairs = [];

    if (firstName && lastName) {
      pairs.push(`${someName} ${firstName} ${lastName}`);
      pairs.push(`${firstName} ${lastName}`);
      pairs.push(`${lastName} , ${firstName}`);
      pairs.push(`${companyName}`);
    }

    return pairs;
  };

  const unitSalution = [
    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Ms.", label: "Ms." },
    { value: "Miss.", label: "Miss." },
    { value: "Dr.", label: "Dr." },
  ];

  const GstTreatment = [
    { value: "", label: "Select a GST Treatment", isDisabled: true },
    {
      value: "Registered Business - Regular",
      label: "Registered Business - Regular",
    },
    {
      value: "Registered Business - Composition",
      label: "Registered Business - Composition",
    },
    { value: "Unregistered Business", label: "Unregistered Business" },
    { value: "Consumer", label: "Consumer" },
    { value: "Overseas", label: "Overseas" },
    { value: "Special Economic Zone", label: "Special Economic Zone" },
    { value: "Deemed Export", label: "Deemed Export" },
    { value: "Tax Deductor", label: "Tax Deductor" },
    { value: "SEZ Developer", label: "SEZ Developer" },
  ];

  const PlaceOfSupplyOptions = [
    { value: "", label: "Source Of Supply", isDisabled: true },
    {
      value: "[AN] - Andaman and Nicobar Islands",
      label: "[AN] - Andaman and Nicobar Islands",
    },
    { value: "[AD] - Andhra Pradesh", label: "[AD] - Andhra Pradesh" },
    { value: "[AR] - Arunachal Pradesh", label: "[AR] - Arunachal Pradesh" },
    { value: "[AS] - Assam", label: "[AS] - Assam" },
    { value: "[BR] - Bihar", label: "[BR] - Bihar" },
    { value: "[CH] - Chandigarh", label: "[CH] - Chandigarh" },
    { value: "[CG] - Chhattisgarh", label: "[CG] - Chhattisgarh" },
    {
      value: "[DN] - Dadra and Nagar Haveli and Daman and Diu",
      label: "[DN] - Dadra and Nagar Haveli and Daman and Diu",
    },
    { value: "[DD] - Daman and Diu", label: "[DD] - Daman and Diu" },
    { value: "[DL] - Delhi", label: "[DL] - Delhi" },
    { value: "[GA] - Goa", label: "[GA] - Goa" },
    { value: "[GJ] - Gujarat", label: "[GJ] - Gujarat" },
    { value: "[HR] - Haryana", label: "[HR] - Haryana" },
    { value: "[HP] - Himachal Pradesh", label: "[HP] - Himachal Pradesh" },
    { value: "[JK] - Jammu and Kashmir", label: "[JK] - Jammu and Kashmir" },
    { value: "[JH] - Jharkhand", label: "[JH] - Jharkhand" },
    { value: "[KA] - Karnataka", label: "[KA] - Karnataka" },
    { value: "[KL] - Kerala", label: "[KL] - Kerala" },
    { value: "[LA] - Ladakh", label: "[LA] - Ladakh" },
    { value: "[LD] - Lakshadweep", label: "[LD] - Lakshadweep" },
    { value: "[MP] - Madhya Pradesh", label: "[MP] - Madhya Pradesh" },
    { value: "[MH] - Maharashtra", label: "[MH] - Maharashtra" },
    { value: "[MN] - Manipur", label: "[MN] - Manipur" },
    { value: "[ML] - Meghalaya", label: "[ML] - Meghalaya" },
    { value: "[MZ] - Mizoram", label: "[MZ] - Mizoram" },
    { value: "[NL] - Nagaland", label: "[NL] - Nagaland" },
    { value: "[OD] - Odisha", label: "[OD] - Odisha" },
    { value: "[OT] - Other Territory", label: "[OT] - Other Territory" },
    { value: "[PY] - Puducherry", label: "[PY] - Puducherry" },
    { value: "[PB] - Punjab", label: "[PB] - Punjab" },
    { value: "[RJ] - Rajasthan", label: "[RJ] - Rajasthan" },
    { value: "[SK] - Sikkim", label: "[SK] - Sikkim" },
    { value: "[TN] - Tamil Nadu", label: "[TN] - Tamil Nadu" },
    { value: "[TS] - Telangana", label: "[TS] - Telangana" },
    { value: "[TR] - Tripura", label: "[TR] - Tripura" },
    { value: "[UP] - Uttar Pradesh", label: "[UP] - Uttar Pradesh" },
    { value: "[UK] - Uttarakhand", label: "[UK] - Uttarakhand" },
    { value: "[WB] - West Bengal", label: "[WB] - West Bengal" },
  ];

  const CurrencyOptions = [
    { value: "", label: "Select Currency ...", isDisabled: true },
    { value: "AED- UAE Dirham", label: "AED- UAE Dirham" },
    { value: "AUD- Australian Dollar", label: "AUD- Australian Dollar" },
    { value: "CAD- Canadian Dollar", label: "CAD- Canadian Dollar" },
    { value: "CNY- Yuan Renminbi", label: "CNY- Yuan Renminbi" },
    { value: "EUR- Euro", label: "EUR- Euro" },
    { value: "GBP- Pound Sterling", label: "GBP- Pound Sterling" },
    { value: "INR- Indian Rupee", label: "INR- Indian Rupee" },
    { value: "JPY- Japanese Yen", label: "JPY- Japanese Yen" },
    { value: "SAR- Saudi Riyal", label: "SAR- Saudi Riyal" },
    { value: "USD- United States Dollar", label: "USD- United States Dollar" },
    { value: "ZAR- South African Rand", label: "ZAR- South African Rand" },
  ];

  const PaymentTermsOptions = [
    { value: "", label: "Select Payment Terms", isDisabled: true },
    { value: "Net 15", label: "Net 15" },
    { value: "Net 30", label: "Net 30" },
    { value: "Net 45", label: "Net 45" },
    { value: "Net 60", label: "Net 60" },
    { value: "Due end of the month", label: "Due end of the month" },
    { value: "Due end of next month", label: "Due end of next month" },
    { value: "Due on Receipt", label: "Due on Receipt" },
  ];

  const countryOptions = [
    { value: "Afghanistan", label: "Afghanistan" },
    { value: "Albania", label: "Albania" },
    { value: "Algeria", label: "Algeria" },
    { value: "American Samoa", label: "American Samoa" },
    { value: "Andorra", label: "Andorra" },
    { value: "Angola", label: "Angola" },
    { value: "Anguilla", label: "Anguilla" },
    { value: "Antarctica", label: "Antarctica" },
    { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
    { value: "Argentina", label: "Argentina" },
    { value: "Armenia", label: "Armenia" },
    { value: "Aruba", label: "Aruba" },
    { value: "Australia", label: "Australia" },
    { value: "Austria", label: "Austria" },
    { value: "Azerbaijan", label: "Azerbaijan" },
    { value: "Bahamas", label: "Bahamas" },
    { value: "Bahrain", label: "Bahrain" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Barbados", label: "Barbados" },
    { value: "Belarus", label: "Belarus" },
    { value: "Belgium", label: "Belgium" },
    { value: "Belize", label: "Belize" },
    { value: "Benin", label: "Benin" },
    { value: "Bermuda", label: "Bermuda" },
    { value: "Bhutan", label: "Bhutan" },
    { value: "Bolivia", label: "Bolivia" },
    { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
    { value: "Botswana", label: "Botswana" },
    { value: "Bouvet Island", label: "Bouvet Island" },
    { value: "Brazil", label: "Brazil" },
    {
      value: "British Indian Ocean Territory",
      label: "British Indian Ocean Territory",
    },
    { value: "Brunei Darussalam", label: "Brunei Darussalam" },
    { value: "Bulgaria", label: "Bulgaria" },
    { value: "Burkina Faso", label: "Burkina Faso" },
    { value: "Burundi", label: "Burundi" },
    { value: "Cambodia", label: "Cambodia" },
    { value: "Cameroon", label: "Cameroon" },
    { value: "Canada", label: "Canada" },
    { value: "Cape Verde", label: "Cape Verde" },
    { value: "Cayman Islands", label: "Cayman Islands" },
    { value: "Central African Republic", label: "Central African Republic" },
    { value: "Chad", label: "Chad" },
    { value: "Chile", label: "Chile" },
    { value: "China", label: "China" },
    { value: "Christmas Island", label: "Christmas Island" },
    { value: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands" },
    { value: "Colombia", label: "Colombia" },
    { value: "Comoros", label: "Comoros" },
    { value: "Congo", label: "Congo" },
    {
      value: "Congo, the Democratic Republic of the",
      label: "Congo, the Democratic Republic of the",
    },
    { value: "Cook Islands", label: "Cook Islands" },
    { value: "Costa Rica", label: "Costa Rica" },
    { value: "Côte d'Ivoire", label: "Côte d'Ivoire" },
    { value: "Croatia", label: "Croatia" },
    { value: "Cuba", label: "Cuba" },
    { value: "Cyprus", label: "Cyprus" },
    { value: "Czech Republic", label: "Czech Republic" },
    { value: "Denmark", label: "Denmark" },
    { value: "Djibouti", label: "Djibouti" },
    { value: "Dominica", label: "Dominica" },
    { value: "Dominican Republic", label: "Dominican Republic" },
    { value: "Ecuador", label: "Ecuador" },
    { value: "Egypt", label: "Egypt" },
    { value: "El Salvador", label: "El Salvador" },
    { value: "Equatorial Guinea", label: "Equatorial Guinea" },
    { value: "Eritrea", label: "Eritrea" },
    { value: "Estonia", label: "Estonia" },
    { value: "Ethiopia", label: "Ethiopia" },
    {
      value: "Falkland Islands (Malvinas)",
      label: "Falkland Islands (Malvinas)",
    },
    { value: "Faroe Islands", label: "Faroe Islands" },
    { value: "Fiji", label: "Fiji" },
    { value: "Finland", label: "Finland" },
    { value: "France", label: "France" },
    { value: "French Guiana", label: "French Guiana" },
    { value: "French Polynesia", label: "French Polynesia" },
    {
      value: "French Southern Territories",
      label: "French Southern Territories",
    },
    { value: "Gabon", label: "Gabon" },
    { value: "Gambia", label: "Gambia" },
    { value: "Georgia", label: "Georgia" },
    { value: "Germany", label: "Germany" },
    { value: "Ghana", label: "Ghana" },
    { value: "Gibraltar", label: "Gibraltar" },
    { value: "Greece", label: "Greece" },
    { value: "Greenland", label: "Greenland" },
    { value: "Grenada", label: "Grenada" },
    { value: "Guadeloupe", label: "Guadeloupe" },
    { value: "Guam", label: "Guam" },
    { value: "Guatemala", label: "Guatemala" },
    { value: "Guernsey", label: "Guernsey" },
    { value: "Guinea", label: "Guinea" },
    { value: "Guinea-Bissau", label: "Guinea-Bissau" },
    { value: "Guyana", label: "Guyana" },
    { value: "Haiti", label: "Haiti" },
    {
      value: "Heard Island and McDonald Islands",
      label: "Heard Island and McDonald Islands",
    },
    {
      value: "Holy See (Vatican City State)",
      label: "Holy See (Vatican City State)",
    },
    { value: "Honduras", label: "Honduras" },
    { value: "Hong Kong", label: "Hong Kong" },
    { value: "Hungary", label: "Hungary" },
    { value: "Iceland", label: "Iceland" },
    { value: "India", label: "India" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "Iran, Islamic Republic of", label: "Iran, Islamic Republic of" },
    { value: "Iraq", label: "Iraq" },
    { value: "Ireland", label: "Ireland" },
    { value: "Isle of Man", label: "Isle of Man" },
    { value: "Israel", label: "Israel" },
    { value: "Italy", label: "Italy" },
    { value: "Jamaica", label: "Jamaica" },
    { value: "Japan", label: "Japan" },
    { value: "Jersey", label: "Jersey" },
    { value: "Jordan", label: "Jordan" },
    { value: "Kazakhstan", label: "Kazakhstan" },
    { value: "Kenya", label: "Kenya" },
    { value: "Kiribati", label: "Kiribati" },
    {
      value: "Korea, Democratic People's Republic of",
      label: "Korea, Democratic People's Republic of",
    },
    { value: "Korea, Republic of", label: "Korea, Republic of" },
    { value: "Kuwait", label: "Kuwait" },
    { value: "Kyrgyzstan", label: "Kyrgyzstan" },
    {
      value: "Lao People's Democratic Republic",
      label: "Lao People's Democratic Republic",
    },
    { value: "Latvia", label: "Latvia" },
    { value: "Lebanon", label: "Lebanon" },
    { value: "Lesotho", label: "Lesotho" },
    { value: "Liberia", label: "Liberia" },
    { value: "Libyan Arab Jamahiriya", label: "Libyan Arab Jamahiriya" },
    { value: "Liechtenstein", label: "Liechtenstein" },
    { value: "Lithuania", label: "Lithuania" },
    { value: "Luxembourg", label: "Luxembourg" },
    { value: "Macao", label: "Macao" },
    {
      value: "Macedonia, the former Yugoslav Republic of",
      label: "Macedonia, the former Yugoslav Republic of",
    },
    { value: "Madagascar", label: "Madagascar" },
    { value: "Malawi", label: "Malawi" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "Maldives", label: "Maldives" },
    { value: "Mali", label: "Mali" },
    { value: "Malta", label: "Malta" },
    { value: "Marshall Islands", label: "Marshall Islands" },
    { value: "Martinique", label: "Martinique" },
    { value: "Mauritania", label: "Mauritania" },
    { value: "Mauritius", label: "Mauritius" },
    { value: "Mayotte", label: "Mayotte" },
    { value: "Mexico", label: "Mexico" },
    {
      value: "Micronesia, Federated States of",
      label: "Micronesia, Federated States of",
    },
    { value: "Moldova, Republic of", label: "Moldova, Republic of" },
    { value: "Monaco", label: "Monaco" },
    { value: "Mongolia", label: "Mongolia" },
    { value: "Montenegro", label: "Montenegro" },
    { value: "Montserrat", label: "Montserrat" },
    { value: "Morocco", label: "Morocco" },
    { value: "Mozambique", label: "Mozambique" },
    { value: "Myanmar", label: "Myanmar" },
    { value: "Namibia", label: "Namibia" },
    { value: "Nauru", label: "Nauru" },
    { value: "Nepal", label: "Nepal" },
    { value: "Netherlands", label: "Netherlands" },
    { value: "Netherlands Antilles", label: "Netherlands Antilles" },
    { value: "New Caledonia", label: "New Caledonia" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Nicaragua", label: "Nicaragua" },
    { value: "Niger", label: "Niger" },
    { value: "Nigeria", label: "Nigeria" },
    { value: "Niue", label: "Niue" },
    { value: "Norfolk Island", label: "Norfolk Island" },
    { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
    { value: "Norway", label: "Norway" },
    { value: "Oman", label: "Oman" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Palau", label: "Palau" },
    {
      value: "Palestinian Territory, Occupied",
      label: "Palestinian Territory, Occupied",
    },
    { value: "Panama", label: "Panama" },
    { value: "Papua New Guinea", label: "Papua New Guinea" },
    { value: "Paraguay", label: "Paraguay" },
    { value: "Peru", label: "Peru" },
    { value: "Philippines", label: "Philippines" },
    { value: "Pitcairn", label: "Pitcairn" },
    { value: "Poland", label: "Poland" },
    { value: "Portugal", label: "Portugal" },
    { value: "Puerto Rico", label: "Puerto Rico" },
    { value: "Qatar", label: "Qatar" },
    { value: "Réunion", label: "Réunion" },
    { value: "Romania", label: "Romania" },
    { value: "Russian Federation", label: "Russian Federation" },
    { value: "Rwanda", label: "Rwanda" },
    { value: "Saint Barthélemy", label: "Saint Barthélemy" },
    {
      value: "Saint Helena, Ascension and Tristan da Cunha",
      label: "Saint Helena, Ascension and Tristan da Cunha",
    },
    { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
    { value: "Saint Lucia", label: "Saint Lucia" },
    {
      value: "Saint Martin (French part)",
      label: "Saint Martin (French part)",
    },
    { value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
    {
      value: "Saint Vincent and the Grenadines",
      label: "Saint Vincent and the Grenadines",
    },
    { value: "Samoa", label: "Samoa" },
    { value: "San Marino", label: "San Marino" },
    { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
    { value: "Saudi Arabia", label: "Saudi Arabia" },
    { value: "Senegal", label: "Senegal" },
    { value: "Serbia", label: "Serbia" },
    { value: "Seychelles", label: "Seychelles" },
    { value: "Sierra Leone", label: "Sierra Leone" },
    { value: "Singapore", label: "Singapore" },
    { value: "Slovakia", label: "Slovakia" },
    { value: "Slovenia", label: "Slovenia" },
    { value: "Solomon Islands", label: "Solomon Islands" },
    { value: "Somalia", label: "Somalia" },
    { value: "South Africa", label: "South Africa" },
    {
      value: "South Georgia and the South Sandwich Islands",
      label: "South Georgia and the South Sandwich Islands",
    },
    { value: "Spain", label: "Spain" },
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "Sudan", label: "Sudan" },
    { value: "Suriname", label: "Suriname" },
    { value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
    { value: "Swaziland", label: "Swaziland" },
    { value: "Sweden", label: "Sweden" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "Syrian Arab Republic", label: "Syrian Arab Republic" },
    { value: "Taiwan, Province of China", label: "Taiwan, Province of China" },
    { value: "Tajikistan", label: "Tajikistan" },
    {
      value: "Tanzania, United Republic of",
      label: "Tanzania, United Republic of",
    },
    { value: "Thailand", label: "Thailand" },
    { value: "Timor-Leste", label: "Timor-Leste" },
    { value: "Togo", label: "Togo" },
    { value: "Tokelau", label: "Tokelau" },
    { value: "Tonga", label: "Tonga" },
    { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
    { value: "Tunisia", label: "Tunisia" },
    { value: "Turkey", label: "Turkey" },
    { value: "Turkmenistan", label: "Turkmenistan" },
    { value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
    { value: "Tuvalu", label: "Tuvalu" },
    { value: "Uganda", label: "Uganda" },
    { value: "Ukraine", label: "Ukraine" },
    { value: "United Arab Emirates", label: "United Arab Emirates" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "United States", label: "United States" },
    {
      value: "United States Minor Outlying Islands",
      label: "United States Minor Outlying Islands",
    },
    { value: "Uruguay", label: "Uruguay" },
    { value: "Uzbekistan", label: "Uzbekistan" },
    { value: "Vanuatu", label: "Vanuatu" },
    {
      value: "Venezuela, Bolivarian Republic of",
      label: "Venezuela, Bolivarian Republic of",
    },
    { value: "Viet Nam", label: "Viet Nam" },
    { value: "Virgin Islands, British", label: "Virgin Islands, British" },
    { value: "Virgin Islands, U.S.", label: "Virgin Islands, U.S." },
    { value: "Wallis and Futuna", label: "Wallis and Futuna" },
    { value: "Western Sahara", label: "Western Sahara" },
    { value: "Yemen", label: "Yemen" },
    { value: "Zambia", label: "Zambia" },
    { value: "Zimbabwe", label: "Zimbabwe" },
  ];

  const handleCountryChange = (selectedOption) => {
    setBillingCountry(selectedOption.value);
  };

  const indianStateOptions = [
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    {
      value: "Andaman and Nicobar Islands",
      label: "Andaman and Nicobar Islands",
    },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chandigarh", label: "Chandigarh" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Dadra and Nagar Haveli", label: "Dadra and Nagar Haveli" },
    { value: "Daman and Diu", label: "Daman and Diu" },
    { value: "Delhi", label: "Delhi" },
    { value: "Lakshadweep", label: "Lakshadweep" },
    { value: "Puducherry", label: "Puducherry" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jammu and Kashmir", label: "Jammu and Kashmir" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },
  ];

  const handleShipStateChange = (selectedOption) => {
    setShippingCountry(selectedOption.value);
  };

  const handleStateChange = (selectedOption) => {
    setBillingState(selectedOption.value);
    setFormData({
      ...formData,
      billState: selectedOption.value,
    });
  };

  const handleTcsOptionChange = (selectedOption) => {
    console.group("selectedOptionnnn : ", selectedOption.value);
    setSelectedTcsOption(selectedOption.value);
  };

  const handleCustomerChange = (selectedOption) => {
    const { value } = selectedOption;
    setFormData((prevData) => ({
      ...prevData,
      customerName: value,
    }));
  };

  const handlePlaceOfSupply = (selectedOption) => {
    setSelectedOption(selectedOption.value); // Update selected option
    setFormData({
      ...formData,
      placeOfSupply: selectedOption.value, // Update gstTreatment in formData
    });
  };

  const handleCurrency = (selectedOption) => {
    setSelectedOption(selectedOption); // Update selected option
    setFormData({
      ...formData,
      currency: selectedOption.value, // Update gstTreatment in formData
    });
  };

  const handlePaymentTerms = (selectedOption) => {
    setSelectedOption(selectedOption); // Update selected option
    setFormData({
      ...formData,
      paymentTerms: selectedOption.value, // Update gstTreatment in formData
    });
  };

  const handleOtherSalutation = (selectedOption) => {
    setSelectedOther(selectedOption.value);
  };

  const handleSalutationChange = (event) => {
    setFormData({
      ...formData,
      someName: event.value,
    });
  };

  return (
    <>
      <Container fluid className="">
        <Row>
          <Col>
            <div className="my-3">
              <div className="d-flex align-items-center ml-1 mt-4">
                <div
                  className="cursor-pointer d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "50%",
                    height: "35px",
                    width: "38px",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIosIcon
                    width={2}
                    onClick={handleCancleClick}
                    className="arrow-child"
                  />
                </div>
                <div className="newcustomer-head ml-2">
                  <h5
                    className="ml-1 mb-0"
                    style={{ letterSpacing: "1px", color: "#086bd5de" }}
                  >
                    Update Customer
                  </h5>
                </div>
              </div>
            </div>
            <Card className=" card-style">
              <div className="my-2">
                {validationMessage && (
                  <Alert severity="error" className="mt-3">
                    {validationMessage.split("\n").map((message, index) => (
                      <div key={index}>{message}</div>
                    ))}
                  </Alert>
                )}{" "}
              </div>
              <Card.Body>
                <Row>
                  <Col md="3" className="">
                    <Form.Group>
                      <label style={{ fontSize: "14px", color: "grey" }}>
                        Customer type*
                      </label>
                      <div className="d-flex my-2">
                        <label className="rdiobox">
                          <input
                            className="mx-1"
                            type="radio"
                            name="customerType"
                            id="cus_bus"
                            value="Business"
                            checked={formData.customerType === "Business"}
                            onChange={handleChange}
                          />
                          <span>Business</span>
                        </label>
                        <label className="rdiobox ml-3">
                          <input
                            className="mx-1"
                            type="radio"
                            name="customerType"
                            id="cus_indivi"
                            value="Individual"
                            checked={formData.customerType === "Individual"}
                            onChange={handleChange}
                          />
                          <span>Individual</span>
                        </label>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="3" className="">
                    <Form.Group>
                      <label style={{ fontSize: "14px", color: "grey" }}>
                        Salutation
                      </label>
                      <Select
                        className="mt-0"
                        name="someName"
                        options={unitSalution}
                        value={unitSalution.find(
                          (option) => option.value === formData.someName
                        )}
                        onChange={handleSalutationChange}
                        placeholder="Select"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            // border:'none',
                            borderRadius: "8px",
                            padding: "4px",
                          }),
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md="3" className="">
                    <Form.Group>
                      <label style={{ fontSize: "14px", color: "grey" }}>
                        First Name
                      </label>
                      <Form.Control
                        placeholder="Enter First name"
                        name="firstName"
                        id="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        type="text"
                        style={{
                          backgroundColor: "#F6F6F6",
                          padding: "10px",
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md="3" className="">
                    <Form.Group>
                      <label style={{ fontSize: "14px", color: "grey" }}>
                        Last Name
                      </label>
                      <Form.Control
                        className="form-control"
                        placeholder="Enter Last name"
                        id="lastName"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        type="text"
                        style={{
                          backgroundColor: "#F6F6F6",
                          padding: "10px",
                        }}
                      />
                    </Form.Group>
                  </Col>

                  {/* <div className="row d-inline-flex pt-3 ml-2"> */}
                  <Col md="3">
                    <Form.Group>
                      <label style={{ fontSize: "14px", color: "grey" }}>
                        Customer Display Name{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <Form.Control
                        className="form-control"
                        placeholder="Customer Display Name"
                        list="customerNames"
                        name="customerName"
                        value={formData.customerName}
                        onChange={handleChange}
                        type="text"
                        style={{
                          backgroundColor: "#F6F6F6",
                          padding: "10px",
                          borderRadius: "6px",
                        }}
                      />
                      <datalist id="customerNames">
                        {generateNamePairs().map((pair, index) => (
                          <option key={index} value={pair} />
                        ))}
                      </datalist>{" "}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="3">
                    <Form.Group>
                      <label style={{ fontSize: "14px", color: "grey" }}>
                        Customer Email
                      </label>
                      <Form.Control
                        placeholder="Customer Email"
                        name="email"
                        type="text"
                        value={formData.email}
                        onChange={handleChange}
                        style={{
                          backgroundColor: "#F6F6F6",
                          padding: "10px",
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group>
                      <label style={{ fontSize: "14px", color: "grey" }}>
                        Designation
                      </label>
                      <Form.Control
                        placeholder="Designation"
                        name="designation"
                        type="text"
                        value={formData.designation}
                        onChange={handleChange}
                        style={{
                          backgroundColor: "#F6F6F6",
                          padding: "10px",
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group>
                      <label style={{ fontSize: "14px", color: "grey" }}>
                        Department
                      </label>
                      <Form.Control
                        placeholder="Department"
                        name="department"
                        type="text"
                        value={formData.department}
                        onChange={handleChange}
                        style={{
                          backgroundColor: "#F6F6F6",
                          padding: "10px",
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group>
                      <label style={{ fontSize: "14px", color: "grey" }}>
                        Website
                      </label>
                      <Form.Control
                        placeholder="Website"
                        name="website"
                        type="text"
                        value={formData.website}
                        onChange={handleChange}
                        style={{
                          backgroundColor: "#F6F6F6",
                          padding: "10px",
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="3">
                    <Form.Group>
                      <label style={{ fontSize: "14px", color: "grey" }}>
                        Customer Phone
                      </label>
                      <Form.Control
                        placeholder="Phone"
                        name="workphone"
                        type="text"
                        value={formData.workphone}
                        onChange={handleChange}
                        style={{
                          backgroundColor: "#F6F6F6",
                          padding: "10px",
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md="3">
                    <Form.Group>
                      <label style={{ fontSize: "14px", color: "grey" }}>
                        Mobile
                      </label>
                      <Form.Control
                        placeholder="Mobile"
                        name="mobile"
                        type="text"
                        value={formData.mobile}
                        onChange={handleChange}
                        style={{
                          backgroundColor: "#F6F6F6",
                          padding: "10px",
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md="3">
                    <Form.Group>
                      <label style={{ fontSize: "14px", color: "grey" }}>
                        Company Name
                      </label>
                      <Form.Control
                        placeholder="Enter Company Name"
                        name="companyName"
                        type="text"
                        value={formData.companyName}
                        onChange={handleChange}
                        style={{
                          backgroundColor: "#F6F6F6",
                          padding: "10px",
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div className="mt-2 my-3 mt-4">
                  <div className="">
                    <button
                      className={`btn ml-2 ${new1 ? "btn-link" : "withoutbtn"}`}
                      onClick={() => {
                        showNew1(true);
                        showNew2(false);
                        showNew3(false);
                      }}
                    >
                      Other Details
                    </button>
                    <button
                      className={`btn ml-2 ${
                        new2 ? " btn-link  " : "withoutbtn"
                      }`}
                      onClick={() => {
                        showNew2(true);
                        showNew1(false);
                        showNew3(false);
                      }}
                    >
                      Address
                    </button>
                    <button
                      className={`btn ml-2 ${
                        new3 ? "btn-link " : "withoutbtn"
                      }`}
                      onClick={() => {
                        showNew3(true);
                        showNew1(false);
                        showNew2(false);
                      }}
                    >
                      Contact Person
                    </button>
                    <hr />

                    {new1 && (
                      // the other details line start from the first row
                      <>
                        <Row className="mt-2">
                          <Col md="3" className="">
                            <label style={{ fontSize: "14px", color: "grey" }}>
                              GST Treatment
                            </label>
                            <Select
                              className="mt-0"
                              options={GstTreatment}
                              value={GstTreatment.find(
                                (option) =>
                                  option.value === formData.gstTreatment
                              )}
                              onChange={handleGSTtreatment}
                              placeholder="Select a GST Treatment"
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  backgroundColor: "#F6F6F6",
                                  // border:'none',
                                  borderRadius: "8px",
                                  padding: "4px",
                                }),
                              }}
                            />
                            {errors.gstTreatment && (
                              <div className="text-danger mb-4">
                                {errors.gstTreatment}
                              </div>
                            )}{" "}
                          </Col>

                          {selectedOption !== "Overseas" ? (
                            <>
                              <Col md="3">
                                <label
                                  style={{ fontSize: "14px", color: "grey" }}
                                >
                                  Place Of Supply
                                </label>
                                <Select
                                  className="mt-0"
                                  options={PlaceOfSupplyOptions}
                                  name="placeOfSupply"
                                  value={PlaceOfSupplyOptions.find(
                                    (option) =>
                                      option.value === formData.placeOfSupply
                                  )}
                                  onChange={handlePlaceOfSupply}
                                  placeholder="Source Of Supply"
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      backgroundColor: "#F6F6F6",
                                      borderRadius: "8px",
                                      padding: "4px",
                                    }),
                                  }}
                                />
                                {errors.placeOfSupply && (
                                  <div className="text-danger mb-4">
                                    {errors.placeOfSupply}
                                  </div>
                                )}{" "}
                              </Col>
                            </>
                          ) : null}

                          <Col md="3" className="">
                            <label style={{ fontSize: "14px", color: "grey" }}>
                              Currency
                            </label>
                            <Select
                              className="mt-0"
                              options={CurrencyOptions}
                              value={CurrencyOptions.find(
                                (option) => option.value === formData.currency
                              )}
                              onChange={handleCurrency}
                              placeholder="Select Currency ..."
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  backgroundColor: "#F6F6F6",
                                  borderRadius: "8px",
                                  padding: "4px",
                                }),
                              }}
                            />
                            {errors.currency && (
                              <div className="text-danger mb-4">
                                {errors.currency}
                              </div>
                            )}{" "}
                          </Col>

                          <Col md="3">
                            <label style={{ fontSize: "14px", color: "grey" }}>
                              Opening Balance
                            </label>
                            <Form.Control
                              placeholder="Opening Balance"
                              name="openingBalance"
                              type="text"
                              value={formData.openingBalance}
                              onChange={handleChange}
                              style={{
                                backgroundColor: "#F6F6F6",
                                padding: "10px",
                              }}
                            />
                          </Col>
                        </Row>

                        {selectedOption === "Registered Business - Regular" ||
                        selectedOption ===
                          "Registered Business - Composition" ||
                        selectedOption === "Special Economic Zone" ||
                        selectedOption === "Deemed Export" ||
                        selectedOption === "Tax Deductor" ||
                        selectedOption === "SEZ Developer" ? (
                          <Row className="mt-3">
                            <Col className="" md="3">
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                GSTIN / UIN
                              </label>
                              <Form.Control
                                className=""
                                placeholder="GSTIN / UIN"
                                name="gstin"
                                type="text"
                                onChange={handleGstChange}
                                value={formData.gstin} // Add the value you want to show here
                                style={{
                                  backgroundColor: "#F6F6F6",
                                  padding: "10px",
                                }}
                              />
                            </Col>
                          </Row>
                        ) : null}

                        {selectedOption === "Unregistered Business" ||
                        selectedOption === "Consumer" ? (
                          <Row className="mt-3">
                            <Col className="" md="3">
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                PAN
                              </label>
                              <Form.Control
                                className=""
                                placeholder="PAN"
                                name="pan"
                                type="text"
                                value={formData.pan}
                                onChange={handlePanChange}
                                style={{
                                  backgroundColor: "#F6F6F6",
                                  padding: "10px",
                                }}
                              />
                            </Col>
                          </Row>
                        ) : null}

                        <Row className="mt-3">
                          <Col md="3">
                            <label style={{ fontSize: "14px", color: "grey" }}>
                              Payment Terms
                            </label>
                            <Select
                              className="mt-0"
                              options={PaymentTermsOptions}
                              value={PaymentTermsOptions.find(
                                (option) =>
                                  option.value === formData.paymentTerms
                              )}
                              onChange={handlePaymentTerms}
                              name="paymentTerms"
                              placeholder="Select Payment Terms"
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  backgroundColor: "#F6F6F6",
                                  // border:'none',
                                  borderRadius: "8px",
                                  padding: "4px",
                                }),
                              }}
                            />
                          </Col>

                          <Col className="" md="3">
                            <label style={{ fontSize: "14px", color: "grey" }}>
                              Facebook
                            </label>
                            <Form.Control
                              placeholder="Facebook Id"
                              name="facebook"
                              type="text"
                              value={formData.facebook}
                              onChange={handleChange}
                              style={{
                                backgroundColor: "#F6F6F6",
                                padding: "10px",
                              }}
                            />
                          </Col>

                          <Col className="" md="3">
                            <label style={{ fontSize: "14px", color: "grey" }}>
                              Twitter
                            </label>
                            <Form.Control
                              placeholder="Twitter Id"
                              name="twitter"
                              type="text"
                              value={formData.twitter}
                              onChange={handleChange}
                              style={{
                                backgroundColor: "#F6F6F6",
                                padding: "10px",
                              }}
                            />
                          </Col>
                        </Row>

                        <Row className="mt-3">
                          <Col md="3">
                            <Form.Group>
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                Tax Preference
                              </label>
                              <div className="d-flex my-2">
                                <label className="rdiobox">
                                  <input
                                    className=""
                                    type="radio"
                                    name="taxPreference" // Give both the same name attribute
                                    id="taxable"
                                    value="Taxable"
                                    checked={formData.taxable === "Taxable"}
                                    onChange={(e) =>
                                      handleTaxable(e, "Taxable")
                                    }
                                  />
                                  <span className="ml-1">Taxable</span>
                                </label>
                                <label className="rdiobox ml-3">
                                  <input
                                    className=""
                                    type="radio"
                                    name="taxPreference" // Give both the same name attribute
                                    id="non-taxable"
                                    value="Non-Taxable"
                                    // onChange={handleChange}
                                    onChange={(e) =>
                                      handleTaxable(e, "Non-Taxable")
                                    }
                                  />
                                  <span className="ml-1">Non-Taxable</span>
                                </label>
                              </div>
                            </Form.Group>
                          </Col>

                          {showTaxable === false && (
                            <Col md="3">
                              <label
                                style={{ fontSize: "14px", color: "grey" }}
                              >
                                Exemption Reason
                              </label>
                              <Form.Control
                                placeholder="Exemption Reason"
                                name="reason"
                                type="text"
                                value={formData.reason}
                                onChange={handleChange}
                                style={{
                                  backgroundColor: "#F6F6F6",
                                  padding: "10px",
                                }}
                              />
                            </Col>
                          )}
                        </Row>
                        {/* third row end */}
                      </>
                    )}

                    {new2 && (
                      <>
                        <div className=" grid-container ">
                          <div className="flex-item">
                            <p className="ml-1 text-center">BILLING ADDRESS</p>
                            <Row className="mt-1">
                              <Col className="" md="6">
                                <label
                                  style={{ fontSize: "14px", color: "grey" }}
                                >
                                  Attention
                                </label>
                                <Form.Control
                                  className=""
                                  placeholder="Attention"
                                  name="billAttention"
                                  type="text"
                                  value={formData.billAttention}
                                  onChange={handleChange}
                                  style={{
                                    backgroundColor: "#F6F6F6",
                                    padding: "10px",
                                  }}
                                />
                              </Col>

                              <Col className="" md="6">
                                <label
                                  style={{ fontSize: "14px", color: "grey" }}
                                >
                                  Country
                                </label>
                                <Select
                                  className="mt-0"
                                  options={countryOptions}
                                  value={{
                                    value: billCountry,
                                    label: billCountry || formData.billCountry,
                                  }}
                                  onChange={handleCountryChange}
                                  placeholder="Select"
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      backgroundColor: "#F6F6F6",
                                      borderRadius: "8px",
                                      padding: "4px",
                                    }),
                                  }}
                                />
                              </Col>
                            </Row>

                            <Row className="mt-2">
                              <Col className="" md="6">
                                <label
                                  style={{ fontSize: "14px", color: "grey" }}
                                >
                                  Address
                                </label>
                                <Form.Control
                                  className=""
                                  placeholder="Site1"
                                  name="billAddress1"
                                  type="text"
                                  value={formData.billAddress1}
                                  onChange={handleChange}
                                  style={{
                                    backgroundColor: "#F6F6F6",
                                    padding: "10px",
                                  }}
                                />
                              </Col>

                              <Col className="" md="6">
                                <label
                                  style={{ fontSize: "14px", color: "grey" }}
                                >
                                  Address
                                </label>
                                <Form.Control
                                  className=""
                                  placeholder="Site2"
                                  name="billAddress2"
                                  type="text"
                                  value={formData.billAddress2}
                                  onChange={handleChange}
                                  style={{
                                    backgroundColor: "#F6F6F6",
                                    padding: "10px",
                                  }}
                                />
                              </Col>
                            </Row>

                            <Row className="mt-2">
                              <Col className="" md="6">
                                <label
                                  style={{ fontSize: "14px", color: "grey" }}
                                >
                                  City
                                </label>
                                <Form.Control
                                  className=""
                                  placeholder="City"
                                  id="billingCity"
                                  name="billCity"
                                  type="text"
                                  value={formData.billCity}
                                  onChange={handleChange}
                                  style={{
                                    backgroundColor: "#F6F6F6",
                                    padding: "10px",
                                  }}
                                />
                              </Col>
                              <Col className="" md="6">
                                <label
                                  style={{ fontSize: "14px", color: "grey" }}
                                >
                                  State
                                </label>
                                <Select
                                  id="billingState"
                                  name="billState"
                                  value={indianStateOptions.find(
                                    (option) =>
                                      option.value === formData.billState
                                  )} // Find the selected option
                                  onChange={handleStateChange} // Handle state change
                                  options={indianStateOptions} // Pass the state options
                                  placeholder="Select State"
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      backgroundColor: "#F6F6F6",
                                      borderRadius: "8px",
                                      padding: "4px",
                                    }),
                                  }}
                                />
                              </Col>
                            </Row>

                            <Row className="mt-2">
                              <Col className="">
                                <label
                                  style={{ fontSize: "14px", color: "grey" }}
                                >
                                  Zipcode
                                </label>
                                <Form.Control
                                  className=""
                                  placeholder="Zipcode"
                                  id="billingZipcode"
                                  name="billZipcode"
                                  type="text"
                                  value={formData.billZipcode}
                                  onChange={handleChange}
                                  style={{
                                    backgroundColor: "#F6F6F6",
                                    padding: "10px",
                                  }}
                                />
                              </Col>
                              <Col className="" md="6">
                                <label
                                  style={{ fontSize: "14px", color: "grey" }}
                                >
                                  Phone
                                </label>
                                <Form.Control
                                  className=""
                                  placeholder="Phone"
                                  id="billingPhone"
                                  name="billPhone"
                                  type="text"
                                  value={formData.billPhone}
                                  onChange={handleChange}
                                  style={{
                                    backgroundColor: "#F6F6F6",
                                    padding: "10px",
                                  }}
                                />
                                {errors.billingPhone && (
                                  <div className="text-danger mb-4">
                                    {errors.billingPhone}
                                  </div>
                                )}{" "}
                              </Col>
                            </Row>

                            <Row className="mt-2">
                              <Col className="" md="6">
                                <label
                                  style={{ fontSize: "14px", color: "grey" }}
                                >
                                  Fax
                                </label>
                                <Form.Control
                                  className="form-control"
                                  placeholder="Fax"
                                  id="billingFax"
                                  name="billFax"
                                  type="text"
                                  value={formData.billFax}
                                  onChange={handleChange}
                                  style={{
                                    backgroundColor: "#F6F6F6",
                                    padding: "10px",
                                  }}
                                />
                              </Col>
                            </Row>
                          </div>

                          <div className="flex-item">
                            <p className=" ml-1 text-center">
                              SHIPPING ADDRESS{" "}
                              <span
                                style={{
                                  fontSize: 12,
                                  color: "blue",
                                  cursor: "pointer",
                                }}
                                onClick={copyData}
                              >
                                <ArrowCircleDown size={16} /> COPY BILLING
                                ADDRESS
                              </span>
                            </p>
                            {/* <div className="vl "></div> */}

                            <Row className="mt-1">
                              <Col className="" md="6">
                                <label
                                  style={{ fontSize: "14px", color: "grey" }}
                                >
                                  Attention
                                </label>
                                <Form.Control
                                  className=""
                                  placeholder="Attention"
                                  id="shippingAttention"
                                  name="shipAttention"
                                  type="text"
                                  value={formData.shipAttention}
                                  onChange={(e) => {
                                    const { name, value } = e.target;
                                    setShippingAttention(value);
                                    setFormData((prevFormData) => ({
                                      ...prevFormData,
                                      [name]: value,
                                    }));
                                  }}
                                  style={{
                                    backgroundColor: "#F6F6F6",
                                    padding: "10px",
                                  }}
                                />
                              </Col>

                              <Col className="" md="6">
                                <label
                                  style={{ fontSize: "14px", color: "grey" }}
                                >
                                  Country
                                </label>
                                <Select
                                  className="mt-0"
                                  options={countryOptions}
                                  placeholder="Select"
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      backgroundColor: "#F6F6F6",
                                      borderRadius: "8px",
                                      padding: "4px",
                                    }),
                                  }}
                                  name="shipCountry"
                                  id="shippingCountry"
                                  value={countryOptions.find(
                                    (option) =>
                                      option.value === formData.shipCountry
                                  )} // Update value prop
                                  onChange={(selectedOption) => {
                                    const { name, value } = selectedOption; // No need for e.target
                                    setShippingCountry(value);
                                    setFormData((prevFormData) => ({
                                      ...prevFormData,
                                      [name]: value,
                                    }));
                                  }}
                                />
                              </Col>
                            </Row>

                            {/* first line end */}

                            <Row className="mt-2">
                              <Col className="" md="6">
                                <label
                                  style={{ fontSize: "14px", color: "grey" }}
                                >
                                  Address
                                </label>
                                <Form.Control
                                  className=""
                                  placeholder="Site1"
                                  name="shipAddress1"
                                  type="text"
                                  value={formData.shipAddress1}
                                  onChange={(e) => {
                                    const { name, value } = e.target;
                                    setShippingAddress1(value);
                                    setFormData((prevFormData) => ({
                                      ...prevFormData,
                                      [name]: value,
                                    }));
                                  }}
                                  style={{
                                    backgroundColor: "#F6F6F6",
                                    padding: "10px",
                                  }}
                                />
                              </Col>

                              <Col className="" md="6">
                                <label
                                  style={{ fontSize: "14px", color: "grey" }}
                                >
                                  Address
                                </label>
                                <Form.Control
                                  className=""
                                  placeholder="Site2"
                                  name="shipAddress2"
                                  type="text"
                                  value={formData.shipAddress2}
                                  onChange={(e) => {
                                    const { name, value } = e.target;
                                    setShippingAddress2(value);
                                    setFormData((prevFormData) => ({
                                      ...prevFormData,
                                      [name]: value,
                                    }));
                                  }}
                                  style={{
                                    backgroundColor: "#F6F6F6",
                                    padding: "10px",
                                  }}
                                />
                              </Col>
                            </Row>

                            {/* second line end */}

                            <Row className="mt-2">
                              <Col className="" md="6">
                                <label
                                  style={{ fontSize: "14px", color: "grey" }}
                                >
                                  City
                                </label>
                                <Form.Control
                                  className=""
                                  placeholder="City"
                                  id="shippingCity"
                                  name="shipCity"
                                  type="text"
                                  value={formData.shipCity}
                                  onChange={(e) => {
                                    const { name, value } = e.target;
                                    setShippingCity(value);
                                    setFormData((prevFormData) => ({
                                      ...prevFormData,
                                      [name]: value,
                                    }));
                                  }}
                                  style={{
                                    backgroundColor: "#F6F6F6",
                                    padding: "10px",
                                  }}
                                />
                              </Col>
                              <Col className="" md="6">
                                <label
                                  style={{ fontSize: "14px", color: "grey" }}
                                >
                                  State
                                </label>
                                <Select
                                  placeholder="Select State"
                                  id="shippingCountry"
                                  name="shipState"
                                  value={indianStateOptions.find(
                                    (option) =>
                                      option.value === formData.shipState
                                  )} // Find the selected option
                                  onChange={handleShipStateChange} // Handle state change
                                  options={indianStateOptions} // Use formData to access the selected value
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      backgroundColor: "#F6F6F6",
                                      borderRadius: "8px",
                                      padding: "4px",
                                    }),
                                  }}
                                />
                              </Col>
                            </Row>

                            {/* third line end */}

                            <Row className="mt-2">
                              <Col className="">
                                <label
                                  style={{ fontSize: "14px", color: "grey" }}
                                >
                                  Zipcode
                                </label>
                                <Form.Control
                                  className=""
                                  placeholder="Zipcode"
                                  id="shippingZipcode"
                                  name="shipZipcode"
                                  type="text"
                                  value={formData.shipZipcode}
                                  onChange={(e) => {
                                    const { name, value } = e.target;
                                    setShippingZipcode(value); // Update billingPhone state
                                    setFormData((prevFormData) => ({
                                      ...prevFormData,
                                      [name]: value, // Update billingPhone field in formData
                                    }));
                                  }}
                                  style={{
                                    backgroundColor: "#F6F6F6",
                                    padding: "10px",
                                  }}
                                />
                              </Col>
                              <Col className="" md="6">
                                <label
                                  style={{ fontSize: "14px", color: "grey" }}
                                >
                                  Phone
                                </label>
                                <Form.Control
                                  className=""
                                  placeholder="Phone"
                                  id="shippingPhone"
                                  name="shipPhone"
                                  type="text"
                                  value={formData.shipPhone}
                                  onChange={(e) => {
                                    const { name, value } = e.target;
                                    setShippingPhone(value);
                                    setFormData((prevFormData) => ({
                                      ...prevFormData,
                                      [name]: value,
                                    }));
                                  }}
                                  style={{
                                    backgroundColor: "#F6F6F6",
                                    padding: "10px",
                                  }}
                                />
                                {errors.billingPhone && (
                                  <div className="text-danger mb-4">
                                    {errors.billingPhone}
                                  </div>
                                )}{" "}
                              </Col>
                            </Row>

                            {/* fourth line end */}

                            <Row className="mt-2">
                              <Col className="" md="6">
                                <label
                                  style={{ fontSize: "14px", color: "grey" }}
                                >
                                  Fax
                                </label>
                                <Form.Control
                                  className="form-control"
                                  placeholder="Fax"
                                  id="shippingFax"
                                  name="shipFax"
                                  type="text"
                                  value={formData.shipFax}
                                  onChange={(e) => {
                                    const { name, value } = e.target;
                                    setShippingFax(value);
                                    setFormData((prevFormData) => ({
                                      ...prevFormData,
                                      [name]: value,
                                    }));
                                  }}
                                  style={{
                                    backgroundColor: "#F6F6F6",
                                    padding: "10px",
                                  }}
                                />
                              </Col>
                            </Row>

                            {/* fifth line end */}
                          </div>
                        </div>
                      </>
                    )}

                    {new3 && (
                      <div>
                        <div className="container">
                          <div className="row mg-t-10 pt-3 lg-b-20">
                            {formData.contactPerson.map((contact, index) => (
                              <table>
                                <tr>
                                  <Row>
                                    <Col md="2" className="">
                                      <Form.Group>
                                        <label
                                          style={{
                                            fontSize: "14px",
                                            color: "grey",
                                          }}
                                        >
                                          Salutation
                                        </label>
                                        <Select
                                          className="mt-0"
                                          options={unitSalution}
                                          name="other"
                                          value={unitSalution.find(
                                            (option) =>
                                              option.value === selectedOther
                                          )}
                                          // value={formData.contactPerson[index].other}
                                          onChange={handleOtherSalutation}
                                          // onChange={(e) => handleOtherChange(e, index)}
                                          placeholder="Select"
                                          styles={{
                                            control: (provided) => ({
                                              ...provided,
                                              backgroundColor: "#F6F6F6",
                                              // border:'none',
                                              borderRadius: "8px",
                                              padding: "4px",
                                            }),
                                          }}
                                        />
                                      </Form.Group>
                                    </Col>

                                    <Col className="">
                                      <label
                                        style={{
                                          fontSize: "14px",
                                          color: "grey",
                                        }}
                                      >
                                        First Name
                                      </label>
                                      <Form.Control
                                        className="form-control"
                                        placeholder="First Name"
                                        name="otherFirstname"
                                        type="text"
                                        value={contact.otherFirstname}
                                        onChange={(e) =>
                                          handleOtherChange(e, index)
                                        }
                                        style={{
                                          backgroundColor: "#F6F6F6",
                                          padding: "10px",
                                        }}
                                      />
                                    </Col>

                                    <Col className="">
                                      <label
                                        style={{
                                          fontSize: "14px",
                                          color: "grey",
                                        }}
                                      >
                                        Last Name
                                      </label>
                                      <Form.Control
                                        className="form-control "
                                        placeholder="Last Name"
                                        name="otherLastname"
                                        type="text"
                                        value={contact.otherLastname}
                                        onChange={(e) =>
                                          handleOtherChange(e, index)
                                        }
                                        style={{
                                          backgroundColor: "#F6F6F6",
                                          padding: "10px",
                                        }}
                                      />
                                    </Col>

                                    <Col className="">
                                      <label
                                        style={{
                                          fontSize: "14px",
                                          color: "grey",
                                        }}
                                      >
                                        Email
                                      </label>
                                      <Form.Control
                                        className="form-control"
                                        placeholder="Email Address"
                                        name="otherEmail"
                                        type="text"
                                        value={contact.otherEmail}
                                        onChange={(e) =>
                                          handleOtherChange(e, index)
                                        }
                                        style={{
                                          backgroundColor: "#F6F6F6",
                                          padding: "10px",
                                        }}
                                      />
                                    </Col>

                                    <Col className="">
                                      <label
                                        style={{
                                          fontSize: "14px",
                                          color: "grey",
                                        }}
                                      >
                                        Work Phone
                                      </label>
                                      <Form.Control
                                        className="form-control"
                                        placeholder="Work Phone"
                                        name="otherWorkphone"
                                        type="text"
                                        value={contact.otherWorkphone}
                                        onChange={(e) =>
                                          handleOtherChange(e, index)
                                        }
                                        style={{
                                          backgroundColor: "#F6F6F6",
                                          padding: "10px",
                                        }}
                                      />
                                    </Col>

                                    <Col className="">
                                      <label
                                        style={{
                                          fontSize: "14px",
                                          color: "grey",
                                        }}
                                      >
                                        Mobile
                                      </label>
                                      <Form.Control
                                        className="form-control"
                                        placeholder="Mobile"
                                        name="otherMobile"
                                        type="text"
                                        value={contact.otherMobile}
                                        onChange={(e) =>
                                          handleOtherChange(e, index)
                                        }
                                        style={{
                                          backgroundColor: "#F6F6F6",
                                          padding: "10px",
                                        }}
                                      />
                                    </Col>

                                    <td>
                                      <div className="col-md-2 mt-4">
                                        <button
                                          className="btn btn-danger btn-sm"
                                          onClick={handleDelete}
                                        >
                                          <i className="fa fa-trash"></i>
                                        </button>
                                      </div>
                                    </td>
                                  </Row>
                                  {/* end row */}
                                </tr>
                              </table>
                            ))}
                            <div className="mt-4 ml-2">
                              <button
                                className="from-control btn ml-2 "
                                style={{
                                  backgroundColor: "#06699d",
                                  color: "white",
                                }}
                                onClick={handleAdd}
                              >
                                + Add Another Line
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="text-right bottom-buttons">
                      <button
                        className="btn-1 btn mt-0"
                        onClick={handleCancleClick}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn-3 btn btn-primary"
                        onClick={handleSaveClick}
                      >
                        {isLoading ? "Updating..." : "Update Customer"}
                      </button>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CustomerUpdate;
