import React, { useState, useEffect } from "react";
import { AES, enc } from "crypto-js";
import swal from "sweetalert";
import { Container, Row, Col, Card } from "react-bootstrap";
import ManagePortfolioUpdate from "../../screens/Dashboard/Office/ManagePortfolioUpdate";
import { BASE_URL } from "../../screens/Dashboard/CommonApi";

function ManagePortfolioCard(props) {
  const [bill, setBill] = useState([]);
  const [billId, setBillId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(true);
  let i = 1;

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchPortfolio`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setBill(data.responseMessage);
        } else {
          console.error("Failed to fetch SalesOrder:", data.responseMessage);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching SalesOrder:", error);
        setLoading(false);
      });
  }, []);

  const handleDelete = (id) => {
    fetch(BASE_URL + `/deletePortfolio/${id}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.status === 200) {
          setBill((prevBills) => prevBills.filter((bill) => bill.id !== id));
          swal({
            icon: "success",
            title: "Bill Deleted Successfully",
          });
        } else {
          console.error("Failed to delete Bill");
        }
      })
      .catch((error) => {
        console.error("Error deleting Bill:", error);
      });
  };

  const handleEdit = (id) => {
    setBillId(id);
    setEdit(false);
  };

  const handleCancleClick = () => {
    props.handleCancle();
  };
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      {edit ? (
        <div className="col-lg-12">
          <Container fluid={true}>
            <Row>
              <Col>
                <Card>
                  <div className="table-responsive">
                    <table class="table table-bordered table-striped mb-0">
                      <thead style={{ fontSize: "12px" }}>
                        <tr>
                          <th scope="col">SR NO</th>
                          <th scope="col">TITLE</th>
                          <th scope="col">ACTION UNIT</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bill.map((bill1, index) => (
                          <tr key={index}>
                            <th scope="row">{i++}</th>
                            <td>{bill1.title}</td>
                            <td>
                              {" "}
                              <button
                                className="btn btn-primary btn-sm mr-2"
                                onClick={() => handleEdit(bill1.id)}
                              >
                                <i className="fa fa-edit"></i>
                              </button>
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => handleDelete(bill1.id)}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <ManagePortfolioUpdate id={billId} handleCancle={handleCancleClick} />
      )}
    </>
  );
}

export default ManagePortfolioCard;
