import React from "react";

class StudentsCard extends React.Component {
    render() {
        const { navigation, onCompose } = this.props;
        return (
            <div className="col-lg-12">
                {/* <div className="card"> */}
                <table class="table">
                    <thead style={{ fontSize: "12px" }}>
                        <tr>
                            <th scope="col">SR NO</th>
                            <th scope="col">NAME</th>
                            <th scope="col">EMAIL</th>
                            <th scope="col">PHONE</th>
                            <th scope="col">COLLEGE</th>                                                      
                        </tr>
                    </thead>
                    <tbody>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>                        
                    </tbody>
                </table>
           <div className="d-flex justify-content-end mt-3">
                  <button className="btn btn-primary mx-1" onClick={this.handlePrevious}>
                    Previous
                  </button>
                  <button className="btn btn-primary mx-1" onClick={this.handleNo}>
                    1
                  </button>
                  <button className="btn btn-primary mx-1" onClick={this.handleNext}>
                    Next
                  </button>
                </div>
            </div>
            
        );
    }
}
export default StudentsCard
