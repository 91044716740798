import React from "react";
import Header from "../../../Components/Common/Header";
import "../../../asset/css/inbox.css";
import ManageStockCard from "../../../Components/Apps/ManageStockCard";

class ManageStock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCompose: false,
      show: false,
    };
  }

  handleNewButtonClick = () => {
    this.setState({ show: true });
  };

  handleCancelNew = () => {
    this.setState({ show: false });
  };

  render() {
    return (
      <div className="container-fluid">
        {!this.state.show && (
          <Header
            headerText=""
            mainNavigate="Shop"
            currentPage="Items"
            childNav={this.state.isCompose ? "Compose" : ""}
          />
        )}
        <div className="row clearfix ">
          {!this.state.show && (
            <div className="container mx-2 mb-2">
              <div className="d-flex justify-content-between">
                <div>
                  <h4>All Items</h4>
                </div>

                <div className="d-flex">
                  {/* <form id="navbar-search" className="navbar-form search-form">
                  <input className="form-control" placeholder="Search here..." type="text" />
                  <button type="button" className="btn btn-default"><i className="icon-magnifier"></i></button>
                </form>
                 */}
                </div>
              </div>
            </div>
          )}

          <ManageStockCard />
        </div>
      </div>
    );
  }
}
export default ManageStock;
