import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import "../../../asset/css/inbox.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import NoDataImage from "../../../asset/images/nodata.jpg";
import { BASE_URL } from "../CommonApi";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import CustomerUpdate from "../SalesNew/CustomerUpdate";
import CustomerDetails from "../SalesNew/CustomerDetails";

function Customers({ navigation }) {
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [itemId, setItemID] = useState();
  const [loading, setLoading] = useState(true);
  const [showCard, setShowCard] = useState(true);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [newCustomerId, setNewCustomerId] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState("");
  const jwtToken = localStorage.getItem("jwtToken");
  const [postData, setPostData] = useState({
    uniqueId: decryptedUniqueId,
  });
  const [customerData, setCustomerData] = useState([]);
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleProfileClick = () => {
    handleNavigation("CustomerNew");
  };

  const fetchCustomerData = () => {
    $("#DataTables_Table_0").DataTable();
    const apiUrl = BASE_URL + "/getAllCustomerName";
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("POST request failed");
        }
      })
      .then((responseData) => {
        setCustomerData(responseData.responseMessage);
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      });
  };
  useEffect(() => {
    fetchCustomerData();
  }, []);
  const handleEdit = (id) => {
    setItemID(id);
    setShowCard(false);
    setShowUpdate(true);
  };

  const handleCancelUpdate = () => {
    fetchCustomerData();
    setShowUpdate(false);
    setShowCard(true);
  };

  const handleCancelDetails = () => {
    setShowDetails(false);
    setShowCard(true);
    setShowUpdate(false);
    fetchCustomerData();
  };

  const handleDelete = (id) => {
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this customer data!"
    );
    if (userConfirmed) {
      fetch(BASE_URL + "/deleteCustomer", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ id: id }),
      })
        .then((response) => {
          if (response.status === 200) {
            setCustomerData((prevBank) =>
              prevBank.filter((customer) => customer.id !== id)
            );
            swal({
              icon: "success",
              title: "Customer Deleted Successfully",
            });
            setLoading(true);
            fetchCustomerData();
          } else {
            console.error("Failed to delete Customer");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error deleting Customer:", error);
        });
    } else {
      alert("Customer data deletion canceled!");
    }
  };

  const handleCustomer = (id, terms) => {
    setNewCustomerId(id);
    setPaymentTerms(terms)
    setShowCard(false);
    setShowDetails(true);
  };

  let i = 1;
  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="container-fluid">
      {!showUpdate && !showDetails && (
        <div className="item-page">
          <div className="d-flex justify-content-between">
            <div className="mt-2" style={{ color: "#3C7BD4",fontSize: 20,fontWeight: "600",wordWrap: "break-word",textAlign: "left",}}>
              Customers
            </div>
            <div className="d-flex">
              <div className="">
                  <button className="btn btn-primary px-1 py-2 new-purchase" onClick={handleProfileClick}>
                    + New Customer
                  </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showCard && (
        <div className="container-fluid">
          <div className="page-content ">
            <Container fluid={true} className="">
              <Row>
                <Col>
                  <Card style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                    {customerData && customerData.length > 0 ? (
                      <>
                        <div className="body">
                          <div className="table-responsive1">
                            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                              <div className="row">
                                <div className="col-sm-12">
                                  <table className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
                                    <thead className="heading-text">
                                      <tr role="row">
                                        <th className="sorting_asc" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="Date: activate to sort column descending">
                                          Serial no.
                                        </th> 
                                        <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Type: activate to sort column ascending">
                                          Name
                                        </th>
                                        <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Amount BTC: activate to sort column ascending">
                                          Company name
                                        </th>
                                        <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="BTC Remaining: activate to sort column ascending">
                                          Email
                                        </th>
                                        <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Price: activate to sort column ascending">
                                          Status
                                        </th>
                                        <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Price: activate to sort column ascending">
                                          Payment terms
                                        </th>
                                        <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Price: activate to sort column ascending"></th>
                                      </tr>
                                    </thead>
                                    <tbody className="text1">
                                      {customerData.map((data) => (
                                        <tr role="row" className="odd" key={data.id}>
                                          <td>{i++}</td>
                                          <td className="sorting_1">
                                            {" "}
                                            <a href="#" onClick={() => handleCustomer(data.id,data.paymentTerms)}>
                                              {data.customerName}
                                            </a>
                                          </td>
                                          <td>{data.companyName}</td>
                                          <td>{data.email}</td>
                                          <td style={{ color: "green" }}>
                                            {data.status}
                                          </td>
                                          <td>{data.paymentTerms}</td>
                                          <td>
                                            <button className="btn icon-border btn-sm mr-2" onClick={() => handleEdit(data.id)}>
                                              <i className="fa fa-edit text1"></i>
                                            </button>
                                            <button className="btn icon-border btn-sm" onClick={() => handleDelete(data.id)}>
                                              <i className="fa fa-trash text1"></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="body">
                        <div className="table-responsive1">
                          <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="row">
                              <div className="col-sm-12">
                                <table className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
                                  <thead className="heading-text">
                                    <tr role="row">
                                      <th className="sorting_asc" tabIndex="0" aria-con trols="DataTables_Table_0" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="Date: activate to sort column descending">
                                        Serial no.
                                      </th>
                                      <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Type: activate to sort column ascending">
                                        Name
                                      </th>
                                      <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Amount BTC: activate to sort column ascending">
                                        Company name
                                      </th>
                                      <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="BTC Remaining: activate to sort column ascending">
                                        Email
                                      </th>
                                      <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Price: activate to sort column ascending">
                                        Work phone
                                      </th>
                                      <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Price: activate to sort column ascending">
                                        Payment terms
                                      </th>
                                      <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Price: activate to sort column ascending"></th>
                                    </tr>
                                  </thead>
                                  <tbody></tbody>
                                </table>
                                <h6 className="text-center">
                                  <img src={NoDataImage} style={{ width: "50%", height: "50%" }} alt="No Data Logo"/>{" "}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}

      {showUpdate && ( <CustomerUpdate id={itemId} handleCancle={handleCancelUpdate} />)}

      {showDetails && ( <CustomerDetails fetchedPaymentTerms={paymentTerms} newCustomerId={newCustomerId} handleCancel={handleCancelDetails}/>)}
    </div>
  );
}

export default Customers;
