import React, { useState, useEffect, useRef } from "react";
import RecurringInvoiceUpdate from "../SalesNew/RecurringInvoiceUpdate";
import { Container, Row, Col, Card } from "react-bootstrap";
import NoDataImage from "../../../asset/images/nodata.jpg";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import swal from "sweetalert";
import RecurringInvoiceDetails from "../SalesNew/RecurringInvoiceDetails";

function RecurringInvoices({ navigation }) {
  const [showUpdate, setShowUpdate] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showCard, setShowCard] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const btnRef = useRef();
  const [customerId, setCustomerId] = useState("");

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const encryptedUniqueId = localStorage.getItem("uniqueId1");

  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const handleEstimateClick = () => {
    handleNavigation("RecurringInvoiceNew");
  };

  const [invoiceNumber, setInvoiceId] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [newinvoiceNumber, setnewInvoice] = useState([]);
  const jwtToken = localStorage.getItem("jwtToken");

  const [loading, setLoading] = useState(true);
  const fetchInvoices = () => {
    $("#DataTables_Table_0").DataTable();
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchRecurringInvoice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setInvoices(data.responseMessage);
          setLoading(false);
        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
          setLoading(false);
        }
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      });
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  const handleDelete = (id) => {
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this invoice!"
    );
    if (userConfirmed) {
      fetch(BASE_URL + `/deleteByRecurringInvoiceNumber/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ uniqueId: decryptedUniqueId }),
      })
        .then((response) => {
          if (response.status === 200) {
            setInvoices((prevInvoices) =>
              prevInvoices.filter((invoice) => invoice.invoiceNumber !== id)
            );
            swal({
              icon: "success",
              title: "Invoice Deleted Successfully",
            });
            setLoading(true);
            fetchInvoices();
          } else {
            console.error("Failed to delete invoice");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error deleting invoice:", error);
          setLoading(false);
        });
    } else {
      alert("Invoice deletion canceled!");
    }
  };

  const handleCancelUpdate = () => {
    setShowUpdate(false);
    setShowCard(true);
    fetchInvoices();
  };

  const handleCancelInvoice = () => {
    setShowDetails(false);
    setShowCard(true);
    setShowUpdate(false);
    fetchInvoices();
  };

  const handleInvoice = (invoiceNumber, customerId) => {
    setnewInvoice(invoiceNumber);
    setCustomerId(customerId);
    setShowDetails(true);
    setShowCard(false);
  };

  const handleEdit = (id) => {
    setInvoiceId(id);
    setShowCard(false);
    setShowUpdate(true);
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      {!showUpdate && !showDetails && (
        <div className="item-page">
          <div className="d-flex justify-content-between">
            <div
              className="mt-2"
              style={{
                color: "#3C7BD4",
                fontSize: 20,
                fontWeight: "600",
                wordWrap: "break-word",
                textAlign: "left",
              }}
            >
              Recurring Invoice
            </div>
            <div className="d-flex">
              <div className="">
                <button
                  type="button"
                  className="btn btn-primary px-1 py-2 new-purchase"
                  onClick={handleEstimateClick}
                >
                  + New Recurring
                </button>
              </div>
              <div className="estimatess-dropdown-container d-flex align-items-end">
                <button
                  className="ml-2 px-1 py-2 icon-dot"
                  style={{
                    border: "none",
                    outline: "none",
                    backgroundColor: "white",
                  }}
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  ref={btnRef}
                >
                  <i
                    className="fa fa-ellipsis-v"
                    style={{ fontSize: 18, color: "gray" }}
                  ></i>
                  {dropdownOpen && (
                    <div className="estimates-dropdown">
                      <ul
                        style={{
                          listStyleType: "none",
                          margin: 0,
                          padding: "3px",
                          width: "170px",
                        }}
                      >
                        <li className="estimates-option1" onClick={""}>
                          <ion-icon
                            name="download-outline"
                            size="small"
                          ></ion-icon>
                          <span className="pl-2">Export Customers</span>
                        </li>
                      </ul>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showCard && (
        <div className="container-fluid">
          <Container fluid={true}>
            <Row>
              <Col>
                <Card style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                  {invoices && invoices.length > 0 ? (
                    <>
                      <div className="body">
                        <div className="table-responsive1">
                          <div
                            id="DataTables_Table_0_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                  id="DataTables_Table_0"
                                  role="grid"
                                  aria-describedby="DataTables_Table_0_info"
                                >
                                  <thead className="heading-text">
                                    <tr role="row">
                                      <th
                                        className="sorting_asc"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Date: activate to sort column descending"
                                      >
                                        Customer name
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Type: activate to sort column ascending"
                                      >
                                        Profile name
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Amount BTC: activate to sort column ascending"
                                      >
                                        Last invoice date
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="BTC Remaining: activate to sort column ascending"
                                      >
                                        Next invoice date
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Total
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      ></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {invoices.map((invoice) => (
                                      <tr
                                        role="row"
                                        className="odd"
                                        key={invoice.id}
                                      >
                                        <td>{invoice.customerName}</td>
                                        <td className="sorting_1">
                                          <a
                                            href="#"
                                            onClick={() =>
                                              handleInvoice(
                                                invoice.invoiceNumber,
                                                invoice.customerId
                                              )
                                            }
                                          >
                                            {invoice.profileName}
                                          </a>
                                        </td>
                                        <td>{invoice.startOn}</td>
                                        <td>{invoice.endsOn}</td>
                                        <td>₹{invoice.total}</td>
                                        <td>
                                          <button
                                            className="btn icon-border btn-sm mr-2"
                                            onClick={() =>
                                              handleEdit(invoice.invoiceNumber)
                                            }
                                          >
                                            <i className="fa fa-edit text1"></i>
                                          </button>
                                          <button
                                            className="btn icon-border btn-sm"
                                            onClick={() =>
                                              handleDelete(
                                                invoice.invoiceNumber
                                              )
                                            }
                                          >
                                            <i className="fa fa-trash text1"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="body">
                      <div className="table-responsive1">
                        <div
                          id="DataTables_Table_0_wrapper"
                          className="dataTables_wrapper dt-bootstrap4 no-footer"
                        >
                          <div className="row">
                            <div className="col-sm-12">
                              <table
                                className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                id="DataTables_Table_0"
                                role="grid"
                                aria-describedby="DataTables_Table_0_info"
                              >
                                <thead className="heading-text">
                                  <tr role="row">
                                    <th
                                      className="sorting_asc"
                                      tabIndex="0"
                                      aria-con
                                      trols="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-sort="ascending"
                                      aria-label="Date: activate to sort column descending"
                                    >
                                      Customer name
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Type: activate to sort column ascending"
                                    >
                                      Profile name
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Amount BTC: activate to sort column ascending"
                                    >
                                      Frequency
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="BTC Remaining: activate to sort column ascending"
                                    >
                                      Last invoice date
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Price: activate to sort column ascending"
                                    >
                                      Next invoice date
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Price: activate to sort column ascending"
                                    >
                                      Status
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Price: activate to sort column ascending"
                                    ></th>
                                  </tr>
                                </thead>
                                <tbody></tbody>
                              </table>
                              <h6 className="text-center">
                                <img
                                  src={NoDataImage}
                                  style={{ width: "50%", height: "50%" }}
                                  alt="No Data Logo"
                                />{" "}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
      {showUpdate && (
        <RecurringInvoiceUpdate
          recurringInvoiceNumber={invoiceNumber}
          handleCancel={handleCancelUpdate}
        />
      )}
      {showDetails && (
        <RecurringInvoiceDetails
          newinvoiceNumber={newinvoiceNumber}
          custId={customerId}
          handleCancel={handleCancelInvoice}
        />
      )}
    </div>
  );
}

export default RecurringInvoices;
