import React, { useState, useEffect } from "react";
import GoogleAnalyticCard from "../../../Components/Dashboard/GoogleAnalyticCard";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import CashFlowChart from "./CashFlowChart";
import "react-toastify/dist/ReactToastify.css";
import "./Analytical.css";
import { ProgressBar } from "react-bootstrap";

const Analytical = (props) => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  });

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    const handlePopState = (event) => {
      window.history.pushState(null, null, window.location.pathname);
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  const { navigation } = props;
  const handleBillClick = () => {
    navigation.navigate("bills");
  };
  const [lineChartData, setLineChartData] = useState({
    xAxisData: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    yAxisData: [],
    seriesData: [],
  });

  const fetchData = async () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const jwtToken = localStorage.getItem("jwtToken");
    try {
      const response = await fetch(BASE_URL + "/fetchForGraph", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ uniqueId: decryptedUniqueId }),
      });
      const data = await response.json();
      const salesData = Object.values(data).map((month) =>
        parseFloat(month.totalSales)
      ); // Extracting total sales and parsing to float
      setLineChartData((prevState) => ({
        ...prevState,
        seriesData: salesData,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [invoiceCount, setInvoiceCount] = useState("0");
  const [purchaseCount, setPurchaseCount] = useState("0");
  const [itemCount, setItemCount] = useState("0");
  const [customerCount, setCustomerCount] = useState("0");
  const [currentValue, setCurrentValue] = useState("0");
  const [overValue, setOverValue] = useState("0");
  const [currentValueBill, setCurrentValueBill] = useState(0);
  const [overValueBill, setOverValueBill] = useState("0");
  const overValueFloat = parseFloat(overValue);
  const currentValueFloat = parseFloat(currentValue);
  const totalUnpaid = overValueFloat + currentValueFloat;
  const overValueFloatBill = parseFloat(overValueBill);
  const currentValueFloatBill = parseFloat(currentValueBill);
  const totalUnpaidBill = overValueFloatBill + currentValueFloatBill;

  const fetchInvoiceCount = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + `/fetchInvoice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const invoiceCount = data.responseMessage1;
          setInvoiceCount(invoiceCount);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };

  const fetchPurchaseCount = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + `/fetchPurchaseOrder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        fromDate: "",
        toDate: "",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const purchaseCount = data.responseMessage1;
          setPurchaseCount(purchaseCount);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };

  const fetchCustomerCount = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + `/getAllCustomerName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const customerCount = data.responseMessage1;
          setCustomerCount(customerCount);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };

  const fetchItemCount = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + `/getAllItemData`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, vendorId: "" }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const itemCount = data.rowCount;
          setItemCount(itemCount);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };

  const fetchAmountDue = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + `/totalReceivables`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setCurrentValue(data.tocurrect);
          setOverValue(data.tooverDue);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };

  const fetchBillAmountBill = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + `/fetchTotalPaybles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        fromData: "",
        toDate: "",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setCurrentValueBill(data.current);
          setOverValueBill(data.overDue);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };

  useEffect(() => {
    fetchInvoiceCount();
    fetchPurchaseCount();
    fetchCustomerCount();
    fetchItemCount();
    fetchAmountDue();
    fetchBillAmountBill();
  }, []);

  const currentPercentage = ((currentValue / totalUnpaid) * 100).toFixed(2); // Limit to 2 decimal places

  const currentPercentageBill = (
    (currentValueFloatBill / totalUnpaidBill) *
    100
  ).toFixed(2);

  useEffect(() => {
    const isFirstTime = sessionStorage.getItem("hasSeenAlert") === null;
    if (isFirstTime) {
      const timer = setTimeout(() => {
        sessionStorage.setItem("hasSeenAlert", "true");
      }, 15000);
      return () => clearTimeout(timer);
    } else {
    }
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row text-center ml-0">
          <div
            className="dashboard-card number-chart mx-2 shadow-sm  bg-whiterounded cardhead"
            style={{
              width: "48%",
              height: "180px",
              backgroundColor: "white",
              border: "solid 1px rgba(0,0,0,0.1)",
            }}
          >
            <div className="body">
              <div className="additional-row ">
                <div className="row text-center">
                  <div className="col-md-6 ml-3 mt-4 text-left">
                    {" "}
                    <span
                      style={{ color: "black", fontSize: "20px" }}
                      className=""
                    >
                      Total Receivables
                    </span>
                  </div>
                </div>
              </div>
              <br />

              <div className="body col-md-12 mt-60">
                <ul className="list-unstyled">
                  <li>
                    <h6 className="d-flex justify-content-between align-items-center">
                      <span className="text-muted">Total unpaid invoices</span>
                      <span className="text-muted font-14">
                        {isNaN(currentPercentage) ? 0 : currentPercentage} %{" "}
                      </span>
                    </h6>
                    <div className="mb-3">
                      <ProgressBar
                        now={isNaN(currentPercentage) ? 0 : currentPercentage}
                        className="progress-xs progress-transparent custom-color-blue"
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div
                className="additional-row  text-uppercase"
                style={{ color: "black" }}
              >
                <div className="row mt-0">
                  <div className="col-md-5 current-column">
                    <span
                      style={{
                        color: "MediumSeaGreen",
                        fontSize: "16px",
                        paddingBottom: "5px",
                      }}
                      className="text-left"
                    >
                      CURRENT
                    </span>{" "}
                    <p style={{ margin: "5px 0", fontSize: "16px" }}>
                      {isNaN(currentValue) ? 0 : currentValue}{" "}
                    </p>{" "}
                  </div>

                  <div className="col-md-5">
                    <span
                      style={{ color: "red", fontSize: "16px" }}
                      className="text-center"
                    >
                      OverDue
                    </span>{" "}
                    <p style={{ margin: "5px 0", fontSize: "16px" }}>
                      {isNaN(overValue) ? 0 : overValue}
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="dashboard-card number-chart mx-2 shadow-sm  bg-whiterounded cardhead"
            style={{
              width: "48%",
              height: "180px",
              backgroundColor: "white",
              border: "solid 1px rgba(0,0,0,0.01)",
            }}
          >
            <div className="body">
              <div className="additional-row ">
                <div className="row text-center pt-10">
                  <div className="col-md-6 ml-3 mt-4 text-left">
                    {" "}
                    <span
                      style={{ color: "black", fontSize: "20px" }}
                      className=""
                    >
                      Total Payables
                    </span>
                  </div>
                </div>
              </div>
              <br />

              <div className="body col-md-12 mt-60">
                <ul className="list-unstyled">
                  <li>
                    <h6 className="d-flex justify-content-between align-items-center">
                      <span className="text-muted">Total unpaid bills </span>
                      <span className="text-muted font-14">
                        {isNaN(currentPercentageBill)
                          ? 0
                          : currentPercentageBill}{" "}
                        %{" "}
                      </span>
                    </h6>
                    <div className="mb-3">
                      <ProgressBar
                        now={
                          isNaN(currentPercentageBill)
                            ? 0
                            : currentPercentageBill
                        }
                        className="progress-xs progress-transparent custom-color-blue"
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div
                className="additional-row mt-2 text-uppercase"
                style={{ color: "black" }}
              >
                <div className="row mt-0">
                  <div className="col-md-5 current-column">
                    <span
                      style={{
                        color: "MediumSeaGreen",
                        fontSize: "16px",
                        paddingBottom: "5px",
                      }}
                      className="text-left"
                    >
                      CURRENT
                    </span>{" "}
                    <p style={{ margin: "5px 0", fontSize: "16px" }}>
                      {isNaN(currentValueBill) ? 0 : currentValueBill}
                    </p>{" "}
                  </div>

                  <div className="col-md-5">
                    <span
                      style={{ color: "red", fontSize: "16px" }}
                      className="text-center"
                    >
                      OverDue
                    </span>{" "}
                    <p style={{ margin: "5px 0", fontSize: "16px" }}>
                      {isNaN(overValueBill) ? 0 : overValueBill}
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row ml-2">
          <div
            className="card number-chart  count-card shadow-sm  bg-white  rounded"
            style={{
              width: "24%",
              height: "110px",
              border: "solid 1px rgba(0,0,0,0.1)",
            }}
          >
            <div
              className="body"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div style={{ textAlign: "left" }}>
                <span
                  style={{
                    color: "black",
                    fontSize: "20px",
                    paddingBottom: "5px",
                  }}
                  className="text-left"
                >
                  Invoice
                </span>{" "}
              </div>
              <div
                style={{
                  textAlign: "right",
                  marginTop: "auto",
                  fontSize: "16px",
                }}
              >
                <h4 className="mb-0 mt-2">{invoiceCount}</h4>
              </div>
            </div>
          </div>
          <div
            className="card number-chart mx-2 count-card shadow-sm  bg-white rounded"
            style={{
              width: "24%",
              height: "110px",
              border: "solid 1px rgba(0,0,0,0.1)",
            }}
          >
            <div
              className="body"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div style={{ textAlign: "left" }}>
                <span
                  style={{
                    color: "black",
                    fontSize: "20px",
                    paddingBottom: "5px",
                  }}
                  className="text-left"
                >
                  Purchase
                </span>{" "}
              </div>
              <div
                style={{
                  textAlign: "end",
                  fontSize: "16px",
                }}
              >
                <h4 className="mb-0 mt-2"> {purchaseCount}</h4>
              </div>
            </div>
          </div>

          <div
            className="card number-chart  count-card shadow-sm  bg-white rounded "
            style={{
              width: "24%",
              height: "110px",
              border: "solid 1px rgba(0,0,0,0.1)",
            }}
          >
            <div
              className="body"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div style={{ textAlign: "left" }}>
                <span
                  style={{
                    color: "black",
                    fontSize: "20px",
                    paddingBottom: "5px",
                  }}
                  className="text-left"
                >
                  Item
                </span>{" "}
              </div>
              <div
                style={{
                  textAlign: "right",
                  marginTop: "auto",
                  fontSize: "16px",
                }}
              >
                <h4 className="mb-0 mt-2">{itemCount}</h4>
              </div>
            </div>
          </div>
          <div
            className="card number-chart mx-2  count-card shadow-sm  bg-white rounded"
            style={{
              width: "24%",
              height: "110px",
              border: "solid 1px rgba(0,0,0,0.1)",
            }}
          >
            <div
              className="body"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div style={{ textAlign: "left" }}>
                <span
                  style={{
                    color: "black",
                    fontSize: "20px",
                    paddingBottom: "5px",
                  }}
                  className="text-left"
                >
                  Customer
                </span>{" "}
              </div>
              <div
                style={{
                  textAlign: "right",
                  marginTop: "auto",
                  fontSize: "16px",
                }}
              >
                <h4 className="mb-0 mt-2">{customerCount}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row clearfix row-deck">
        <div className="col-lg-12">
          <div className="card dashboard-card">
            <CashFlowChart lineChartData={lineChartData} />
          </div>
        </div>
      </div>
      <div className="row clearfix row-deck">
        <GoogleAnalyticCard />
      </div>
    </>
  );
};

export default Analytical;
