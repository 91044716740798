import React, { useState } from "react";
import "./header.css";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import LocalConvenienceStoreIcon from "@mui/icons-material/LocalConvenienceStore";
import SummarizeIcon from "@mui/icons-material/Summarize";
import DevicesOtherOutlinedIcon from "@mui/icons-material/DevicesOtherOutlined";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice, faUsers } from "@fortawesome/free-solid-svg-icons";

class Mainmenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainActive: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      blogActive: false,
      fMActive: false,
      color: true,
    };
  }

  componentDidMount() {
    const { navigation } = this.props;
    const activeKey = navigation.state.routes[navigation.state.index].key;
    if (activeKey === "dashboard") {
      var mA = [
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ];
      this.setState({
        mainActive: mA,
        blogActive: false,
        fMActive: true,
      });
    } else if (activeKey === "items" || activeKey === "ItemsNew") {
      var mA = [
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ];
      this.setState({
        blogActive: false,
        mainActive: mA,
        fMActive: false,
      });
    } else if (
      activeKey === "bankacc" ||
      activeKey === "BankAccNew" ||
      activeKey === "calendar" ||
      activeKey === "contactList" ||
      activeKey === "contactCard" ||
      activeKey === "taskbar"
    ) {
      var mA = [
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ];
      this.setState({
        mainActive: mA,
        blogActive: false,
        fMActive: false,
      });
    } else if (
      activeKey === "customers" ||
      activeKey === "Estimates" ||
      activeKey === "Order" ||
      activeKey === "Challans" ||
      activeKey == "Invoices" ||
      activeKey == "Payment" ||
      activeKey == "Credit" ||
      activeKey === "RecurringInvoices" ||
      activeKey === "CustomerNew" ||
      activeKey === "EstimatesNewButton" ||
      activeKey === "SalesOrderNewButton" ||
      activeKey === "DeliveryChallansNew" ||
      activeKey === "InvoicesNew" ||
      activeKey === "PaymentRecivedNew" ||
      activeKey === "CreditNoteNew" ||
      activeKey === "Proforma" ||
      activeKey === "quotes" ||
      activeKey === "quotesnew"
    ) {
      var mA = [
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ];
      this.setState({
        mainActive: mA,
        blogActive: false,
        fMActive: false,
      });
    } else if (
      activeKey === "vendors" ||
      activeKey === "expenses" ||
      activeKey === "bills" ||
      activeKey === "purchaseOrder" ||
      activeKey === "EwayBills" ||
      activeKey === "EwayBillsNew" ||
      activeKey === "VendorsNew" ||
      activeKey === "ExpensesNew" ||
      activeKey === "BillsNew" ||
      activeKey === "PurchaseOrderNew"
    ) {
      var mA = [
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ];
      this.setState({
        mainActive: mA,
        blogActive: false,
        fMActive: false,
      });
    } else if (
      activeKey === "manageEmployee" ||
      activeKey === "timeSheetLog" ||
      activeKey === "manageServer" ||
      activeKey === "manageProject" ||
      activeKey === "managePortfolio" ||
      activeKey === "Setting"
    ) {
      var mA = [
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
      ];
      this.setState({
        mainActive: mA,
        blogActive: false,
        fMActive: false,
      });
    } else if (
      activeKey === "manageRecipe" ||
      activeKey === "manageSalesPerson" ||
      activeKey === "manageCategory" ||
      activeKey === "manageStock"
    ) {
      var mA = [
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
      ];
      this.setState({
        mainActive: mA,
        blogActive: false,
        fMActive: false,
      });
    } else if (activeKey === "students" || activeKey === "fees") {
      var mA = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
      ];
      this.setState({
        mainActive: mA,
        blogActive: false,
        fMActive: false,
      });
    } else if (activeKey === "razorpay") {
      var mA = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
      ];
      this.setState({
        mainActive: mA,
        blogActive: false,
        fMActive: false,
      });
    } else if (activeKey === "Users") {
      var mA = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
      ];
      this.setState({
        mainActive: mA,
        blogActive: false,
        fMActive: false,
      });
    } else if (activeKey === "SalesPerson") {
      var mA = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
      ];
      this.setState({
        mainActive: mA,
        blogActive: false,
        fMActive: false,
      });
    } else if (activeKey === "GstFilling") {
      var mA = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
      ];
      this.setState({
        mainActive: mA,
        blogActive: false,
        fMActive: false,
      });
    } else if (activeKey === "reports") {
      var mA = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
      ];
      this.setState({
        mainActive: mA,
        blogActive: false,
        fMActive: false,
      });
    }
  }

  render() {
    const { navigation } = this.props;
    const { routeName } = navigation.state;
    const activeKey = navigation.state.routes[navigation.state.index].key;
    const { mainActive, blogActive, fMActive } = this.state;
    // const [light, setLight] = useState(true);
    const check = localStorage.getItem("check");
    const isTrue = mainActive[2] ? `active` : "" + "child-cont";

    const handle = () => {
      this.setState({ isToggle: !this.state.isToggle });
    };
    return (
      <div
        className="tab-pane active text"
        id="menu"
        style={{
          fontFamily: 'Inter, "Source Sans Pro", Helvetica, Arial, sans-serif',
          lineHeight: "26.8px",
          color: "rgb(24, 28, 46)",
        }}
      >
        <nav id="left-sidebar-nav text" className="sidebar-nav all text">
          <ul id="main-menu" className="metismenu li_animation_delay text">
            {/* Dashboard */}
            <li
              className={mainActive[0] ? "active" : ""}
              onClick={() => {
                var mA = [
                  true,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                ];
                if (!this.state.mainActive[0]) {
                  if (activeKey !== "dashboard" || mainActive[0] !== true) {
                    this.setState({
                      mainActive: mA,
                      blogActive: false,
                      fMActive: false,
                    });
                  } else {
                    this.setState({
                      mainActive: true,
                      blogActive: false,
                      fMActive: false,
                    });
                  }
                }
              }}
            >
              <li
                className={
                  activeKey === "dashboard" && mainActive[0] === true
                    ? "active"
                    : ""
                }
                onClick={() => {
                  if (activeKey !== "dashboard") {
                    navigation.navigate("dashboard");
                  }
                }}
              >
                <a href="#" className="pro active cont">
                  <DashboardOutlinedIcon />
                  <span className="ml-2">Dashboard</span>
                </a>
              </li>
            </li>

            <li
              className={mainActive[1] ? "active" : ""}
              onClick={() => {
                var mA = [
                  false,
                  true,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                ];

                if (!this.state.mainActive[1]) {
                  if (activeKey !== "items" || "itemsNew") {
                    this.setState({
                      mainActive: mA,
                      blogActive: false,
                      fMActive: false,
                    });
                  } else {
                    this.setState({
                      mainActive: true,
                      blogActive: false,
                      fMActive: false,
                    });
                  }
                }
                // mA[0] = !this.state.mainActive[0];
              }}
            >
              <li
                className={
                  (activeKey === "items" || "itemsNew") &&
                  mainActive[1] === true
                    ? "active"
                    : ""
                }
              >
                <a
                  href="#"
                  className=" pro active cont"
                  onClick={() => {
                    if (activeKey !== "items") {
                      navigation.navigate("items");
                    }
                  }}
                >
                  <div className="cir">
                    <span className="pro">
                      {" "}
                      <CategoryOutlinedIcon />{" "}
                      <span className="ml-2">Items</span>
                    </span>
                    <span
                      className="circlehid"
                      onClick={() => {
                        if (activeKey !== "ItemsNew") {
                          navigation.navigate("ItemsNew");
                        }
                      }}
                    >
                      <AddCircleIcon className="circlechild" />
                    </span>
                  </div>
                </a>
              </li>
            </li>

            {/* Banking */}

            <li className={mainActive[2] ? `active` : ""}>
              <a
                href="#Banking"
                className="text has-arrow active cont"
                onClick={() => {
                  var mA = [
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                  ];

                  mA[2] = !this.state.mainActive[2];

                  this.setState({
                    mainActive: mA,
                    blogActive: false,
                    fMActive: false,
                  });
                }}
              >
                <span className="pro">
                  <AccountBalanceOutlinedIcon />{" "}
                  <span className="ml-2">Banking</span>
                </span>
              </a>
            </li>

            <ul
              aria-expanded="false"
              className={mainActive[2] ? `collapse in ` : "collapse"}
            >
              <li
                className={
                  activeKey === "bankacc" || activeKey === "BankAccNew"
                    ? `active`
                    : "" + "child-cont"
                }
                onClick={(e) => {
                  e.preventDefault();
                  if (activeKey !== "bankacc") {
                    navigation.navigate("bankacc");
                  }
                }}
              >
                <a href="#" className="text pro">
                  <div className="cir">
                    <span className="pro"> Bank Account </span>
                    <span
                      className="circlehid"
                      onClick={() => {
                        if (activeKey !== "BankAccNew") {
                          navigation.navigate("BankAccNew");
                        }
                      }}
                    >
                      <AddCircleIcon className="circlechild" />
                    </span>
                  </div>
                </a>
              </li>
            </ul>

            {/* Sales Start */}
            <li className={mainActive[3] ? "active" : ""}>
              <a
                href="#Sales"
                className="text has-arrow cont"
                onClick={() => {
                  var mA = [
                    false,
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                  ];
                  mA[3] = !this.state.mainActive[3];
                  this.setState({
                    mainActive: mA,
                    blogActive: false,
                    fMActive: false,
                  });
                }}
              >
                <span className="pro">
                  {" "}
                  <ShoppingCartOutlinedIcon />{" "}
                  <span className="ml-2">Sales</span>
                </span>
              </a>
            </li>

            <ul
              aria-expanded="false"
              className={mainActive[3] ? "collapse in" : "collapse"}
              id="stacked-menu"
              style={{}}
            >
              <li
                className={
                  activeKey === "Customers" || activeKey === "CustomerNew"
                    ? "active"
                    : "" + "child-cont"
                }
                onClick={() => {
                  if (activeKey !== "Customers") {
                    navigation.navigate("Customers");
                  }
                }}
              >
                <a href="#" className="text pro">
                  <div className={"cir"}>
                    <span className="pro"> Customers</span>{" "}
                    <span
                      className="circlehid"
                      onClick={() => {
                        if (activeKey !== "CustomerNew") {
                          navigation.navigate("CustomerNew");
                        }
                      }}
                    >
                      <AddCircleIcon className="circlechild" />
                    </span>
                  </div>
                </a>
              </li>
              <li
                className={
                  activeKey === "Estimates" ||
                  activeKey === "EstimatesNewButton"
                    ? "active"
                    : "" + "child-cont"
                }
                onClick={() => {
                  if (activeKey !== "Estimates") {
                    navigation.navigate("Estimates");
                  }
                }}
              >
                <a href="#" className="text pro">
                  <div className={"cir"}>
                    <span className="pro"> Estimates</span>{" "}
                    <span
                      className="circlehid"
                      onClick={() => {
                        if (activeKey !== "EstimatesNewButton") {
                          navigation.navigate("EstimatesNewButton");
                        }
                      }}
                    >
                      <AddCircleIcon className="circlechild" />
                    </span>{" "}
                  </div>
                </a>
              </li>
              <li
                className={
                  activeKey === "Order" || activeKey === "SalesOrderNewButton"
                    ? "active"
                    : "" + "child-cont"
                }
                onClick={() => {
                  if (activeKey !== "Order") {
                    navigation.navigate("Order");
                  }
                }}
              >
                <a href="#" className="text pro">
                  <div className="cir">
                    <span className="pro"> Sales Orders</span>{" "}
                    <span
                      className="circlehid"
                      onClick={() => {
                        if (activeKey !== "SalesOrderNewButton") {
                          navigation.navigate("SalesOrderNewButton");
                        }
                      }}
                    >
                      {" "}
                      <AddCircleIcon className="circlechild" />
                    </span>{" "}
                  </div>
                </a>
              </li>
              <li
                className={
                  activeKey === "Challans" ||
                  activeKey === "DeliveryChallansNew"
                    ? "active"
                    : "" + "child-cont"
                }
                onClick={() => {
                  if (activeKey !== "Challans") {
                    navigation.navigate("Challans");
                  }
                }}
              >
                <a href="#" className="text pro">
                  <div className="cir">
                    <span className="pro"> Delivery Challans</span>{" "}
                    <span
                      className="circlehid"
                      onClick={() => {
                        if (activeKey !== "DeliveryChallansNew") {
                          navigation.navigate("DeliveryChallansNew");
                        }
                      }}
                    >
                      <AddCircleIcon className="circlechild" />
                    </span>{" "}
                  </div>
                </a>
              </li>
              <li
                className={
                  activeKey === "Invoices" || activeKey === "InvoicesNew"
                    ? "active"
                    : "" + "child-cont"
                }
                onClick={() => {
                  if (activeKey !== "Invoices") {
                    navigation.navigate("Invoices");
                  }
                }}
              >
                <a href="#" className="text pro">
                  <div className="cir">
                    <span className="pro">Invoices</span>
                    <span
                      className="circlehid"
                      onClick={() => {
                        if (activeKey !== "InvoicesNew") {
                          navigation.navigate("InvoicesNew");
                        }
                      }}
                    >
                      <AddCircleIcon className="circlechild" />
                    </span>{" "}
                  </div>
                </a>
              </li>
              <li
                className={
                  activeKey === "Proforma" || activeKey === "ProformaNew"
                    ? "active"
                    : "" + "child-cont"
                }
                onClick={() => {
                  if (activeKey !== "Proforma") {
                    navigation.navigate("Proforma");
                  }
                }}
              >
                <a href="#" className="text pro">
                  <div className="cir">
                    <span className="pro">Pro Forma</span>
                    <span
                      className="circlehid"
                      onClick={() => {
                        if (activeKey !== "ProformaNew") {
                          navigation.navigate("ProformaNew");
                        }
                      }}
                    >
                      <AddCircleIcon className="circlechild" />
                    </span>{" "}
                  </div>
                </a>
              </li>
              <li
                className={
                  activeKey === "RecurringInvoices" ||
                  activeKey === "RecurringInvoiceNew"
                    ? "active"
                    : "" + "child-cont"
                }
                onClick={() => {
                  if (activeKey !== "RecurringInvoices") {
                    navigation.navigate("RecurringInvoices");
                  }
                }}
              >
                <a href="#" className="text pro">
                  <div className="cir">
                    <span className="pro">Recurring Invoices</span>{" "}
                    <span
                      className="circlehid"
                      onClick={() => {
                        if (activeKey !== "RecurringInvoiceNew") {
                          navigation.navigate("RecurringInvoiceNew");
                        }
                      }}
                    >
                      <AddCircleIcon className="circlechild" />
                    </span>{" "}
                  </div>
                </a>
              </li>
              <li
                className={
                  activeKey === "Payment" || activeKey === "PaymentRecivedNew"
                    ? "active"
                    : "" + "child-cont"
                }
                onClick={() => {
                  if (activeKey !== "Payment") {
                    navigation.navigate("Payment");
                  }
                }}
              >
                <a href="#" className="text pro">
                  <div className="cir">
                    <span className="pro">Payment Received</span>{" "}
                    <span
                      className="circlehid"
                      onClick={() => {
                        if (activeKey !== "PaymentRecivedNew") {
                          navigation.navigate("PaymentRecivedNew");
                        }
                      }}
                    >
                      <AddCircleIcon className="circlechild" />
                    </span>
                  </div>
                </a>
              </li>
              <li
                className={
                  activeKey === "Credit" || activeKey === "CreditNoteNew"
                    ? "active"
                    : "" + "child-cont"
                }
                onClick={() => {
                  if (activeKey !== "Credit") {
                    navigation.navigate("Credit");
                  }
                }}
              >
                <a href="#" className="text pro">
                  <div className="cir">
                    <span className="pro"> Credit Notes</span>{" "}
                    <span
                      className="circlehid"
                      onClick={() => {
                        if (activeKey !== "CreditNoteNew") {
                          navigation.navigate("CreditNoteNew");
                        }
                      }}
                    >
                      <AddCircleIcon className="circlechild" />
                    </span>{" "}
                  </div>
                </a>
              </li>
              <li
                className={
                  activeKey === "quotes" || activeKey === "quotesnew"
                    ? "active"
                    : "" + "child-cont"
                }
                onClick={() => {
                  if (activeKey !== "quotes") {
                    navigation.navigate("quotes");
                  }
                }}
              >
                <a href="#" className="text pro">
                  <div className="cir">
                    <span className="pro">Quotations</span>{" "}
                    <span
                      className="circlehid"
                      onClick={() => {
                        if (activeKey !== "quotesnew") {
                          navigation.navigate("quotesnew");
                        }
                      }}
                    >
                      <AddCircleIcon className="circlechild" />
                    </span>{" "}
                  </div>
                </a>
              </li>
            </ul>

            {/* Purchase */}
            <li className={mainActive[4] ? `active` : ""}>
              <a
                href="#Purchases"
                className="text has-arrow cont"
                onClick={() => {
                  var mA = [
                    false,
                    false,
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                  ];
                  mA[4] = !this.state.mainActive[4];
                  this.setState({
                    mainActive: mA,
                    blogActive: false,
                    fMActive: false,
                  });
                }}
              >
                <span className="pro">
                  {" "}
                  <ReceiptOutlinedIcon /> <span className="ml-2">Purchase</span>
                </span>
              </a>
            </li>

            <ul
              aria-expanded="false"
              className={mainActive[4] ? `collapse in` : "collapse"}
            >
              {/* <li className={activeKey === 'purchase'?`active`:''}><a href="#" onClick={()=>{ if(activeKey !== 'purchase'){navigation.navigate("purchase")} }}>Purchase</a></li> */}
              <li
                className={
                  activeKey === "vendors" || activeKey === "VendorsNew"
                    ? `active`
                    : "" + "child-cont"
                }
                onClick={() => {
                  if (activeKey !== "vendors") {
                    navigation.navigate("vendors");
                  }
                }}
              >
                <a href="#" className="text pro">
                  <div className="cir">
                    <span className="pro">Vendors</span>{" "}
                    <span
                      className="circlehid"
                      onClick={() => {
                        if (activeKey !== "VendorsNew") {
                          navigation.navigate("VendorsNew");
                        }
                      }}
                    >
                      <AddCircleIcon className="circlechild" />
                    </span>
                  </div>
                </a>
              </li>
              <li
                className={
                  activeKey === "expenses" || activeKey === "ExpensesNew"
                    ? `active`
                    : "" + "child-cont"
                }
                onClick={() => {
                  if (activeKey !== "expenses") {
                    navigation.navigate("expenses");
                  }
                }}
              >
                <a href="#" className="text pro">
                  <div className="cir">
                    <span className="pro">Expenses</span>{" "}
                    <span
                      className="circlehid"
                      onClick={() => {
                        if (activeKey !== "ExpensesNew") {
                          navigation.navigate("ExpensesNew");
                        }
                      }}
                    >
                      <AddCircleIcon className="circlechild" />
                    </span>{" "}
                  </div>
                </a>
              </li>
              <li
                className={
                  activeKey === "bills" || activeKey === "BillsNew"
                    ? `active`
                    : "" + "child-cont"
                }
                onClick={() => {
                  if (activeKey !== "bills") {
                    navigation.navigate("bills");
                  }
                }}
              >
                <a href="#" className="text pro">
                  <div className="cir">
                    <span className="pro">Bills</span>{" "}
                    <span
                      className="circlehid"
                      onClick={() => {
                        if (activeKey !== "BillsNew") {
                          navigation.navigate("BillsNew");
                        }
                      }}
                    >
                      <AddCircleIcon className="circlechild" />
                    </span>{" "}
                  </div>
                </a>
              </li>
              <li
                className={
                  activeKey === "purchaseOrder" ||
                  activeKey === "PurchaseOrderNew"
                    ? `active`
                    : "" + "child-cont"
                }
                onClick={() => {
                  if (activeKey !== "purchaseOrder") {
                    navigation.navigate("purchaseOrder");
                  }
                }}
              >
                <a href="#" className="text pro">
                  <div className="cir">
                    <span className="pro">Purchase Order</span>{" "}
                    <span
                      className="circlehid"
                      onClick={() => {
                        if (activeKey !== "PurchaseOrderNew") {
                          navigation.navigate("PurchaseOrderNew");
                        }
                      }}
                    >
                      <AddCircleIcon className="circlechild" />
                    </span>{" "}
                  </div>
                </a>
              </li>
              <li
                className={
                  activeKey === "paymentsMade" ||
                  activeKey === "PaymentsMadeNew"
                    ? `active`
                    : "" + "child-cont"
                }
                onClick={() => {
                  if (activeKey !== "paymentsMade") {
                    navigation.navigate("paymentsMade");
                  }
                }}
              >
                <a href="#" className="text pro">
                  <div className="cir">
                    <span className="pro">Payments Made</span>{" "}
                    <span
                      className="circlehid"
                      onClick={() => {
                        if (activeKey !== "PaymentsMadeNew") {
                          navigation.navigate("PaymentsMadeNew");
                        }
                      }}
                    >
                      {" "}
                      <AddCircleIcon className="circlechild" />
                    </span>{" "}
                  </div>
                </a>
              </li>
              <li
                className={
                  activeKey === "EwayBills" ? `active` : "" + "child-cont"
                }
                onClick={() => {
                  if (activeKey !== "EwayBills") {
                    navigation.navigate("EwayBills");
                  }
                }}
              >
                <a href="#" className="text pro">
                  <div className="cir">
                    <span className="pro">E-Way Bill</span>{" "}
                    <AddCircleIcon className="circlechild" />
                  </div>
                </a>
              </li>
            </ul>

            <li className={mainActive[9] ? "active" : ""}>
              <a
                href="#Users"
                className="text has-arrow cont"
                onClick={() => {
                  var mA = [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    false,
                  ];
                  mA[9] = !this.state.mainActive[9];
                  this.setState({
                    mainActive: mA,
                    blogActive: false,
                    fMActive: false,
                  });
                }}
              >
                <span className="pro">
                  {" "}
                  <PeopleOutlinedIcon />{" "}
                  <span className="ml-2">Users & Roles</span>
                </span>
              </a>
            </li>

            <ul
              aria-expanded="false"
              className={mainActive[9] ? `collapse in` : "collapse"}
            >
              <li
                className={activeKey === "Users" ? "active" : "" + "child-cont"}
                onClick={() => {
                  if (activeKey !== "Users") {
                    navigation.navigate("Users");
                  }
                }}
              >
                <a href="#" className="text pro">
                  <div className="cir">
                    <span className="pro">Users</span>{" "}
                  </div>
                </a>
              </li>
            </ul>
            <li
              className={mainActive[10] ? "active" : ""}
              onClick={() => {
                var mA = [
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  true,
                  false,
                  false,
                  false,
                  false,
                  false,
                ];
                // mA[10] = !this.state.mainActive[10];
                if (!this.state.mainActive[10]) {
                  if (activeKey !== "GstFilling" || mainActive[10] !== true) {
                    this.setState({
                      mainActive: mA,
                      blogActive: false,
                      fMActive: false,
                    });
                  } else {
                    this.setState({
                      mainActive: true,
                      blogActive: false,
                      fMActive: false,
                    });
                  }
                }
              }}
            >
              <li
                className={
                  activeKey === "SalesPerson" && mainActive[10] === true
                    ? "active"
                    : ""
                }
              >
                <a
                  href="#"
                  className="text cont"
                  onClick={() => {
                    if (activeKey !== "SalesPerson") {
                      navigation.navigate("SalesPerson");
                    }
                  }}
                >
                  {" "}
                  <span className="pro">
                    {" "}
                    <PersonOutlineOutlinedIcon />{" "}
                    <span className="ml-2">Manage SalesPerson</span>
                  </span>
                </a>
              </li>
            </li>
            {check === "YES" && (
              <li
                className={mainActive[11] ? "active" : ""}
                onClick={() => {
                  var mA = [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                    false,
                  ];
                  // mA[10] = !this.state.mainActive[10];
                  if (!this.state.mainActive[11]) {
                    if (activeKey !== "GstFilling" || mainActive[11] !== true) {
                      this.setState({
                        mainActive: mA,
                        blogActive: false,
                        fMActive: false,
                      });
                    } else {
                      this.setState({
                        mainActive: true,
                        blogActive: false,
                        fMActive: false,
                      });
                    }
                  }
                }}
              >
                <li
                  className={
                    activeKey === "GstFilling" && mainActive[11] === true
                      ? "active"
                      : ""
                  }
                >
                  <a
                    href="#"
                    className="text cont"
                    onClick={() => {
                      if (activeKey !== "GstFilling") {
                        navigation.navigate("GstFilling");
                      }
                    }}
                  >
                    {" "}
                    <span className="pro">
                      {" "}
                      <AssignmentOutlinedIcon />{" "}
                      <span className="ml-2">GST Filling</span>
                    </span>
                  </a>
                </li>
              </li>
            )}
            <li
              className={mainActive[12] ? "active" : ""}
              onClick={() => {
                var mA = [
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  true,
                  false,
                  false,
                  false,
                ];
                // mA[10] = !this.state.mainActive[10];
                if (!this.state.mainActive[12]) {
                  if (activeKey !== "reports" || mainActive[12] !== true) {
                    this.setState({
                      mainActive: mA,
                      blogActive: false,
                      fMActive: false,
                    });
                  } else {
                    this.setState({
                      mainActive: true,
                      blogActive: false,
                      fMActive: false,
                    });
                  }
                }
              }}
            >
              <li
                className={
                  activeKey === "reports" && mainActive[12] === true
                    ? "active"
                    : ""
                }
              >
                <a
                  href="#"
                  className="text cont"
                  onClick={() => {
                    if (activeKey !== "reports") {
                      navigation.navigate("reports");
                    }
                  }}
                >
                  {" "}
                  <span className="pro">
                    {" "}
                    <BarChartOutlinedIcon />{" "}
                    <span className="ml-2">Reports</span>
                  </span>
                </a>
              </li>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}
export default Mainmenu;
