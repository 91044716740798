import React, { useState } from "react";
import { XCircle } from "phosphor-react";
import swal from "sweetalert";

function StudentNew(props) {
  const handleCancleClick = () => {
    props.handleCancle();
  };

  const [serverData, setServerData] = useState({
    someName: "",
    firstName: "",
    lastName: "",
    studentAddress: "",
    studentEmail: "",
    phone1: "",
    phone2: "",
    collegeName: "",
    department: "",
    description: "",
    process: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setServerData({
      ...serverData,
      [name]: value,
    });
  };

  const handleSaveClick = () => {
    handleCancleClick();
    // Define the URL of your API endpoint
    const apiUrl = "http://localhost:8096/api/insertStudent";

    // Define the request headers
    const headers = {
      "Content-Type": "application/json",
    };

    // Create the request body by converting the serverData object to JSON
    const requestBody = JSON.stringify(serverData);

    // Send a POST request to the API
    fetch(apiUrl, {
      method: "POST",
      headers,
      body: requestBody,
    })
      .then((response) => {
        if (response.ok) {
          swal({
            icon: "success",
            title: "Estimate Data Inserted Successfully",
          });
        } else {
          console.error("Error adding server");
          swal({
            icon: "error",
            title: "Failed to insert data",
          });
        }
      })
      .catch((error) => {
        console.error("Error adding server", error);
      });
  };

  return (
    <div className="container my-3">
      <div className="border border-2 p-2">
        <div className="px-2 d-flex justify-content-between">
          <h4 className="mt-3 mx-2" style={{ letterSpacing: "1px" }}>
            <b>Add New Student</b>
          </h4>
          <button className="btn" onClick={handleCancleClick}>
            <XCircle size={32} weight="fill" />
          </button>
        </div>
        <hr />
        <div className="container">
          <div className="row mg-t-15 pt-3  align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label   mg-b-0">
                <span className="text-dashed-underline">
                  <b>Primary Contact : </b>
                </span>
              </label>
            </div>
            <div className="">
              <select
                className="form-control"
                value={serverData.someName}
                onChange={handleInputChange}
              >
                <option value="Mr.">Mr.</option>
                <option value="Mrs.">Mrs.</option>
                <option value="Ms.">Ms.</option>
                <option value="Miss.">Miss.</option>
                <option value="Dr.">Dr.</option>
              </select>
            </div>
            <div className="col-md-12 col-lg-3 col-xl-2 ">
              <input
                className="form-control"
                placeholder="First Name"
                name="firstName"
                value={serverData.firstName}
                onChange={handleInputChange}
                id="firstName"
                type="text"
              />
            </div>
            <div>
              <input
                className="form-control"
                placeholder="Last Name"
                id="lastName"
                name="lastName"
                value={serverData.lastName}
                onChange={handleInputChange}
                type="text"
              />
            </div>
          </div>

          <div className="row mg-t-15 pt-3  align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label   mg-b-0">
                <span className="text-dashed-underline">
                  <b>Student Address : </b>
                </span>
              </label>
            </div>
            <div className="col-md-3 pl-0">
              <textarea
                className="form-control"
                placeholder="Student Address"
                name="studentAddress"
                value={serverData.studentAddress}
                onChange={handleInputChange}
                type="text"
              />
            </div>
          </div>

          <div className="row mg-t-15 pt-3  align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label   mg-b-0">
                <span className="text-dashed-underline">
                  <b>Student Email : </b>
                </span>
              </label>
            </div>
            <div className="col-md-3 pl-0">
              <input
                className="form-control"
                placeholder="Student Email"
                name="studentEmail"
                value={serverData.studentEmail}
                onChange={handleInputChange}
                type="text"
              />
            </div>
          </div>

          <div className="row mg-t-15 pt-3  align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label   mg-b-0">
                <span className="text-dashed-underline">
                  <b>Student Phone : </b>
                </span>
              </label>
            </div>
            <div>
              <input
                className="form-control"
                placeholder="Work Phone"
                name="phone1"
                type="text"
                value={serverData.phone1}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <input
                className="form-control ml-2 "
                placeholder="Mobile"
                name="phone2"
                type="text"
                value={serverData.phone2}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="row mg-t-15 pt-3  align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label   mg-b-0">
                <span className="text-dashed-underline">
                  <b>College Name : </b>
                </span>
              </label>
            </div>
            <div className="col-md-3 pl-0">
              <input
                className="form-control"
                placeholder="College Name"
                name="collegeName"
                type="text"
                value={serverData.collegeName}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row mg-t-15 pt-3  align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label   mg-b-0">
                <span className="text-dashed-underline">
                  <b>Department: </b>
                </span>
              </label>
            </div>
            <div className="col-md-3 pl-0">
              <input
                className="form-control"
                placeholder="Department"
                name="department"
                type="text"
                value={serverData.department}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row mg-t-15 pt-3  align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label   mg-b-0">
                <span className="text-dashed-underline">
                  <b>Description: </b>
                </span>
              </label>
            </div>
            <div className="col-md-3 pl-0">
              <textarea
                className="form-control"
                placeholder="Description"
                name="description"
                type="text"
                value={serverData.description}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2" style={{ color: "#E54643" }}></div>
            <div>
              <label className="rdiobox">
                <input
                  className="mx-1"
                  type="radio"
                  name="Inquiry"
                  id="cus_indivi"
                  value="Inquiry"
                />
                <span>Inquiry </span>
              </label>
            </div>
            <div>
              <label className="rdiobox ml-3">
                <input
                  className="mx-1"
                  type="radio"
                  name="Inquiry"
                  id="cus_indivi"
                  value="Intern"
                />
                <span>Student</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="my-2">
        <button className="btn btn-success" onClick={handleSaveClick}>
          Save
        </button>
        <button className="btn btn-dark mx-2" onClick={handleCancleClick}>
          Cancle
        </button>
      </div>
    </div>
  );
}

export default StudentNew;
