import React, { useState, useEffect } from "react";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import usericon from "../../../asset/images/user-circlea.png";
import RecurringInvoiceUpdate from "./RecurringInvoiceUpdate";
import InvoiceDetails from "./InvoiceDetails";
import "./RecurringInvoiceDetails.css";

export default function RecurringInvoiceDetails(props) {
  const { newinvoiceNumber, custId } = props;
  const [detailsPage, setDetailsPage] = useState(true);
  const [recurringInvoice, setRecurringInvoice] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [companyName, setCompanyName] = useState([]);

  const [loading, setLoading] = useState(true);
  const [itemDetails, setItemDetails] = useState([]);
  const [invoiceNumber, setInvoiceId] = useState(null);
  const [invoiceEdit, setInvoiceEdit] = useState(false);
  const [clickedInvoiceNumber, setClickedInvoiceNumber] = useState(""); // Define invoiceNumber
  const [state, setState] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [someName, setSomeName] = useState(true);
  const [firstName, setFirstName] = useState(true);
  const [lastName, setLastName] = useState(true);
  const [custEmail, setCustomerEmail] = useState([]);
  const [showOfflinePay, setOfflinePay] = useState(false);
  const [offline, setOffline] = useState({});
  const [customerData, setCustomerData] = useState({
    someName: "",
    firstName: "",
    lastName: "",
    companyName: "",
    customerName: "",
  });
  const [showDetails, setShowDetails] = useState(true);
  const [showPdf, setShowPdf] = useState(false);
  const [selectedTab, setSelectedTab] = useState("overview");

  const jwtToken = localStorage.getItem("jwtToken");

  const handleCancleClick = () => {
    props.handleCancel();
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    setSelectedTab("overview");
  }, []);

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const userId = localStorage.getItem("userId");

    fetch(BASE_URL + `/fetchUserProfile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const userDetails = data.responseMessage;
          setUserDetails(userDetails);
          const companyName = data.responseMessage.companyName;
          setCompanyName(companyName);
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  }, []);

  useEffect(() => {
    // Get the encrypted uniqueId from session storage
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    // Fetch invoices for the decrypted uniqueId
    fetch(BASE_URL + `/fetchRecurringInvoice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setInvoices(data.responseMessage);
        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(
      BASE_URL + `/fetchRecurringInvoiceByInvoiceNumber/${newinvoiceNumber}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ uniqueId: decryptedUniqueId }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const itemDetails = data.responseMessage;
          setItemDetails(itemDetails);

          const invoiceDetails = data.responseMessage[0];
          setInvoiceDetails(invoiceDetails);

          const paymentInv = data.responseMessage[0].invoiceNumber;
          setOffline(paymentInv);
          // getCustomerData();
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  }, [newinvoiceNumber]);

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchCustomerById/${custId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const state = data.responseMessage.billState;
          setState(state);
          const customerName = data.responseMessage.customerName;
          setCustomerName(customerName);
          const Data = data.responseMessage;
          setCustomerData(Data);
          const custEmail = data.responseMessage.email;
          setCustomerEmail(custEmail);
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  }, [custId]);

  const handleInvoiceClick = (invoiceNumber) => {
    setSelectedTab("overview");
    setClickedInvoiceNumber(invoiceNumber); // Set invoiceNumber
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    fetch(BASE_URL + `/fetchRecurringInvoiceByInvoiceNumber/${invoiceNumber}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const itemDetails = data.responseMessage;
          setItemDetails(itemDetails);
          const invoiceDetails = data.responseMessage[0];
          setInvoiceDetails(invoiceDetails);
          const paymentInv = data.responseMessage[0].invoiceNumber;
          setOffline(paymentInv);
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  };

  const getCustomerData = (customerId) => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchCustomerById/${customerId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const state = data.responseMessage.billState;
          setState(state);
          const customerName = data.responseMessage.customerName;
          setCustomerName(customerName);

          const custEmail = data.responseMessage.email;

          setCustomerEmail(custEmail);

          setCustomerData(data.responseMessage);
        } else {
          setState("");
          setSomeName("");
          setFirstName("");
          setLastName("");
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  };

  useEffect(() => {
    const container = document.querySelector(".recurring-templates-container");
    const invoiceContainer = document.querySelector(".custom-invoice-section");

    if (selectedTab === "nextinvoice") {
      if (container) {
        container.style.height = "925px";
      }
      if (invoiceContainer) {
        invoiceContainer.style.maxHeight = "unset";
      }
    } else {
      if (container) {
        container.style.height = "560px";
      }
      if (invoiceContainer) {
        invoiceContainer.style.maxHeight = "400px";
      }
    }
  }, [selectedTab]);

  const handleEditClick = (invoiceNumber) => {
    setInvoiceId(invoiceNumber);
    setInvoiceEdit(true);
    setDetailsPage(false);
  };

  const handleNavigateInvoice = (invoiceNumber) => {
    setInvoiceEdit(false);
    setDetailsPage(false);
    setRecurringInvoice(true);
  };

  const handleCancelUpdate = () => {
    setInvoiceEdit(false);
    setDetailsPage(true);
  };

  let i = 1;

  function groupByTax(items) {
    return items.reduce((acc, item) => {
      (acc[item.tax] = acc[item.tax] || []).push(item);
      return acc;
    }, {});
  }

  const groupedItems = groupByTax(itemDetails);

  function groupByTax1(items) {
    return items.reduce((acc, item) => {
      const taxRate = item.tax;
      if (!acc[taxRate]) {
        acc[taxRate] = [];
      }
      acc[taxRate].push(item);
      return acc;
    }, {});
  }

  const groupedItems1 = groupByTax1(itemDetails);

  return (
    <>
      {detailsPage && (
        <>
          <div
            className="d-flex justify-content-between mt-3"
            style={{ padding: "5px 5px 0px 5px" }}
          >
            <div className="d-flex align-items-center ">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIosIcon
                  width={2}
                  onClick={handleCancleClick}
                  className="arrow-child"
                />
              </div>
              <div className="headinvoice ml-2">
                <h5
                  className="ml-1 mb-0 text-primary"
                  style={{ letterSpacing: "1px" }}
                >
                  All Details
                </h5>
              </div>
            </div>
            <div className="add-invoice pr-2 d-flex align-items-baseline"></div>
          </div>

          <div
            className="card border-2 invoice-border mt-3"
            style={{
              borderRadius: "20px",
              height: "auto",
              marginBottom: "0px",
            }}
          >
            <div className="custom-templates-container">
              <div className="left-invoice custom-scrollbar">
                <div>
                  <div
                    className="text-left custom-scrollbar"
                    style={{ cursor: "pointer" }}
                  >
                    {invoices.map((invoice, index) => (
                      <React.Fragment>
                        <div
                          className="recurringinvoice-row"
                          onClick={() => {
                            handleInvoiceClick(invoice.invoiceNumber);
                            getCustomerData(invoice.customerId);
                            setShowDetails(true);
                            setOfflinePay(false);
                            setShowPdf(false);
                          }}
                        >
                          <div className="list-primary">
                            <span className="float-end amount ">
                              ₹ {invoice.total}
                            </span>
                            <a href="#" className="text-primary recurringnum">
                              {invoice.orderNumber}{" "}
                            </a>
                          </div>
                          <div className="list-secondary">
                            <span className="float-end text-muted">
                              {invoice.paymentTerms}
                            </span>
                            <div className="customer">
                              {invoice.customerName}
                            </div>
                          </div>
                          <div className="list-notes">
                            <span className="float-end text-uppercase text-success status">
                              Active
                            </span>
                            <span className="text-muted note">
                              Next Invoice on 2 Jun 2024
                            </span>
                          </div>
                        </div>
                        {index < invoices.length - 0 && (
                          <div className="horizontal-line"></div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>

              <div className="middle-line"></div>

              {/* Right Side Content */}
              {showDetails && (
                <div className="right-recurringinvoice">
                  {/* Top Template Bar */}
                  <div className="top-heading">
                    <ul className="nav nav-tabs-new d-flex">
                      <h6
                        className="flex-grow-1 mb-0 ml-1"
                        style={{ color: "#3C7BD4" }}
                      >
                        {invoiceDetails.orderNumber}
                      </h6>
                      <li className="nav-item mr-1 cursor-pointer">
                        <a
                          className="nav-link"
                          href="#"
                          role="button"
                          style={{
                            borderRadius: "10px",
                            border: "1.4px solid #c1bebe",
                          }}
                          onClick={() =>
                            handleEditClick(invoiceDetails.invoiceNumber)
                          }
                        >
                          Edit
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="navigation-bar mt-1">
                    <ul className="nav nav-tabs-new">
                      <li className="nav-item">
                        <button
                          className={`btn  cursor-pointer top-nav-buttons ${
                            selectedTab === "overview"
                              ? "btn-customer-link text-primary"
                              : "withoutbtn"
                          }`}
                          onClick={() => handleTabClick("overview")}
                        >
                          <span className="ml-2">Overview</span>
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={`btn  cursor-pointer top-nav-buttons ${
                            selectedTab === "nextinvoice"
                              ? "btn-customer-link text-primary"
                              : "withoutbtn"
                          }`}
                          onClick={() => handleTabClick("nextinvoice")}
                        >
                          <span className="ml-2">Next Invoice</span>
                        </button>
                      </li>
                      {/* <li className="nav-item">
                        <button
                          className={`btn  cursor-pointer top-nav-buttons ${
                            selectedTab === "recentactivities"
                              ? "btn-customer-link text-primary"
                              : "withoutbtn"
                          }`}
                          onClick={() => handleTabClick("recentactivities")}
                        >
                          <span className="ml-2">Recent Activities</span>
                        </button>
                      </li> */}
                    </ul>

                    <hr
                      className=""
                      style={{ margin: "0.28rem 0px 0px 0px" }}
                    />

                    <div className="tab-content">
                      <div
                        className={`tab-pane ${
                          selectedTab === "overview" ? "active" : ""
                        }`}
                      >
                        <div className="overview-container">
                          <div className="details-info">
                            <div className="group1">
                              <div className="row">
                                <div className="col-lg-2">
                                  <img
                                    src={usericon}
                                    alt="profileimg"
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </div>
                                <div className="col-lg-10">
                                  <a href="#" className="text-primary ml-3">
                                    {invoiceDetails.customerName}
                                  </a>
                                  <br />
                                  <small className="ml-3">{custEmail}</small>
                                  <br />
                                </div>
                              </div>
                            </div>

                            <div className="details-header border-bottom pt-3 pb-2">
                              <div className="text-uppercase">Details</div>
                            </div>

                            <div className="group2">
                              <div className="row">
                                <label className="col-lg-6 text-muted">
                                  Profile Status:
                                </label>
                                <div className="col-lg-6">
                                  <span className="badge text-semibold badge-secondary ml-0">
                                    Expired
                                  </span>
                                </div>
                              </div>
                              <div className="row">
                                <label className="col-lg-6 text-muted">
                                  Start Date:
                                </label>
                                <div className="col-lg-6">
                                  {invoiceDetails.startOn}
                                </div>
                              </div>
                              <div className="row">
                                <label className="col-lg-6 text-muted">
                                  End Date:
                                </label>
                                <div className="col-lg-6">
                                  {invoiceDetails.endsOn}
                                </div>
                              </div>
                              <div className="row">
                                <label className="col-lg-6 text-muted">
                                  Payment Terms:
                                </label>
                                <div className="col-lg-6">
                                  {invoiceDetails.paymentTerms}
                                </div>
                              </div>
                              <div className="row">
                                <label className="col-lg-6 text-muted">
                                  Salesperson:
                                </label>
                                <div className="col-lg-6">
                                  {invoiceDetails.salesPerson}
                                </div>
                              </div>
                              <div className="row">
                                <label className="col-lg-6 text-muted">
                                  Invoices:
                                </label>
                                <div className="col-lg-6">1</div>
                              </div>
                            </div>
                          </div>

                          <div className="details-info2">
                            <div className="overview-info">
                              <div className="inner-col text-center unusedcredits mt-1 border-right p-3">
                                <p className="outstanding mb-1">
                                  Invoice Amount
                                </p>
                                <p className="outstandingvalue">₹8989898</p>
                              </div>
                              <div
                                className="inner-col text-center unusedcredits mt-1 border-right p-3"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                }}
                              >
                                <p className="outstanding mb-1">
                                  Next Invoice Date
                                </p>
                                <a
                                  href="#"
                                  className="outstandingvalue text-primary"
                                  onClick={() => {
                                    setSelectedTab("nextinvoice");
                                  }}
                                >
                                  30 April 2024
                                </a>
                              </div>

                              {/* <!-- Bottom Right Section --> */}
                              <div
                                className="inner-col text-center unusedcredits mt-1 p-3"
                                style={{ fontSize: "15px", fontWeight: "500" }}
                              >
                                <p className="outstanding mb-1">
                                  Recurring Period
                                </p>
                                <div className="outstandinetngvalue">
                                  {" "}
                                  Due end of the month
                                </div>
                              </div>
                            </div>

                            <div className="inner-col2">
                              <div className="row pl-2 pr-2">
                                <div className="col-lg-5">
                                  <p className="invoices-dropdown d-flex mb-4">
                                    <a
                                      href="#"
                                      className="d-flex align-items-end text-dark"
                                    >
                                      All Child Invoices
                                      <div className="d-inline-flex ml-1">
                                        <ion-icon
                                          name="chevron-down-outline"
                                          style={{ color: "#408dfb" }}
                                        />
                                      </div>
                                    </a>
                                  </p>
                                </div>
                                <p className="col-lg-7 text-end mb-4">
                                  Unpaid Invoices &nbsp;:&nbsp;{" "}
                                  <a href="#">₹191653</a>
                                </p>
                              </div>
                              <table className="recurringinvoices-table table-hover cursor-pointer">
                                <tbody>
                                  {/* <tr
                                    tabIndex="-1"
                                    onClick={handleNavigateInvoice}
                                  >
                                    <td
                                      className=" border-bottom"
                                      style={{
                                        padding: "8.5px 30px 10.5px 20px",
                                      }}
                                    >
                                      <div className="list-primary">
                                        <span className="float-end amount">
                                          ₹51523
                                        </span>
                                        <div className="name">abc pqr xyz</div>
                                      </div>
                                      <div className="list-secondary clearfix">
                                        <span className="float-end text-uppercase font-xs text-primary">
                                          Open
                                        </span>
                                        <div>
                                          <a href="#" className="pr-2">
                                            Invoice1
                                          </a>
                                          <span className="border-left pl-2">
                                            21 April 2024
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr
                                    tabIndex="-1"
                                    onClick={handleNavigateInvoice}
                                  >
                                    <td
                                      className=" border-bottom"
                                      style={{
                                        padding: "8.5px 30px 10.5px 20px",
                                      }}
                                    >
                                      <div className="list-primary">
                                        <span className="float-end amount">
                                          ₹51523
                                        </span>
                                        <div className="name">abc pqr xyz</div>
                                      </div>
                                      <div className="list-secondary clearfix">
                                        <span className="float-end text-uppercase font-xs text-success">
                                          Partially Paid
                                        </span>
                                        <div>
                                          <a href="#" className="pr-2">
                                            Invoice1
                                          </a>
                                          <span className="border-left pl-2">
                                            21 April 2024
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                  </tr> */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`tab-pane ${
                          selectedTab === "nextinvoice" ? "active" : ""
                        }`}
                      >
                        <div className="custom-container">
                          {/* <div className="">                           */}
                          {/*****Invoice*****/}
                          <div className="section1 custom-scrollbar invoice-data m-0">
                            <div className="invoice-container">
                              <div className="invoice-head border-bottom-0">
                                <div className="invoice-head-top">
                                  {userDetails && (
                                    <div className="invoice-head-top-left text-start p-2">
                                      <ul className="text-start mb-0">
                                        <li className="text-bold">
                                          {companyName}
                                        </li>
                                        <li>
                                          {userDetails.address1
                                            ? userDetails.address1
                                            : ""}
                                        </li>
                                        <li>
                                          {userDetails.address2
                                            ? userDetails.address2
                                            : ""}
                                        </li>
                                        <li>
                                          {userDetails.zipcode
                                            ? userDetails.zipcode
                                            : ""}
                                        </li>
                                        <li>
                                          {userDetails.city
                                            ? userDetails.city
                                            : ""}
                                        </li>
                                        <li>
                                          {userDetails.state
                                            ? userDetails.state
                                            : ""}
                                        </li>
                                        <li>
                                          {userDetails.businessLocation
                                            ? userDetails.businessLocation
                                            : ""}
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                  <div className="invoice-head-top-right text-end p-2 d-flex align-items-end justify-content-end">
                                    <h3 className="mb-0">TAX INVOICE</h3>
                                  </div>
                                </div>
                                <div
                                  className="hr"
                                  style={{ border: "1px solid #9e9e9e" }}
                                ></div>
                                <div className="invoice-head-bottom p-0">
                                  <div className="invoice-head-bottom-left right-border p-2">
                                    <ul>
                                      <div className="row">
                                        <div className="col-lg-5">
                                          <li className="mb-2">
                                            <b># </b>
                                          </li>
                                        </div>
                                        <div className="col-lg-5">
                                          <li className="mb-2">
                                            :Will be generated automatically
                                          </li>
                                        </div>
                                        <div className="col-lg-5">
                                          <li className="mb-2">
                                            <b>Invoice Date </b>
                                          </li>
                                        </div>
                                        <div className="col-lg-5">
                                          <li className="mb-2">
                                            : {invoiceDetails.startOn}
                                          </li>
                                        </div>
                                        <div className="col-lg-5">
                                          <li className="mb-2">
                                            <b>Terms</b>
                                          </li>
                                        </div>
                                        <div className="col-lg-5">
                                          <li className="mb-2">
                                            : {invoiceDetails.terms}
                                          </li>
                                        </div>
                                        <div className="col-lg-5">
                                          <li>
                                            <b>Due Date</b>
                                          </li>
                                        </div>
                                        <div className="col-lg-5">
                                          <li>: {invoiceDetails.endsOn}</li>
                                        </div>
                                      </div>
                                    </ul>
                                  </div>
                                </div>
                                <div
                                  className="hr"
                                  style={{ border: "1px solid #9e9e9e" }}
                                ></div>
                                <div
                                  className="invoice-head-middle"
                                  style={{ backgroundColor: "#f2f3f4" }}
                                >
                                  <div className="invoice-head-middle-left text-start pl-2">
                                    <p className="mb-0">
                                      <span className="text-bold">
                                        Bill To:{" "}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="hr"
                                  style={{ border: "1px solid #9e9e9e" }}
                                ></div>
                                <div className="invoice-head-middle">
                                  <div className="invoice-head-middle-left text-start pl-2">
                                    <p className="mb-0">
                                      <span className="text-primary">
                                        {customerName}
                                      </span>
                                      <br />
                                      {state}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="hr"
                                  style={{ border: "1px solid #9e9e9e" }}
                                ></div>
                              </div>
                              <div className="overflow-view">
                                <div className="invoice-body">
                                  <table>
                                    <thead>
                                      <tr>
                                        <td
                                          className="td-left-border text-bold"
                                          style={{ border: "0px" }}
                                        >
                                          ID
                                        </td>
                                        <td className="text-bold">
                                          Items & Description
                                        </td>
                                        <td className="text-bold">QTY</td>
                                        <td className="text-bold">Rate</td>
                                        <td className="text-bold">Discount</td>
                                        <td className="text-bold">Amount</td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {itemDetails.map((item, index) => (
                                        <tr key={index}>
                                          <td style={{ border: "0px" }}>
                                            {i++}{" "}
                                          </td>
                                          <td>{item.itemsDetails}</td>
                                          <td>{item.quantity}</td>
                                          <td>{item.rate}</td>
                                          <td>
                                            {item.discount} {item.discountType}
                                          </td>
                                          <td>{item.amount}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                  <div
                                    style={{ width: "100%", marginTop: "1px" }}
                                  >
                                    <div
                                      style={{
                                        width: "50%",
                                        padding: "4px 4px 3px 7px",
                                        float: "left",
                                      }}
                                    >
                                      {/* <div style={{ margin: "10px 0 5px" }}>
                                  <div style={{ paddingRight: "10px" }}>Total In Words</div>
                                  <span>
                                    <b>
                                      <i>Indian Rupee One Thousand Only</i>
                                    </b>
                                  </span>
                                </div> */}
                                      <div style={{ paddingTop: "15px" }}>
                                        <p
                                          style={{
                                            whiteSpace: "pre-wrap",
                                            wordWrap: "break-word",
                                          }}
                                          className="pcs-notes"
                                        >
                                          <h6>NOTES:</h6>
                                          Thanks for your business.
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      style={{ width: "43.6%", float: "right" }}
                                      className="pcs-totals"
                                    >
                                      <table
                                        className="left-border"
                                        id="itemTable"
                                        cellSpacing="0"
                                        border="0"
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td valign="middle">Sub Total</td>
                                            <td
                                              id="tmp_subtotal"
                                              valign="middle"
                                              style={{ width: "110px" }}
                                            >
                                              ₹{invoiceDetails.subtotal}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td valign="middle">Adjustment</td>
                                            <td
                                              id="tmp_total"
                                              valign="middle"
                                              style={{ width: "110px" }}
                                            >
                                              ₹{invoiceDetails.adjustment}
                                            </td>
                                          </tr>

                                          {
                                            // invoiceDetails.taxCheck === "YES" &&
                                            userDetails.state !==
                                            invoiceDetails.state
                                              ? Object.entries(
                                                  groupedItems1
                                                ).map(([taxRate, items]) => {
                                                  let totalIGST = 0.0;

                                                  // Loop through items to accumulate IGST
                                                  items.forEach((item) => {
                                                    const igstValue =
                                                      parseFloat(item.igst) ||
                                                      0.0;
                                                    if (
                                                      item.customerTaxType === 1
                                                    ) {
                                                      totalIGST +=
                                                        igstValue * 2;
                                                    } else {
                                                      totalIGST += igstValue;
                                                    }
                                                  });

                                                  return (
                                                    <React.Fragment
                                                      key={taxRate}
                                                    >
                                                      <tr>
                                                        <td className="headings">
                                                          IGST ({taxRate}%):
                                                        </td>
                                                        <td className="headings">
                                                          {totalIGST.toFixed(2)}
                                                        </td>
                                                      </tr>
                                                    </React.Fragment>
                                                  );
                                                })
                                              : Object.entries(
                                                  groupedItems
                                                ).map(([taxRate, items]) => {
                                                  let totalCGST = 0.0;
                                                  let totalSGST = 0.0;

                                                  items.forEach((item) => {
                                                    const cgstValue =
                                                      parseFloat(item.cgst) ||
                                                      0.0;
                                                    const sgstValue =
                                                      parseFloat(item.sgst) ||
                                                      0.0;

                                                    totalCGST += cgstValue;
                                                    totalSGST += sgstValue;
                                                  });

                                                  return (
                                                    <React.Fragment
                                                      key={taxRate}
                                                    >
                                                      <tr>
                                                        <td className="headings">
                                                          CGST ({taxRate / 2}%):
                                                        </td>
                                                        <td className="headings">
                                                          {totalCGST.toFixed(2)}
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td className="headings">
                                                          SGST ({taxRate / 2}%):
                                                        </td>
                                                        <td className="headings">
                                                          {totalSGST.toFixed(2)}
                                                        </td>
                                                      </tr>
                                                    </React.Fragment>
                                                  );
                                                })
                                          }

                                          <tr
                                            style={{ height: "10px" }}
                                            className="pcs-balance"
                                          >
                                            <td valign="middle">
                                              <b>Balance Due</b>
                                            </td>
                                            <td
                                              id="tmp_balance_due"
                                              valign="middle"
                                              style={{ width: "110px" }}
                                            >
                                              <strong>
                                                ₹{invoiceDetails.total}
                                              </strong>
                                            </td>
                                          </tr>
                                        </tbody>
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                textAlign: "center",
                                                paddingTop: "5px",
                                              }}
                                              colSpan="2"
                                            >
                                              <div
                                                style={{ minHeight: "75px" }}
                                              ></div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{ textAlign: "center" }}
                                              colSpan="2"
                                            >
                                              <label
                                                style={{ marginBottom: "0px" }}
                                                className="pcs-totals"
                                              >
                                                Authorized Signature
                                              </label>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div style={{ clear: "both" }}></div>
                                    <div style={{ clear: "both" }}></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`tab-pane ${
                          selectedTab === "recentactivities" ? "active" : ""
                        }`}
                      >
                        <div className="recentactivities-container">
                          {/* <div className=""> */}
                          <div className="recents-inner-container">
                            <div className="recents-list">
                              <div className="row">
                                <div className="tree-node col-lg-8">
                                  <div className="time text-muted">
                                    <small>21 April 2024 12:42 PM</small>
                                  </div>
                                  <div className="tree-node-leaf">
                                    <div className="description">
                                      Recurring Invoice 1 Created
                                    </div>
                                    <div className="text-muted font-xs">
                                      by Abc Xyz
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {invoiceEdit && (
        <RecurringInvoiceUpdate
          recurringInvoiceNumber={invoiceNumber}
          handleCancel={handleCancelUpdate}
        />
      )}

      {recurringInvoice && <InvoiceDetails handleCancel={handleCancelUpdate} />}
    </>
  );
}
