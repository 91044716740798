import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col, Spinner } from "react-bootstrap";
import "../../../asset/css/inbox.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import NoDataImage from "../../../asset/images/nodata.jpg";
import { BASE_URL } from "../CommonApi";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import InvoiceUpdate from "../SalesNew/InvoicesUpdate";
import InvoiceDetails from "../SalesNew/InvoiceDetails";
import { FaWhatsapp } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Invoices({ navigation }) {
  const [itemId, setItemID] = useState();
  const [fetchedStatus, setFetchedStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [showCard, setShowCard] = useState(true);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [loadingRows, setLoadingRows] = useState({});

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleInvoicesClick = () => {
    handleNavigation("InvoicesNew");
  };

  const handleEdit = (id, status) => {
    setFetchedStatus(status);
    setItemID(id);
    setShowCard(false);
    setShowUpdate(true);
  };

  const [invoices, setInvoices] = useState([]);
  const [newinvoiceNumber, setnewInvoice] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [itemDetails, setItemDetails] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [apiCallInitiated, setApiCallInitiated] = useState(false); // Flag for API call
  const [bankData, setBankData] = useState([]);
  const [bankColumn, setBankColumn] = useState("No");

  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const handleDelete = (id) => {
    const formattedId = id.replace(/\//g, "!");
    const userConfirmed = window.confirm(
      "Are you sure ? Once deleted, you will not be able to recover this invoice!"
    );
    if (userConfirmed) {
      fetch(BASE_URL + `/deleteByInvoiceNumber/${formattedId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ uniqueId: decryptedUniqueId }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setInvoices((prevInvoices) =>
              prevInvoices.filter((invoice) => invoice.invoiceNumber !== id)
            );
            swal({
              icon: "success",
              title: "Invoice Deleted Successfully",
            });
            fetchInvoices();
            setLoading(true);
          } else if (
            data.responseMessage ===
            "Payments have been recorded for these invoices; hence they cannot be deleted"
          ) {
            window.confirm(
              "Payments have been recorded for these invoices; hence they cannot be deleted!"
            );
          } else {
            console.error("Failed to delete invoice");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error deleting invoice:", error);
          setLoading(false);
        });
    } else {
      alert("Invoice deletion canceled!");
    }
  };

  const handleCancelUpdate = () => {
    fetchInvoices();
    setShowUpdate(false);
    setShowCard(true);
  };

  const handleCancelDetails = () => {
    setShowDetails(false);
    setShowCard(true);
    setShowUpdate(false);
    fetchInvoices();
  };

  const fetchInvoices = () => {
    $("#DataTables_Table_0").DataTable();
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    fetch(BASE_URL + `/fetchInvoice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setInvoices(data.responseMessage);
          setLoading(false);
        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
          setLoading(false);
        }
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      });
  };

  useEffect(() => {
    fetchInvoices();
  }, []);
  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const userId = localStorage.getItem("userId");
    fetch(BASE_URL + `/fetchUserProfile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const userDetails = data.responseMessage;
          setUserDetails(userDetails);
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  }, []);

  const handleSentMsg = async (invoiceNumber, customerId) => {
    await fetchInvoiceByNumber(invoiceNumber);
    await fetchCustomer(customerId);
  };

  const fetchInvoiceByNumber = async (invoiceNumber) => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const formattedId = invoiceNumber.replace(/\//g, "!");
    try {
      const response = await fetch(
        BASE_URL + `/fetchInvoiceNumber/${formattedId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({ uniqueId: decryptedUniqueId }),
        }
      );
      const data = await response.json();
      if (data.status === "success") {
        setItemDetails(data.responseMessage);
        setInvoiceDetails(data.responseMessage[0]);
      } else {
        console.error("Failed to fetch invoices by id:", data.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  };

  const fetchCustomer = async (customerId) => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    try {
      const response = await fetch(
        BASE_URL + `/fetchCustomerById/${customerId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({ uniqueId: decryptedUniqueId }),
        }
      );
      const data = await response.json();
      if (data.status === "success") {
        setCustomerData(data.responseMessage);
      } else {
        console.error("Failed to fetch customer by id:", data.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching customer:", error);
    }
  };

  useEffect(() => {
    if (invoiceDetails && customerData && !apiCallInitiated) {
      setApiCallInitiated(true); // Set the flag to true before making the API call
      handleMailSubmit();
    }
  }, [invoiceDetails, customerData]);

  useEffect(() => {
    const apiUrl = BASE_URL + "/fetchBankForPdf";
    const makePostRequest = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({ uniqueId: decryptedUniqueId }),
        });

        if (response.ok) {
          const responseData = await response.json();
          const filteredData = responseData.responseMessage;
          setBankData(filteredData);
          setBankColumn("Yes");
        } else {
          console.error("POST request failed");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    makePostRequest();
  }, []);
  const notifySuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });
  };
  const handleMailSubmit = async () => {
    setLoadingRows((prev) => ({
      ...prev,
      [invoiceDetails.invoiceNumber]: true,
    }));

    const itemDetails1 = itemDetails.map((item) => ({
      items: item.itemsDetails || "",
      quantity: item.quantity || "",
      rate: item.rate || "",
      discount: item.discount || "",
      amount: item.amount || "",
      discountType: item.discountType || "",
      size: item.size || "",
      tax: item.tax || "",
    }));

    const postData = {
      total: invoiceDetails.total,
      invoiceNumber: invoiceDetails.invoiceNumber,
      invoiceDate: invoiceDetails.invoiceDate,
      dueDate: invoiceDetails.dueDate,
      organizationName: userDetails.companyName,
      custmorName: customerData.customerName,
      companyName: userDetails.companyName,
      terms: invoiceDetails.paymentTerms,
      items: itemDetails1,
      subTotal: invoiceDetails.subtotal,
      adjustment: invoiceDetails.adjustment,
      totalDue: invoiceDetails.receivables,
      notes: invoiceDetails.customerNote,
      state: customerData.billState,
      address: userDetails.address1,
      imageName: userDetails.logo,
      uniqueId: decryptedUniqueId,
      name: bankData.name,
      branch: bankData.bankName,
      accountNo: bankData.accountNumber,
      bankColumn: bankColumn,
      ifscCode: bankData.ifsc,
      sizeColumn: invoiceDetails.sizeCheck,
      discountColumn: invoiceDetails.discountCheck,
      taxColumn: invoiceDetails.taxCheck,
      igst: invoiceDetails.igst,
      cgst: invoiceDetails.cgst,
      sgst: invoiceDetails.sgst,
      orderNo: invoiceDetails.referenceNumber,
      orgMob1: userDetails.contact,
      organizationStreetName: userDetails.address1,
      organizationAddress: userDetails.address2,
      organizationPinCode: userDetails.zipcode,
      custmorAddress: customerData.billAddress1,
      custmorCity: customerData.billCity,
      custmorMob: customerData.mobile,
      termsAndCondition: invoiceDetails.termsAndCondition,
    };
    try {
      const response = await fetch(BASE_URL + "/generate-pdf", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(postData),
      });
      const data = await response.json();
      if (data.status === "success") {
        notifySuccess("Message Sent Successfully");
        setLoadingRows((prev) => ({
          ...prev,
          [invoiceDetails.invoiceNumber]: false,
        }));
        setApiCallInitiated(false);
      } else if (data.status === "error") {
        notifyError(data.responseMessage);
        setLoadingRows((prev) => ({
          ...prev,
          [invoiceDetails.invoiceNumber]: false,
        }));
        setApiCallInitiated(false);
      }
    } catch (error) {
      notifyError(error.message);
      setLoadingRows((prev) => ({
        ...prev,
        [invoiceDetails.invoiceNumber]: false,
      }));
      setApiCallInitiated(false);
    }
  };

  const handleInvoice = (invoiceNumber, customerId) => {
    setnewInvoice(invoiceNumber);
    setCustomerId(customerId);
    setShowDetails(true);
    setShowCard(false);
    setShowUpdate(false);
  };

  let i = 1;

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      {!showUpdate && !showDetails && (
        <div className="item-page">
          <div className="d-flex justify-content-between">
            <div
              className="mt-2"
              style={{
                color: "#3C7BD4",
                fontSize: 20,
                fontWeight: "600",
                wordWrap: "break-word",
                textAlign: "left",
              }}
            >
              Invoices
            </div>
            <div className="d-flex">
              <div className="">
                <button
                  className="btn btn-primary px-1 py-2 new-purchase"
                  onClick={handleInvoicesClick}
                >
                  + New Invoices
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showCard && (
        <div className="container-fluid">
          <div className="page-content ">
            <Container fluid={true}>
              <Row>
                <Col>
                  <Card style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                    {invoices && invoices.length > 0 ? (
                      <>
                        <div className="body">
                          <div className="table-responsive1">
                            <div
                              id="DataTables_Table_0_wrapper"
                              className="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                  <table
                                    className="table table-hover js-basic-example dataTable table-custom no-footer"
                                    id="DataTables_Table_0"
                                    role="grid"
                                    aria-describedby="DataTables_Table_0_info"
                                  >
                                    <thead className="heading-text">
                                      <tr role="row">
                                        <th
                                          className="sorting_asc"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-sort="ascending"
                                          aria-label="Date: activate to sort column descending"
                                        >
                                          Date
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Type: activate to sort column ascending"
                                        >
                                          Invoices#
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Amount BTC: activate to sort column ascending"
                                        >
                                          Order number
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="BTC Remaining: activate to sort column ascending"
                                        >
                                          Customer name
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        >
                                          Status
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        >
                                          Due date
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        >
                                          Total
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        ></th>
                                      </tr>
                                    </thead>
                                    <tbody className="text1">
                                      {invoices.map((invoice) => (
                                        <tr
                                          role="row"
                                          className="odd"
                                          key={invoice.id}
                                        >
                                          <td>{invoice.invoiceDate}</td>
                                          <td className="sorting_1">
                                            {" "}
                                            <a
                                              href="#"
                                              onClick={() =>
                                                handleInvoice(
                                                  invoice.invoiceNumber,
                                                  invoice.customerId
                                                )
                                              }
                                            >
                                              {invoice.invoiceNumber}
                                            </a>
                                          </td>
                                          <td>{invoice.orderNumber}</td>
                                          <td>{invoice.customerName}</td>
                                          <td
                                            style={{
                                              color: invoice.status.startsWith(
                                                "O"
                                              )
                                                ? "red"
                                                : invoice.status.startsWith("D")
                                                ? "#408dfb"
                                                : "green",
                                            }}
                                          >
                                            {invoice.status}
                                          </td>
                                          <td>{invoice.dueDate}</td>
                                          <td>₹{invoice.total}</td>
                                          <td>
                                            <button
                                              className="btn icon-border btn-sm mr-2"
                                              onClick={() =>
                                                handleEdit(
                                                  invoice.invoiceNumber,
                                                  invoice.status
                                                )
                                              }
                                            >
                                              <i className="fa fa-edit text1"></i>
                                            </button>
                                            <button
                                              className="btn icon-border btn-sm"
                                              onClick={() =>
                                                handleDelete(
                                                  invoice.invoiceNumber
                                                )
                                              }
                                            >
                                              <i className="fa fa-trash text1"></i>
                                            </button>
                                            <button
                                              className="btn icon-border btn-sm"
                                              onClick={() =>
                                                handleSentMsg(
                                                  invoice.invoiceNumber,
                                                  invoice.customerId
                                                )
                                              }
                                              disabled={
                                                loadingRows[
                                                  invoice.invoiceNumber
                                                ]
                                              }
                                            >
                                              {loadingRows[
                                                invoice.invoiceNumber
                                              ] ? (
                                                <Spinner
                                                  as="span"
                                                  animation="border"
                                                  size="sm"
                                                  role="status"
                                                  aria-hidden="true"
                                                />
                                              ) : (
                                                <FaWhatsapp
                                                  className="fa fa-edit text1"
                                                  style={{ color: "green" }}
                                                />
                                              )}
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="body">
                        <div className="table-responsive1">
                          <div
                            id="DataTables_Table_0_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                  id="DataTables_Table_0"
                                  role="grid"
                                  aria-describedby="DataTables_Table_0_info"
                                >
                                  <thead className="heading-text">
                                    <tr role="row">
                                      <th
                                        className="sorting_asc"
                                        tabIndex="0"
                                        aria-con
                                        trols="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Date: activate to sort column descending"
                                      >
                                        Date
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Type: activate to sort column ascending"
                                      >
                                        Invoices#
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Amount BTC: activate to sort column ascending"
                                      >
                                        Oredr number
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="BTC Remaining: activate to sort column ascending"
                                      >
                                        Customer name
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Due date
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Balance due
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Status
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      ></th>
                                    </tr>
                                  </thead>
                                  <tbody></tbody>
                                </table>
                                <h6 className="text-center">
                                  <img
                                    src={NoDataImage}
                                    style={{ width: "50%", height: "50%" }}
                                    alt="No Data Logo"
                                  />{" "}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>
            </Container>
            <div>
              <ToastContainer hideProgressBar={true}></ToastContainer>
            </div>
          </div>
        </div>
      )}

      {showUpdate && (
        <InvoiceUpdate
          fetchedStatus={fetchedStatus}
          invoiceNumber1={itemId}
          handleCancel={handleCancelUpdate}
        />
      )}

      {showDetails && (
        <InvoiceDetails
          newinvoiceNumber={newinvoiceNumber}
          custId={customerId}
          handleCancel={handleCancelDetails}
        />
      )}
    </div>
  );
}

export default Invoices;
