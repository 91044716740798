import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
class ManageProjectDataCard extends React.Component {
  render() {
    return (
      <div className="col-lg-12">
        <Container fluid={true}>
          <Row>
            <Col>
              <Card>
                <div className="table-responsive">
                  <table class="table table-bordered table-striped mb-0">
                    <thead style={{ fontSize: "12px" }}>
                      <tr>
                        <th scope="col">SR NO</th>
                        <th scope="col">PROJECT NAME</th>
                        <th scope="col">DATA TYPE</th>
                        <th scope="col">LINK</th>
                        <th scope="col">LOGIN ID</th>
                        <th scope="col">PASSWORD</th>
                        <th scope="col">ACTION UNIT</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>yash</td>
                        <td>XYZ</td>
                        <td>
                          <a href="www.example.com">www.example.com</a>
                        </td>
                        <td>yash dudhankar</td>
                        <td>12345</td>
                        <td>
                          {" "}
                          <button
                            className="btn btn-primary btn-sm mr-2"
                            onClick={() => this.handleEdit(2)}
                          >
                            <i className="fa fa-edit"></i>
                          </button>
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => this.handleDelete(2)}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default ManageProjectDataCard;
