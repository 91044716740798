import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { BASE_URL } from "../../screens/Dashboard/CommonApi";
import { AES, enc } from "crypto-js";
import * as htmlToImage from "html-to-image";

const AnnualReportCardForVendor = (props) => {
  const [salesData, setSalesData] = useState([]);
  useEffect(() => {
    fetchDataForIncome();
  }, [props.customerId]);

  const fetchDataForIncome = async () => {
    try {
      const encryptedUniqueId = localStorage.getItem("uniqueId1");
      const decryptedUniqueId = AES.decrypt(
        encryptedUniqueId,
        "1234567812345678"
      ).toString(enc.Utf8);
      const jwtToken = localStorage.getItem("jwtToken");
      const response = await fetch(BASE_URL + "/fetchForGraphByVendorId", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        },
        body: JSON.stringify({ uniqueId: decryptedUniqueId, vendorId: props.customerId })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const salesDataForGraph = Object.values(data).map(month => parseFloat(month.totalSales));
      setSalesData(salesDataForGraph);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDownload = (type) => {
    if (type === "png") {
      downloadAsPNG();
    } else if (type === "csv") {
      downloadAsCSV();
    }
  };

  const downloadAsPNG = () => {
    const chartElement = document.getElementById("chart-container");
    htmlToImage.toPng(chartElement)
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "chart.png";
        link.href = dataUrl;
        link.click();
      });
  };

  const downloadAsCSV = () => {
    const xAxisData = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nav", "Dec"
    ];

    const combinedData = salesData.map((value, index) => ({
      month: xAxisData[index], // Assuming xAxisData contains month names
      customer: value,
    }));

    const csvContent = [
      "Month,Vendor,",
      ...combinedData.map(item => `${item.month},${item.customer}`)
    ].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "report.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const maxSales = Math.max(
    Math.max(...salesData),
  );

  return (
    <div className="col-lg-12 col-md-6 col-sm-6">
      <div className="card border-0">
        <div className="d-flex justify-content-between">
          <div className="d-flex p-2">
            <label htmlFor="exportDropdown" className="`">Income</label>
          </div>
          <div className="d-flex">
            <div className="dropdown">
              <button
                className="btn btn-outline-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Export
              </button>
              <ul className="dropdown-menu">
                <li
                  className="dropdown-item"
                  onClick={() => handleDownload("csv")}
                >
                  Export as CSV
                </li>
                <li
                  className="dropdown-item"
                  onClick={() => handleDownload("png")}
                >
                  Export as Image
                  </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="chart-container" className="body">
          <ReactEcharts
            option={{
              tooltip: {
                show: true,
                trigger: "axis",
                axisPointer: {
                  show: true,
                  type: "shadow",
                },
                data: ["data1", "data2"],
              },
              color: ["rgb(99, 122, 174)", "rgb(89, 196, 188)"],
              legend: {
                show: false,
                top: 280,
                data: ["Income", "Value"],
              },
              grid: {
                top: "20%",
                left: "0%",
                right: "2%",
                bottom: "2%",
                containLabel: true,
              },
              xAxis: {
                type: "category",
                // axisLine: {
                //   show: false,
                // },
                data: [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "June",
                  "July",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ],
              },
              yAxis: [
                {
                  show: true,
                  type: "value",
                  axisTick: { show: true },
                  data: maxSales // Set the maximum limit for the y-axis
                },
              ],
              series: [
                {
                  name: "INR",
                  type: "bar",
                  stack: "总量",
                  barWidth: 18,
                  label: {
                    show: false,
                    position: "insideRight",
                  },
                  // data: [0, 2, 2, 5, 4, 8, 8, 3, 4, 7, 7, 9],
                  data: salesData
                },
                // {
                //   name: "Data2",
                //   type: "bar",
                //   stack: "总量",
                //   label: {
                //     show: false,
                //     position: "insideRight",
                //   },
                //   data: [1, 2, 4, 5, 1, 4, 4, 3, 5, 7, 6, 5],
                // },
              ],
            }}
            opts={{ renderer: "svg" }}
          />
        </div>
      </div>
    </div>
  );
};

export default AnnualReportCardForVendor;
