import React from "react";
import Header from "../../../Components/Common/Header";
import ManagePortfolioCard from "../../../Components/Apps/ManagePortfolioCard";
import ManagePortfolioNew from "./ManagePortfolioNew";
class ManagePortfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCompose: false,
      show: false,
    };
  }

  handleNewButtonClick = () => {
    this.setState({ show: true });
  };

  handleCancelNew = () => {
    this.setState({ show: false });
  };

  render() {
    return (
      <div className="container-fluid">
        {!this.state.show && (
          <Header
            headerText=""
            mainNavigate="Office"
            currentPage="ManagePortfolio"
            childNav={this.state.isCompose ? "Compose" : ""}
          />
        )}
        <div className="row clearfix ">
          {!this.state.show && (
            <div className="container mx-2 mb-2">
              <div className="d-flex justify-content-between">
                <div>
                  <h3>All Portfolio</h3>
                </div>

                <div className="d-flex">
                  <div className="">
                    {!this.state.show && (
                      <button
                        className="btn btn-primary px-2 py-2"
                        onClick={this.handleNewButtonClick}
                      >
                        + NEW
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.show ? (
            <ManagePortfolioNew handleCancle={this.handleCancelNew} />
          ) : (
            <ManagePortfolioCard />
          )}
        </div>
      </div>
    );
  }
}
export default ManagePortfolio;
