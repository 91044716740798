import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./CustomerDetails.css";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FaChevronDown, FaTimes } from "react-icons/fa";
import Select from "react-select";
import AnnualReportCard from "../../../Components/Dashboard/AnnualReportCard";
import AnnualReportCardForVendor from "../../../Components/Dashboard/AnnualReportCardForVendor.js";
import { BASE_URL } from "../../Dashboard/CommonApi.js";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { AES, enc } from "crypto-js";
import commenticon from "./commenticon.svg";
import Badge from "react-bootstrap/Badge";
import VendorsUpdate from "../Purchases/VendorsUpdate.js";
import PurchasesOrderDetails from "../Purchases/PurchasesOrderDetails.js";
import BillsDetails from "../Purchases/BillsDetails.js";
import { Spinner } from "react-bootstrap"; // Import the Bootstrap Spinner component
import swal from "sweetalert";

function VendorDetails(props) {
  const { newVendorId } = props;
  const { fetchedPaymentTerms } = props;
  const [custIdTabel, setCustIdTabel] = useState(newVendorId);
  const [fetchedTerms, setFetchedTerms] = useState(fetchedPaymentTerms);
  const [fetchedVendorId, setFetchedVendorId] = useState(newVendorId);
  const [vendorCommentId, setVendorCommentId] = useState(newVendorId);

  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [selectedTab, setSelectedTab] = useState("edit"); // Initial tab
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeTab, setActiveTab] = useState(null);
  const [venID, setId] = useState("");
  const [contactPersonName, setContactPersonName] = useState([]);
  const [billByVendor, setBillByVendorId] = useState([]);
  const [purchaseOrderByVendor, setPurchaseOrderByVendorId] = useState([]);
  const [fetchedTotalReceivables, setFetchedTotalReceivables] = useState("");
  const [fetchedUnusedCredits, setFetchedUnusedCredits] = useState("");
  const jwtToken = localStorage.getItem("jwtToken");

  const [showDetails, setShowDetails] = useState(true);
  const [vendorEditPage, setVendorEditPage] = useState(false);
  const [showPurchaseDetails, setShowPurchaseDetails] = useState(false);
  const [showBillsDetails, setShowBillsDetails] = useState(false);
  const [vendorId, setVendorId] = useState("");
  const [purchaseNumber, setPurchaseNumber] = useState("");
  const [billsNumber, setBillsNumber] = useState("");
  const handleOpenEdit = (id) => {
    setVendorId(id);
    setVendorEditPage(true);
    setShowDetails(false);
  };

  const handleCancelPage = () => {
    setVendorEditPage(false);
    setShowDetails(true);
    setShowPurchaseDetails(false);
    setShowBillsDetails(false);
  };

  const handlePurchaseOpen = (purchaseNumber) => {
    setPurchaseNumber(purchaseNumber);
    setShowPurchaseDetails(true);
    setShowDetails(false);
  };

  const handleBillsOpen = (billsNumber) => {
    setBillsNumber(billsNumber);
    setShowBillsDetails(true);
    setShowDetails(false);
  };
  const handleDelete = (id) => {
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this vendor!"
    );

    if (userConfirmed) {
      fetch(BASE_URL + `/deleteVendor`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ id }),
      })
        .then((response) => {
          if (response.status === 200) {
            swal({
              icon: "success",
              title: "Vendor Deleted Successfully",
            });
            props.handleCancel();
          } else {
            console.error("Failed to delete Vendor");
          }
        })
        .catch((error) => {
          console.error("Error deleting Vendor:", error);
        });
    } else {
      // If the user clicks "Cancel", show a cancellation message
      alert("Vendor deletion canceled!");
    }
  };
  const options = [
    { value: "today", label: "Today" },
    { value: "this_week", label: "This week" },
    { value: "this_month", label: "This month" },
    { value: "this_year", label: "This year" },
    { value: "this_quarter", label: "This Quarter" },
    { value: "yesterday", label: "Yesterday" },
    { value: "previous_week", label: "Previous Week" },
    { value: "previous_month", label: "Previous Month" },
    { value: "previous_quarter", label: "Previous Quarter" },
    { value: "previous_year", label: "Previous Year" },
  ];
  const formatDate = (date) => {
    return date.toLocaleDateString("en-GB"); // DD/MM/YYYY format
  };
  const formatDate1 = (date) => {
    return date.toLocaleDateString("en-GB").replace(/\//g, "-"); // DD-MM-YYYY format
  };

  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    const today = new Date();

    switch (selectedOption.value) {
      case "today":
        setFromDate(today);
        setToDate(today);
        break;
      case "this_week":
        const sunday = new Date(today);
        sunday.setDate(today.getDate() - today.getDay());
        const saturday = new Date(today);
        saturday.setDate(today.getDate() + (6 - today.getDay()));
        setFromDate(sunday);
        setToDate(saturday);
        break;
      case "this_month":
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );
        const lastDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        setFromDate(firstDayOfMonth);
        setToDate(lastDayOfMonth);
        break;
      case "this_year":
        const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
        const lastDayOfYear = new Date(today.getFullYear(), 11, 31);
        setFromDate(firstDayOfYear);
        setToDate(lastDayOfYear);
        break;
      case "this_quarter":
        const quarterStartMonth = Math.floor(today.getMonth() / 3) * 3;
        const quarterStartDate = new Date(
          today.getFullYear(),
          quarterStartMonth,
          1
        );
        const quarterEndDate = new Date(
          today.getFullYear(),
          quarterStartMonth + 3,
          0
        );
        setFromDate(quarterStartDate);
        setToDate(quarterEndDate);
        break;
      case "yesterday":
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        setFromDate(yesterday);
        setToDate(yesterday);
        break;
      case "previous_week":
        const prevSunday = new Date(today);
        prevSunday.setDate(today.getDate() - today.getDay() - 7);
        const prevSaturday = new Date(today);
        prevSaturday.setDate(today.getDate() - today.getDay() - 1);
        setFromDate(prevSunday);
        setToDate(prevSaturday);
        break;
      case "previous_month":
        const prevMonthStart = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const prevMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
        setFromDate(prevMonthStart);
        setToDate(prevMonthEnd);
        break;
      case "previous_quarter":
        const prevQuarterStartMonth =
          Math.floor((today.getMonth() - 3) / 3) * 3;
        const prevQuarterStartDate = new Date(
          today.getFullYear(),
          prevQuarterStartMonth,
          1
        );
        const prevQuarterEndDate = new Date(
          today.getFullYear(),
          prevQuarterStartMonth + 3,
          0
        );
        setFromDate(prevQuarterStartDate);
        setToDate(prevQuarterEndDate);
        break;
      case "previous_year":
        const prevYearStart = new Date(today.getFullYear() - 1, 0, 1);
        const prevYearEnd = new Date(today.getFullYear() - 1, 11, 31);
        setFromDate(prevYearStart);
        setToDate(prevYearEnd);
        break;
      default:
        setFromDate(null);
        setToDate(null);
    }
  };
  useEffect(() => {
    if (fromDate !== null && toDate !== null) {
      fetchStatement1(venID, fromDate, toDate);
    }
  }, [fromDate, toDate]); // This effect runs when either fromDate or toDate changes

  const [formData, setFormData] = useState({
    uniqueId: decryptedUniqueId,
    primaryContact: "",
    companyName: "",
    vendorDisplayName: "",
    vendorEmail: "",
    vendorPhone1: "",
    vendorPhone2: "",
    designation: "",
    department: "",
    website: "",
    gstTreatment: "",
    placeOfSupply: "",
    currency: "",
    openingBalance: "",
    paymentTerms: "",
    facebook: "",
    id: "",
    twitter: "",
    billingAttention: "",
    billingCountry: "",
    billingAddress1: "",
    billingAddress2: "",
    billingCity: "",
    billingState: "",
    billingZipcode: "",
    billingPhone: "",
    billingFax: "",
    shipAttention: "",
    shipCountry: "",
    shipAddress1: "",
    shipAddress2: "",
    shipCity: "",
    shipState: "",
    shipZipcode: "",
    shipPhone: "",
    contactPerson: [
      {
        other: "",
        firstName: "",
        lastName: "",
        email: "",
        workphone: "",
        mobile: "",
      },
    ],
  });

  const handleInnerTab = (tab) => {
    setActiveTab((prevTab) => (prevTab === tab ? null : tab));
  };
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleAccordionClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    setSelectedTab("overview");
  }, []);

  const [postData, setPostData] = useState({
    uniqueId: decryptedUniqueId,
    vendorId: custIdTabel,
  });

  const [vendorNameList, setVendorData] = useState([]);

  useEffect(() => {
    const apiUrl = BASE_URL + "/fetchVendors";

    const makePostRequest = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify(postData),
        });

        if (response.ok) {
          const responseData = await response.json();

          setVendorData(responseData.responseMessage);
        } else {
          console.error("POST request failed");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    makePostRequest();
  }, []);
  const [dataPost, setDataPost] = useState({
    uniqueId: decryptedUniqueId,
    vendorId: newVendorId.toString(),
    fromDate: formatDate1(fromDate),
    toDate: formatDate1(toDate),
  });
  const [statementList, setStatementList] = useState([]);
  const [openingBalance, setOpeningBalance] = useState("");
  const [invoiceAmounted, setInvoiceAmounted] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [lastBalance, setLastBalance] = useState("");
  useEffect(() => {
    const apiUrl = BASE_URL + "/fetchVendorStatement";

    const makePostRequest = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify(dataPost),
        });

        if (response.ok) {
          const responseData = await response.json();
          const statementEntities = responseData.statementEntities;
          // Extract the last balance data
          const lastBalance =
            statementEntities.length > 0
              ? statementEntities[statementEntities.length - 1].balance
              : 0;

          setAmountPaid(responseData.amountPaid);
          setInvoiceAmounted(responseData.billedAmount);
          setOpeningBalance(responseData.openingBalance);
          setStatementList(statementEntities);
          setLastBalance(lastBalance); // Set the last balance separately
        } else {
          console.error("POST request failed");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    makePostRequest();
  }, []);
  const [companyName, setCompanyName] = useState([]);

  const [userDetails, setUserDetails] = useState(null);
  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const userId = localStorage.getItem("userId");

    fetch(BASE_URL + `/fetchUserProfile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const userDetails = data.responseMessage;

          setUserDetails(userDetails);
          const companyName = data.responseMessage.companyName;
          setCompanyName(companyName);
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  }, []);

  const fetchStatement = (id) => {
    // Get the encrypted uniqueId from session storage
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    setStatementList([]);
    setLastBalance("0.0");
    setInvoiceAmounted("0.0");
    setOpeningBalance("0.0");
    setAmountPaid("0.0");
    const postUrl = BASE_URL + `/fetchVendorStatement`;

    fetch(postUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        vendorId: id,
        fromDate: formatDate1(fromDate),
        toDate: formatDate1(toDate),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const statementEntities = data.statementEntities;
        // Extract the last balance data
        const lastBalance =
          statementEntities.length > 0
            ? statementEntities[statementEntities.length - 1].balance
            : 0;

        setAmountPaid(data.amountReceived);
        setInvoiceAmounted(data.invoiceAmount);
        setOpeningBalance(data.openingBalance);
        setStatementList(statementEntities);
        setLastBalance(lastBalance); // Set the last balance separately
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };
  const fetchStatement1 = (venID) => {
    // Get the encrypted uniqueId from session storage
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    setStatementList([]);
    setLastBalance("0.0");
    setInvoiceAmounted("0.0");
    setOpeningBalance("0.0");
    setAmountPaid("0.0");
    const postUrl = BASE_URL + `/fetchVendorStatement`;

    fetch(postUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        vendorId: venID,
        fromDate: formatDate1(fromDate),
        toDate: formatDate1(toDate),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const statementEntities = data.statementEntities;
        // Extract the last balance data
        const lastBalance =
          statementEntities.length > 0
            ? statementEntities[statementEntities.length - 1].balance
            : 0;

        setAmountPaid(data.amountReceived);
        setInvoiceAmounted(data.invoiceAmount);
        setOpeningBalance(data.openingBalance);
        setStatementList(statementEntities);
        setLastBalance(lastBalance); // Set the last balance separately
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };
  const fetchData = () => {
    const postUrl = BASE_URL + `/fetchVendorById/${newVendorId}`;

    fetch(postUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        const portfolioItem = data.responseMessage;
        setId(portfolioItem.id);
        setFormData({
          id: portfolioItem.id,
          primaryContact: portfolioItem.primaryContact,
          companyName: portfolioItem.companyName,
          vendorDisplayName: portfolioItem.vendorDisplayName,
          vendorEmail: portfolioItem.vendorEmail,
          vendorPhone1: portfolioItem.vendorPhone1,
          vendorPhone2: portfolioItem.vendorPhone2,
          designation: portfolioItem.designation,
          department: portfolioItem.department,
          website: portfolioItem.website,
          gstTreatment: portfolioItem.placeOfSupply,
          placeOfSupply: portfolioItem.placeOfSupply,
          currency: portfolioItem.currency,
          openingBalance: portfolioItem.openingBalance,
          paymentTerms: portfolioItem.paymentTerms,
          facebook: portfolioItem.facebook,
          twitter: portfolioItem.twitter,
          billingAttention: portfolioItem.billingAttention,
          billingCountry: portfolioItem.billingCountry,
          billingAddress1: portfolioItem.billingAddress1,
          billingAddress2: portfolioItem.billingAddress2,
          billingCity: portfolioItem.billingCity,
          billingState: portfolioItem.billingState,
          billingZipcode: portfolioItem.billingZipcode,
          billingPhone: portfolioItem.billingPhone,
          billingFax: portfolioItem.billingFax,
          shipAttention: portfolioItem.shipAttention,
          shipCountry: portfolioItem.shipCountry,
          shipAddress1: portfolioItem.shipAddress1,
          shipAddress2: portfolioItem.shipAddress2,
          shipCity: portfolioItem.shipCity,
          shipState: portfolioItem.shipState,
          shipPhone: portfolioItem.shipPhone,
          shipZipcode: portfolioItem.shipZipcode,
          shipFax: portfolioItem.shipFax,
          uniqueId: portfolioItem.uniqueId,
        });
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  const fetchContactPerson = () => {
    const postUrl = BASE_URL + `/getVendorAllContactPerson/${newVendorId}`;

    fetch(postUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        const Data = data.responseMessage;
        Data.map((contact) => ({
          other: contact.other,
          firstName: contact.firstName,
          lastName: contact.lastName,
          email: contact.email,
          workphone: contact.workphone,
          mobile: contact.mobile,
        }));
        const extractedData = Data.map((item) => ({
          other: item.other,
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email,
          workphone: item.workphone,
          mobile: item.mobile,
        }));
        setFormData((prevData) => ({
          ...prevData,
          contactPerson: extractedData,
        }));
        const namesArray = extractedData.map(
          (item) => `${item.firstName} ${item.lastName} ,`
        );
        setContactPersonName(namesArray);
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  const fetchBillByVendorId = () => {
    const postUrl = BASE_URL + `/fetchBillsByVendorId/${newVendorId}`;

    fetch(postUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        const BillData = data.responseMessage;
        setBillByVendorId(BillData);
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  const fetchPurchaseOrderByVendorId = () => {
    const postUrl = BASE_URL + `/fetchPurchaseOrderByVendorId/${newVendorId}`;

    fetch(postUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        const PurchaseOrderData = data.responseMessage;
        setPurchaseOrderByVendorId(PurchaseOrderData);
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  const fetchReceivablesByCustomerId = () => {
    const postUrl = BASE_URL + `/fetchReceivablesByVendorId`;

    fetch(postUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        const totalReceivables = data.totalReceivables;
        const unusedCredits = data.unusedCredits;

        setFetchedTotalReceivables(totalReceivables);
        setFetchedUnusedCredits(unusedCredits);
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  useEffect(() => {
    fetchData();
    fetchContactPerson();
    fetchBillByVendorId();
    fetchPurchaseOrderByVendorId();
    fetchComments();
  }, [newVendorId]);

  useEffect(() => {
    fetchReceivablesByCustomerId();
  }, [custIdTabel]);

  const fetchClickedVendor = (id) => {
    fetchClickedContactPerson(id);
    fetchClickedBillByVendorId(id);
    fetchStatement(id);
    fetchClickedPurchaseByVendorId(id);
    fetchCommentsOnclick(id);
    const postUrl = BASE_URL + `/fetchVendorById/${id}`;
    setSelectedTab("overview");
    fetch(postUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        const portfolioItem = data.responseMessage;

        setFormData({
          id: portfolioItem.id,
          primaryContact: portfolioItem.primaryContact,
          companyName: portfolioItem.companyName,
          vendorDisplayName: portfolioItem.vendorDisplayName,
          vendorEmail: portfolioItem.vendorEmail,
          vendorPhone1: portfolioItem.vendorPhone1,
          vendorPhone2: portfolioItem.vendorPhone2,
          designation: portfolioItem.designation,
          department: portfolioItem.department,
          website: portfolioItem.website,
          gstTreatment: portfolioItem.placeOfSupply,
          placeOfSupply: portfolioItem.placeOfSupply,
          currency: portfolioItem.currency,
          openingBalance: portfolioItem.openingBalance,
          paymentTerms: portfolioItem.paymentTerms,
          facebook: portfolioItem.facebook,
          twitter: portfolioItem.twitter,
          billingAttention: portfolioItem.billingAttention,
          billingCountry: portfolioItem.billingCountry,
          billingAddress1: portfolioItem.billingAddress1,
          billingAddress2: portfolioItem.billingAddress2,
          billingCity: portfolioItem.billingCity,
          billingState: portfolioItem.billingState,
          billingZipcode: portfolioItem.billingZipcode,
          billingPhone: portfolioItem.billingPhone,
          billingFax: portfolioItem.billingFax,
          shipAttention: portfolioItem.shipAttention,
          shipCountry: portfolioItem.shipCountry,
          shipAddress1: portfolioItem.shipAddress1,
          shipAddress2: portfolioItem.shipAddress2,
          shipCity: portfolioItem.shipCity,
          shipState: portfolioItem.shipState,
          shipPhone: portfolioItem.shipPhone,
          shipZipcode: portfolioItem.shipZipcode,
          shipFax: portfolioItem.shipFax,
          uniqueId: portfolioItem.uniqueId,
        });
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  const fetchClickedContactPerson = (id) => {
    const postUrl = BASE_URL + `/getVendorAllContactPerson/${id}`;

    fetch(postUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        const Data = data.responseMessage;

        Data.map((contact) => ({
          other: contact.other,
          firstName: contact.firstName,
          lastName: contact.lastName,
          email: contact.email,
          workphone: contact.workphone,
          mobile: contact.mobile,
        }));

        const extractedData = Data.map((item) => ({
          other: item.other,
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email,
          workphone: item.workphone,
          mobile: item.mobile,
        }));

        setFormData((prevData) => ({
          ...prevData,
          contactPerson: extractedData,
        }));

        const namesArray = extractedData.map(
          (item) => `${item.firstName} ${item.lastName}, `
        );
        setContactPersonName(namesArray);
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  const fetchClickedBillByVendorId = (id) => {
    const postUrl = BASE_URL + `/fetchBillsByVendorId/${id}`;
    setBillByVendorId();

    fetch(postUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        const BillData = data.responseMessage;
        setBillByVendorId(BillData);
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };
  const fetchClickedPurchaseByVendorId = (id) => {
    const postUrl = BASE_URL + `/fetchPurchaseOrderByVendorId/${id}`;
    setPurchaseOrderByVendorId();

    fetch(postUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        const PurchaseOrderData = data.responseMessage;
        setPurchaseOrderByVendorId(PurchaseOrderData);
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  const handleCancleClick = () => {
    props.handleCancel();
  };

  const accordionData = [
    {
      title: "Address",
      contenthead: "Billing Address",
      subcontent: formData.billingAddress1,

      head2: "Shipping Address",

      subcontent2: formData.shipAddress1,

      billAdress1: formData.billingAddress1,
      billAdress2: formData.billingAddress2,

      shipAddress1: formData.shipAddress1,
      shipAddress2: formData.shipAddress2,
    },
    {
      title: "Other Details",
      // Customer: "Customer Type",
      Payment: "Payment terms",
      pan: "PAN",
      subtitle: "Currency Code",
      currency: formData.currency,
    },
  ];

  const saveComments = (commentText) => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const userId = localStorage.getItem("userId");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const postUrl = BASE_URL + `/insertVendorComments`;

    fetch(postUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        vendorId: vendorCommentId,
        comments: commentText, // Add the comment text here
        userId: userId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          fetchComments();
          document.getElementById("floatingTextarea").value = "";
        }
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  const [commentFetch, setCommentFetch] = useState([]);
  const fetchComments = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const postUrl = BASE_URL + `/fetchVendorComments`;
    fetch(postUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        vendorId: vendorCommentId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setCommentFetch(data.responseMessage1);
        }
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  const fetchCommentsOnclick = (id) => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    setCommentFetch([]);

    const postUrl = BASE_URL + `/fetchVendorComments`;

    fetch(postUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        vendorId: id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setCommentFetch(data.responseMessage1);
        }
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };
  const deleteComments = (id) => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    setCommentFetch([]);

    const postUrl = BASE_URL + `/deleteVendorComments/${id}`;

    fetch(postUrl, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setCommentFetch(data.fetchedData);
        }
        fetchCommentsOnclick(id);
        fetchComments();
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };
  const [pdfData, setPdfData] = useState(null);
  const [pdfLoading, setPdfLoading] = useState(false);

  const itemDetails1 = statementList.map((item) => {
    return {
      date: item.billsDate || "",
      transactions: item.typeBill || "",
      details: item.billsNumber || "",
      amount: item.totalD || "",
      payments: item.Payment || "",
      balance: item.balance || "",
    };
  });
  const showPDF = async () => {
    setPdfLoading(true);
    const formDataObject = {
      organizationName: companyName,
      fDate: formatDate1(fromDate),
      tDate: formatDate1(toDate),
      vendorName: formData.vendorDisplayName,
      openingBalance: openingBalance,
      billedAmount: invoiceAmounted,
      amountPaid: amountPaid,
      balanceDue: lastBalance,
      items: itemDetails1,
      address: userDetails.address1,
      uniqueId: userDetails.uniqueId,
    };

    try {
      const response = await fetch(BASE_URL + "/generate-vendor-pdf", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(formDataObject),
      });

      if (response.ok) {
        const pdfBlob = await response.blob();
        setPdfData(URL.createObjectURL(pdfBlob));
        setPdfLoading(false);
      } else {
        console.error("Error: Failed to generate PDF");
        setPdfLoading(false);
      }
    } catch (error) {
      console.error("Error: ", error);
      setPdfLoading(false);
    }
  };
  const handleUpdate = (tab) => {
    showPDF();
    setSelectedTab(tab);
  };
  const handlePrint = () => {
    const printableContent = document.getElementById("printable-content");
    if (printableContent) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
          <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>${formData.vendorDisplayName}</title>
    <style>
        * {
            margin: 0px;
            padding: 0px;
            box-sizing: border-box;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 11px;
        }
        body {
            margin: 0px 5px;
        }
        hr {
            border: none;
            height: 2px;
            background-color: #52504f;
            border-radius: 5px;
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
            width: 90%;
        }
        .company-detail {
            display: flex;
            flex-direction: row;
            margin-top: 15px;
        }
        .company-detail-left {
            width: 50%
        }
        .company-detail-right {
            width: 50%;
            text-align: end;
            padding-right: 30px;
            line-height: 25px;
        }
        .user-detail {
            margin-top: 50px;
            display: flex;
            flex-direction: row;
        }
        .user-detail-left {
            width: 50%;
            padding-left: 10px;
        }
        .to {
            margin-bottom: 30px;
            font-weight: bold;
        }
        .reciver-name {
            font-weight: bold;
        }
        .user-detail-right {
            width: 50%;
        }
        .user-detail-heading {
            font-size: 23px;
            text-align: center;
            margin-bottom: 10px;
            margin-right: 30px;
        }
        .from-to-date {
            margin: 10px auto;
            text-align: center;
            padding-left: 0px;
        }
        .bg-color {
            background-color: rgb(95 90 90 / 15%);
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
            padding: 10px 1px;
            margin-top: 30px;
            margin-bottom: 5px;
        }
        #account-summary {
            font-weight: bold;
            padding-left: 10px;
            justify-items: center;
            align-items: center;
        }
        .user-detail-table {
            width: 100%;
            padding-left: 30px;
        }
        .ac-sum-left {
            height: 30px;
        }
        .ac-sum-right {
            text-align: right;
            padding-right: 15px;
        }
        #amount-paid-left {
            height: 50px;
        }
        #amount-paid-right {
            width: 50%;
            position: relative;
        }
        #ac-sum-right-hr {
            position: relative;
            top: 10px;
            padding: 0px;
            margin: 0px;
            width: 100%;
        }
        .transaction-table {
            width: 100%;
            border: none;
            border-collapse: collapse;
            margin-bottom: 20px;
            margin-top: 35px;
            border-bottom: 1px solid #4b49481a;
        }        
        .transaction-table th {
            background-color: #2d2828;
            font-weight: bold;
            color: white;
            text-align: left;
            padding: 8px;
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
        }
        .transaction-table td {
            padding: 8px 8px 8px 8px;
            page-break-inside: avoid;
        }
        .balance-due {
            text-align: right;
            font-size: 1.2em;
            font-weight: bold;
            margin-top: 20px;
        }
        #table-1-row {
            width: 13%;
        }
        #table-2-row {
            width: 13%;
        }
        #table-3-row {
            width: 18%;
        }
        #table-4-row {
            width: 8%;
        }
        #table-5-row {
            width: 12.25%;
        }
        #table-6-row {
            text-align: right;
            width: 12.25%;
        }
        .align-right {
            text-align: end;
        }
        .balance-due{
            display: flex;
        }
        .balance-due-left{
            width: 50%;
        }
        .balance-due-right{
            width: 50%;
        }
        #bal-due-table{
            width: 100%;
        }
        #bal-due-heading{
width: 50%;
        }
        #bal-due-amu
        {
width: 50%;
padding-right: 30px;
font-weight: 100;
        }
    </style>
</head>
<body>
    <div class="company-detail">
        <div class="company-detail-left"></div>
        <div class="company-detail-right">
            <div class="info">
                <b>${companyName}</b>
                <p class="address">${
                  userDetails.address1 ? userDetails.address1 : ""
                }  ${userDetails.address2 ? userDetails.address2 : ""}</p>
                <p class="email">${userDetails.city ? userDetails.city : ""}
                ${userDetails.zipcode ? userDetails.zipcode : ""}</p>
                <p class="state">${userDetails.state ? userDetails.state : ""}
                ${
                  userDetails.businessLocation
                    ? userDetails.businessLocation
                    : ""
                }</p>
            </div>
        </div>
    </div>
    <div class="user-detail">
        <div class="user-detail-left">
            <p class="to">To</p>
            <p class="reciver-name">${formData.vendorDisplayName}</p>
        </div>
        <div class="user-detail-right">
            <h3 class="user-detail-heading">Statement of Accounts</h3>
            <hr>
            <p class="from-to-date">${formatDate(fromDate)} To ${formatDate(
        toDate
      )}
            </p>
            <hr>
            <div class="bg-color">
                <p id="account-summary">Account Summery</p>
            </div>
            <table class="user-detail-table">
                <tr class="user-detail-table-tr">
                    <td class="ac-sum-left">Opening Balance</td>
                    <td class="ac-sum-right"> ₹ ${openingBalance || "0"}</td>
                </tr>
                <tr class="user-detail-table-tr">
                    <td class="ac-sum-left">Bill Amount</td>
                    <td class="ac-sum-right"> ₹ ${invoiceAmounted || "0"}</td>
                </tr>
                <tr class="user-detail-table-tr">
                    <td class="ac-sum-left" id="amount-paid-left">Amount Paid</td>
                    <td class="ac-sum-right" id="amount-paid-right"> ₹ ${
                      amountPaid || "0"
                    }
                        <hr id="ac-sum-right-hr">
                    </td>
                </tr>
                <tr class="user-detail-table-tr">
                    <td class="ac-sum-left">Balance Due</td>
                    <td class="ac-sum-right"> ₹ ${lastBalance || "0"} </td>
                </tr>
            </table>
        </div>
    </div>
    <table class="transaction-table">
        <thead>
            <tr>
                <th id="table-1-row">Date</th>
                <th id="table-2-row">Transactions</th>
                <th id="table-3-row">Details</th>
                <th id="table-4-row">Amount</th>
                <th id="table-5-row">Payments</th>
                <th id="table-6-row">Balance</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="align-left"></td>
                <td class="align-left">***Opening Balance***</td>
                <td class="align-left">-</td>
                <td class="align-right">${openingBalance}</td>
                <td class="align-left payments">-</td>
                <td class="align-right">${openingBalance}</td>
            </tr>
            ${statementList.map(
              (data) => `
            <tr>
                <td class="align-left">${data.billsDate}</td>
                <td class="align-left">${data.typeBill}</td>
                <td class="align-left">${data.billsNumber} ${data.dueDate}</td>
                <td class="align-right">${data.totalD || "0"}</td>
                <td class="align-left payments">${data.Payment || "-"}</td>
                <td class="align-right">${data.balance || "0"}</td>
            </tr>
            `
            )}
        </tbody>
    </table>
    <div class="balance-due">
        <div class="balance-due-left"></div>
        <div class="balance-due-right">
            <table id="bal-due-table">
                <tr>
                    <td id="bal-dur-heading">Balance Due</td>
                    <td id="bal-due-amu">₹ ${lastBalance || "0"} </td>
                </tr>
            </table>
        </div>
    </div>
</body>
</html>   
         `);
      printWindow.document.close();
      printWindow.onload = () => {
        printWindow.onafterprint = () => {
          printWindow.close();
          printWindow.onafterprint = null;
        };
        printWindow.print();
      };
    }
  };

  return (
    <>
      {showDetails && (
        <>
          <div className="d-flex align-items-center ml-1 mt-4">
            <div
              className="cursor-pointer d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "white",
                borderRadius: "50%",
                height: "35px",
                width: "38px",
              }}
            >
              <ArrowBackIosIcon
                width={2}
                onClick={handleCancleClick}
                className="arrow-child"
              />
            </div>
            <div className="headitems ml-2">
              <h5
                className="ml-1 mb-0"
                style={{ letterSpacing: "1px", color: "#086bd5de" }}
              >
                All Vendors
              </h5>
              <span className="text-muted ml-1">
                {vendorNameList.length} Vendors
              </span>
            </div>
          </div>
          <div
            className="card border-2 items-border mt-3"
            style={{
              borderRadius: "15px",
              height: "auto",
              marginBottom: "0px",
            }}
          >
            <div className="custom-customer-container">
              <div className="left-customer custom-scrollbar">
                <div className="text-left" style={{ cursor: "pointer" }}>
                  {vendorNameList.map((data, index) => (
                    <React.Fragment key={index}>
                      <div
                        className="customer-row pl-3"
                        onClick={() => {
                          fetchClickedVendor(data.id);
                          setFetchedVendorId(data.id);
                          setCustIdTabel(data.id);
                          setPostData({
                            ...postData,
                            vendorId: data.id,
                          });
                          setFetchedTerms(data.paymentTerms);
                        }}
                      >
                        <span style={{ fontSize: "16px" }} className="">
                          {data.vendorDisplayName}
                        </span>
                        <br />
                        <span className="text-muted">
                          ₹ {data.openingBalance || 0.0} /-
                        </span>
                      </div>
                      {index < vendorNameList.length - 0 && (
                        <div className="horizontal-line"></div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <div className="middle-line"></div>
              <div className="right-content1">
                <div className="d-flex justify-content-between mt-3 mb-1 ml-3">
                  <h6
                    className="flex-grow-1 mb-0 ml-1"
                    style={{ color: "#3C7BD4" }}
                  >
                    {formData.vendorDisplayName}{" "}
                  </h6>
                </div>
                <div className="cardm">
                  <div className="body">
                    <ul
                      className="nav nav-tabs-new  "
                      style={{ cursor: "pointer" }}
                    >
                      <li className="nav-item mr-4  ">
                        <button
                          className={`btn top-nav-buttons ${
                            selectedTab === "overview"
                              ? "btn-customer-link text-primary"
                              : "withoutbtn"
                          }`}
                          onClick={() => handleTabClick("overview")}
                        >
                          <span className="ml-2">OVERVIEW</span>{" "}
                        </button>
                      </li>
                      <li className="nav-item mr-4">
                        <button
                          className={`btn top-nav-buttons ${
                            selectedTab === "comments"
                              ? "btn-customer-link text-primary"
                              : "withoutbtn"
                          }`}
                          onClick={() => handleTabClick("comments")}
                        >
                          <span className="ml-2">COMMENTS</span>{" "}
                        </button>
                      </li>
                      <li className="nav-item mr-4">
                        <button
                          className={`btn top-nav-buttons ${
                            selectedTab === "sales"
                              ? "btn-customer-link text-primary"
                              : "withoutbtn"
                          }`}
                          onClick={() => handleTabClick("sales")}
                        >
                          <span className="ml-2">SALES</span>
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={`btn top-nav-buttons ${
                            selectedTab === "statements"
                              ? "btn-customer-link text-primary"
                              : "withoutbtn"
                          }`}
                          onClick={() => handleTabClick("statements")}
                        >
                          <span className="ml-2">STATEMENT</span>
                        </button>
                      </li>
                    </ul>
                    <hr
                      className=""
                      style={{ margin: "0.28rem 0px 0px 0px" }}
                    />

                    <div className="tab-content">
                      <div
                        className={`tab-pane ${
                          selectedTab === "overview" ? "active" : ""
                        }`}
                      >
                        <div
                          className="childcontainer overview-content"
                          style={{ overflow: "hidden", height: "560px" }}
                        >
                          <div className="first-col">
                            <div className="col pl-3 pt-3 pb-3">
                              <div className="row-left text-dark iconandname">
                                <span>
                                  <FontAwesomeIcon icon={faUser} />
                                </span>
                                <span className="ml-2">
                                  {formData.vendorDisplayName}
                                  <br />
                                  <small
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    className="editanddelete"
                                    onClick={() => handleOpenEdit(formData.id)}
                                  >
                                    Edit
                                  </small>{" "}
                                  /
                                  <span
                                    style={{ cursor: "pointer" }}
                                    className="editanddelete ml-1"
                                    onClick={() => handleDelete(formData.id)}
                                  >
                                    Delete
                                  </span>
                                </span>
                                <br />
                              </div>
                              <div className="row-right username"></div>
                            </div>
                            <hr className="m-0 ml-3" />
                            <div className="custom-accordion-container pl-3">
                              {accordionData.map((item, index) => (
                                <div key={index} className="accordion-item">
                                  <div
                                    className={`custom-accordion-title text-muted  ${
                                      activeIndex === index ? "active" : ""
                                    }`}
                                    onClick={() => handleAccordionClick(index)}
                                  >
                                    <span>{item.title}</span>
                                    <FontAwesomeIcon
                                      icon={faAngleDown}
                                      rotation={activeIndex === index ? 180 : 0}
                                    />
                                  </div>
                                  {activeIndex === index && (
                                    <div className="accordion-mcontaine">
                                      <div className="mb-3">
                                        {item.contenthead && (
                                          <div className="contentdata">
                                            <div>
                                              <span className="text-muted">
                                                {item.contenthead} :{" "}
                                              </span>
                                              <b
                                                className="ml-1"
                                                style={{ fontWeight: "500" }}
                                              >
                                                {formData.billingAddress1},
                                                {formData.billingAddress2}
                                              </b>
                                            </div>
                                            <div style={{ marginTop: "12px" }}>
                                              <span className="text-muted">
                                                {item.head2} :
                                              </span>
                                              <b
                                                className="ml-1"
                                                style={{ fontWeight: "500" }}
                                              >
                                                {formData.shipAddress1},
                                                {formData.shipAddress2}
                                              </b>
                                            </div>
                                          </div>
                                        )}
                                        {item.currency && (
                                          <div className="pl-0">
                                            <span className="titleandtext">
                                              <span className="text-muted">
                                                {item.subtitle} :
                                              </span>
                                              <b
                                                className="ml-1"
                                                style={{ fontWeight: "500" }}
                                              >
                                                {formData.currency}
                                              </b>
                                            </span>
                                            <span className="titleandtext">
                                              <span className="text-muted">
                                                {item.Payment}
                                              </span>
                                              <b
                                                className="ml-1"
                                                style={{ fontWeight: "500" }}
                                              >
                                                {formData.paymentTerms}
                                              </b>
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  <hr className="m-0" />
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="vertical-line"></div>
                          <div className="second-col">
                            <div className="inner-cols-container">
                              <div className="inner-col text-start unusedcredits mt-1 border-right">
                                <p className="outstanding mb-1 pl-4">
                                  Outstanding Receivables
                                </p>
                                <p className="outstandingvalue mt-1 pl-4">
                                  ₹ {fetchedTotalReceivables || 0} /-
                                </p>
                              </div>
                              <div
                                className="inner-col text-start unusedcredits mt-1 border-right"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                }}
                              >
                                <p className="outstanding mb-1 pl-5">
                                  Unused Credits :
                                </p>
                                <p className="outstandingvalue mt-1 pl-5">
                                  ₹ {fetchedUnusedCredits || 0} /-
                                </p>
                              </div>

                              <div
                                className="inner-col text-start unusedcredits mt-1"
                                style={{ fontSize: "15px", fontWeight: "bold" }}
                              >
                                <p className="outstanding mb-1 pl-5">
                                  Payment due period{" "}
                                </p>
                                <p className="outstandingvalue mt-1 pl-5">
                                  {" "}
                                  {fetchedTerms}
                                </p>
                              </div>
                            </div>

                            <div className="inner-cols-container ">
                              <div
                                className="inner-col text-dark"
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  marginTop: "20px",
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    alignContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <AnnualReportCardForVendor
                                    customerId={fetchedVendorId}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`tab-pane ${
                          selectedTab === "comments" ? "active" : ""
                        }`}
                      >
                        <div className="custom-card-body">
                          <div className="custom-container mt-2">
                            <div className="custom-form-floating">
                              <div className="textarea-comment-box mt-2">
                                <textarea
                                  className="tc-textarea"
                                  placeholder="Leave a comment here"
                                  id="floatingTextarea"
                                  style={{
                                    backgroundColor: "#F6F6F6",
                                    padding: "12px",
                                    borderRadius: "9px",
                                  }}
                                  cols={100}
                                ></textarea>
                              </div>
                              <br />
                            </div>
                          </div>
                          <button
                            className="custom-btn btn btn-primary rounded-2"
                            onClick={() =>
                              saveComments(
                                document.getElementById("floatingTextarea")
                                  .value
                              )
                            }
                          >
                            Comment
                          </button>
                        </div>
                        <div className="pl-4 pr-4">
                          <h6>
                            ALL COMMENTS{" "}
                            <Badge className="btn-primary">
                              {commentFetch.length}{" "}
                            </Badge>
                          </h6>
                          <hr className="mb-0" />
                        </div>
                        <div className="comments-history custom-scrollbar">
                          <ul className="list-unstyled">
                            {commentFetch.map((comment) => (
                              <li className="mb-4">
                                <div className="comment-section float-start">
                                  <div className="comment-container">
                                    <div className="float-comment-icon mr-2">
                                      <div className="txn-comment-icon circle-box">
                                        <img
                                          src={commenticon}
                                          alt="commenticon"
                                          style={{
                                            height: "16px",
                                            width: "16px",
                                            verticalAlign: "middle",
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="media-body">
                                      <div className="ms-3 mb-2 d-flex flex-wrap align-items-baseline justify-content-between">
                                        <span className="mw-50">
                                          <span className="text-md pe-3">
                                            <b className="comment-name">
                                              {formData.vendorDisplayName}
                                            </b>
                                          </span>
                                        </span>
                                        <div className="">
                                          <button
                                            className="btn btn-light icon-border btn-sm"
                                            onClick={() =>
                                              deleteComments(comment.id)
                                            }
                                          >
                                            <ion-icon name="trash-outline"></ion-icon>
                                          </button>
                                        </div>
                                      </div>
                                      <div className="wrapper-comment mb-2">
                                        <div className="wrapper-commentbox">
                                          <div className="comment-box">
                                            <p>{comment.comments}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div
                        className={`tab-pane custom-scrollbar ${
                          selectedTab === "sales" ? "active" : ""
                        }`}
                        style={{
                          maxHeight: "500px",
                          overflowY: "auto",
                          overflowX: "hidden",
                        }}
                      >
                        <div className="container my-3">
                          <div
                            className={`top-bar-icons ${
                              activeTab === "bills" ? "active" : ""
                            }`}
                          >
                            <span
                              className="ml-4"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleInnerTab("bills")}
                            >
                              {activeTab === "bills" ? (
                                <FaTimes />
                              ) : (
                                <FaChevronDown />
                              )}
                              {"  "}
                              <span className="heading-text">BILLS</span>
                            </span>

                            {activeTab === "bills" && (
                              <div
                                className="container-fluid"
                                style={{ marginTop: "0px", border: "none" }}
                              >
                                <Container fluid={true}>
                                  <div className="add-invoice-container d-flex justify-content-end  mb-2"></div>
                                  <Row>
                                    <Col>
                                      <Card className="mb-2">
                                        <div className="table-responsive">
                                          <table className="table  mb-0">
                                            <thead className="text-center text-heading">
                                              <tr>
                                                <th scope="col">Date</th>
                                                <th scope="col">Bill no</th>
                                                <th scope="col">Terms</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Status</th>
                                              </tr>
                                            </thead>
                                            {billByVendor &&
                                            Array.isArray(billByVendor) &&
                                            billByVendor.length > 0 ? (
                                              <tbody className="text1">
                                                {billByVendor.map((bill) => (
                                                  <tr key={bill.billsNumber}>
                                                    <td className="text-center">
                                                      {bill.billsDate}
                                                    </td>
                                                    <td className="text-center">
                                                      <a
                                                        href="#"
                                                        onClick={() =>
                                                          handleBillsOpen(
                                                            bill.billsNumber
                                                          )
                                                        }
                                                      >
                                                        {bill.billsNumber}
                                                      </a>
                                                    </td>
                                                    <td className="text-center">
                                                      {bill.terms}
                                                    </td>
                                                    <td className="text-center">
                                                      ₹ {bill.total}{" "}
                                                    </td>

                                                    <td className="text-center">
                                                      {bill.status}
                                                    </td>
                                                  </tr>
                                                ))}
                                              </tbody>
                                            ) : (
                                              <tbody>
                                                <tr>
                                                  <td
                                                    colSpan="6"
                                                    className="text-center"
                                                  >
                                                    {Array.isArray(billByVendor)
                                                      ? "No data"
                                                      : "No Invoice Data"}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            )}
                                          </table>
                                        </div>
                                      </Card>
                                    </Col>
                                  </Row>
                                </Container>
                              </div>
                            )}
                          </div>
                          <hr />
                          <div
                            className={`top-bar-icons text-dark ${
                              activeTab === "purchase-order" ? "active" : ""
                            }`}
                          >
                            <span
                              className="ml-4"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleInnerTab("purchase-order")}
                            >
                              {activeTab === "purchase-order" ? (
                                <FaTimes />
                              ) : (
                                <FaChevronDown />
                              )}
                              {"  "}
                              <span className="heading-text">
                                PURCHASE ORDER
                              </span>
                            </span>
                            {activeTab === "purchase-order" && (
                              <div
                                className="container-fluid"
                                style={{ marginTop: "0px" }}
                              >
                                <Container fluid={true}>
                                  <div className="add-invoice-container d-flex justify-content-end  mb-2"></div>
                                  <Row>
                                    <Col>
                                      <Card className="mb-2">
                                        <div className="table-responsive">
                                          <table className="table mb-0">
                                            <thead className="text-center text-heading">
                                              <tr>
                                                <th scope="col">Date</th>
                                                <th scope="col">
                                                  Purchase order
                                                </th>
                                                <th scope="col">Terms</th>
                                                <th scope="col">Amount</th>

                                                <th scope="col">Status</th>
                                              </tr>
                                            </thead>
                                            {purchaseOrderByVendor &&
                                            Array.isArray(
                                              purchaseOrderByVendor
                                            ) &&
                                            purchaseOrderByVendor.length > 0 ? (
                                              <tbody className="text1">
                                                {purchaseOrderByVendor.map(
                                                  (purchase) => (
                                                    <tr
                                                      key={
                                                        purchase.purchaseNumber
                                                      }
                                                    >
                                                      <td className="text-center">
                                                        {purchase.purchaseDate}
                                                      </td>
                                                      <td className="text-center">
                                                        <a
                                                          href="#"
                                                          onClick={() =>
                                                            handlePurchaseOpen(
                                                              purchase.purchaseNumber
                                                            )
                                                          }
                                                        >
                                                          {
                                                            purchase.purchaseNumber
                                                          }
                                                        </a>
                                                      </td>
                                                      <td className="text-center">
                                                        {purchase.terms}
                                                      </td>
                                                      <td className="text-center">
                                                        ₹ {purchase.total}{" "}
                                                      </td>

                                                      <td className="text-center">
                                                        {purchase.status}
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            ) : (
                                              <tbody>
                                                <tr>
                                                  <td
                                                    colSpan="6"
                                                    className="text-center"
                                                  >
                                                    {Array.isArray(
                                                      purchaseOrderByVendor
                                                    )
                                                      ? "No data"
                                                      : "No estimate Data"}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            )}
                                          </table>
                                        </div>
                                      </Card>
                                    </Col>
                                  </Row>
                                </Container>
                              </div>
                            )}
                          </div>
                          <hr />
                        </div>
                      </div>
                      <div
                        className={`tab-pane ${
                          selectedTab === "mails" ? "active" : ""
                        }`}
                      >
                        <div
                          className="container my-3"
                          style={{ overflow: "hidden", maxHeight: "500px" }}
                        >
                          <div
                            className="container-fluid"
                            style={{ marginTop: "0px" }}
                          >
                            <Container fluid={true}>
                              <Row>
                                <Col>
                                  <Card className="mb-2">
                                    <div className="table-responsive">
                                      <table className="table table-bordered table-striped mb-0">
                                        <thead className="text-center">
                                          <tr>
                                            <th scope="col">To</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Time</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <td
                                            colSpan="6"
                                            className="text-center"
                                          >
                                            There's no Mail
                                          </td>
                                        </tbody>
                                      </table>
                                    </div>
                                  </Card>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`tab-pane ${
                          selectedTab === "statements" ? "active" : ""
                        }`}
                      >
                        <div
                          className="container"
                          style={{ maxHeight: "500px" }}
                        >
                          <div className="d-flex justify-content-between mt-3 mb-1 ml-3">
                            <div className="d-flex align-items-center">
                              <span
                                className="input-group-text"
                                id="calendar-icon"
                                style={{
                                  padding: "6px",
                                }}
                              >
                                📅
                              </span>
                              <Select
                                className="mt-0"
                                options={options}
                                value={selectedOption}
                                onChange={handleOptionChange}
                                placeholder="Select Date Range"
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    border: "none",
                                    padding: "6px",
                                  }),
                                }}
                              />
                            </div>
                            <div
                              className="d-flex align-items-center"
                              id="printable-content"
                            >
                              <span
                                className="input-group-text"
                                id="pdf-icon"
                                onClick={() => handlePrint()}
                                style={{
                                  backgroundColor: "#F6F6F6",
                                  marginLeft: "5px",
                                  cursor: "pointer", // Adjust the margin as needed
                                }}
                              >
                                📄 {/* Emoji representation of PDF icon */}
                              </span>
                            </div>
                          </div>
                          <div className="mt-2">
                            <div className="d-flex justify-content-center">
                              <h5 style={{ fontWeight: "450" }}>
                                Vendor Statement for{" "}
                                {formData.vendorDisplayName}
                              </h5>
                            </div>
                            <div className="d-flex justify-content-center">
                              <span>
                                From {formatDate(fromDate)} To{" "}
                                {formatDate(toDate)}
                              </span>
                            </div>
                          </div>
                          <div className="statement-details-container">
                            <div className="customer-statement-container">
                              <div className="d-none d-md-block">
                                <div className="customer-statement-template custom-scrollbar">
                                  <div
                                    className="customer-template-header customer-header-content"
                                    id="header"
                                  >
                                    <div className="customer-template-fill-emptydiv"></div>
                                  </div>

                                  <div className="customer-template-body">
                                    <table
                                      style={{ lineHeight: "18px" }}
                                      cellpadding="0"
                                      cellspacing="0"
                                      border="0"
                                      width="100%"
                                    >
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          {userDetails && (
                                            <td
                                              width="50%"
                                              className="customer-orgname text-align-right"
                                            >
                                              <ul className="mb-0 text-align-right">
                                                <li className="text-bold">
                                                  {companyName}
                                                </li>
                                                <li>
                                                  {userDetails.address1
                                                    ? userDetails.address1
                                                    : ""}
                                                </li>
                                                <li>
                                                  {userDetails.address2
                                                    ? userDetails.address2
                                                    : ""}
                                                </li>

                                                <li>
                                                  {userDetails.city
                                                    ? userDetails.city
                                                    : ""}{" "}
                                                  {userDetails.zipcode
                                                    ? userDetails.zipcode
                                                    : ""}
                                                </li>
                                                <li>
                                                  {userDetails.state
                                                    ? userDetails.state
                                                    : ""}{" "}
                                                  {userDetails.businessLocation
                                                    ? userDetails.businessLocation
                                                    : ""}
                                                </li>
                                              </ul>
                                            </td>
                                          )}
                                        </tr>
                                        <tr height="18px"></tr>
                                        <tr>
                                          <td>
                                            <table
                                              cellpadding="0"
                                              cellspacing="0"
                                              border="0"
                                              width="50%"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td className="customer-label">
                                                    <b>To</b>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <span
                                                      style={{
                                                        whiteSpace: "preWrap",
                                                      }}
                                                    >
                                                      <strong>
                                                        <span className="customer-customer-name">
                                                          {
                                                            formData.vendorDisplayName
                                                          }
                                                        </span>
                                                      </strong>
                                                    </span>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                          <td
                                            style={{ verticalAlign: "bottom" }}
                                          >
                                            <table
                                              cellpadding="0"
                                              cellspacing="0"
                                              border="0"
                                              className="customer-title-section"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    className="customer-entity-title"
                                                    style={{
                                                      paddingTop: "6px",
                                                      lineHeight: "30px",
                                                    }}
                                                  >
                                                    <b>Statement of Accounts</b>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style={{
                                                      fontSize: "12px",
                                                      borderTop:
                                                        "1px solid #000",
                                                      borderBottom:
                                                        "1px solid #000",
                                                    }}
                                                    height="24"
                                                    className="text-align-right"
                                                  >
                                                    {formatDate(fromDate)} To{" "}
                                                    {formatDate(toDate)}{" "}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table
                                      style={{ lineHeight: "18px" }}
                                      cellpadding="0"
                                      cellspacing="0"
                                      border="0"
                                      width="100%"
                                    >
                                      <tbody>
                                        <tr>
                                          <td width="50%"></td>
                                          <td
                                            style={{
                                              padding: "20px 0px 30px 0px",
                                            }}
                                            valign="bottom"
                                          >
                                            <table
                                              cellpadding="5"
                                              cellspacing="0"
                                              width="79%"
                                              border="0"
                                              className="customer-summary-section"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    className="customer-label"
                                                    style={{
                                                      padding:
                                                        "4px 6px 4px 6px",
                                                      borderBottom:
                                                        "1px solid #dcdcdc",
                                                    }}
                                                    bgcolor="#e8e8e8"
                                                    colspan="5"
                                                  >
                                                    <b>Account Summary</b>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    className="customer-label"
                                                    style={{
                                                      paddingTop: "6px",
                                                    }}
                                                    width="50%"
                                                  >
                                                    Opening Balance
                                                  </td>
                                                  <td
                                                    style={{
                                                      padding:
                                                        "6px 0px 0px 6px",
                                                    }}
                                                    className="text-align-right"
                                                  >
                                                    ₹ {openingBalance}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    className="customer-label"
                                                    style={{
                                                      paddingTop: "4px",
                                                    }}
                                                  >
                                                    Bill Amount
                                                  </td>
                                                  <td
                                                    style={{
                                                      padding:
                                                        "6px 0px 0px 6px",
                                                    }}
                                                    className="text-align-right"
                                                  >
                                                    ₹ {invoiceAmounted}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td className="customer-label">
                                                    Amount Paid
                                                  </td>
                                                  <td
                                                    style={{
                                                      padding:
                                                        "4px 0px 2px 6px",
                                                    }}
                                                    className="text-align-right"
                                                  >
                                                    ₹ {amountPaid}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    className="customer-label"
                                                    style={{
                                                      paddingTop:
                                                        "6px;border-top:1px solid #000",
                                                    }}
                                                  >
                                                    Balance Due
                                                  </td>
                                                  <td
                                                    style={{
                                                      padding:
                                                        "6px 0px 0px 6px",
                                                      borderTop:
                                                        "1px solid #000",
                                                    }}
                                                    className="text-align-right"
                                                  >
                                                    ₹ {lastBalance}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table
                                      style={{
                                        lineHeight: "18px",
                                        marginTop: "10px",
                                      }}
                                      cellpadding="2"
                                      cellspacing="0"
                                      border="0"
                                      width="100%"
                                      className="trpadding"
                                    >
                                      <thead>
                                        <tr height="26">
                                          <th
                                            width="15%"
                                            className="customer-itemtable-header"
                                          >
                                            <b>Date</b>
                                          </th>
                                          <th
                                            width="14%"
                                            className="customer-itemtable-header"
                                          >
                                            <b>Transactions</b>
                                          </th>
                                          <th
                                            width="25%"
                                            className="customer-itemtable-header"
                                          >
                                            <b>Details</b>
                                          </th>

                                          <th
                                            width="13%"
                                            className="text-align-right customer-itemtable-header"
                                          >
                                            <b>Amount</b>
                                          </th>
                                          <th
                                            width="13%"
                                            className="text-align-right customer-itemtable-header"
                                          >
                                            <b>Payments</b>
                                          </th>
                                          <th
                                            width="20%"
                                            className="text-align-right customer-itemtable-header"
                                          >
                                            <b>Balance</b>
                                          </th>
                                        </tr>
                                      </thead>
                                      {statementList &&
                                      Array.isArray(statementList) &&
                                      statementList.length > 0 ? (
                                        <tbody className="itemBody">
                                          <tr className="customer-trclass_oddrow customer-breakrow-inside breakrow-after">
                                            <td className="customer-box-padding">
                                              -
                                            </td>
                                            <td className="customer-box-padding">
                                              ***Opening Balance***
                                            </td>
                                            <td className="customer-box-padding"></td>

                                            <td className="text-align-right customer-box-padding">
                                              {openingBalance}
                                            </td>
                                            <td className="text-align-right customer-box-padding"></td>
                                            <td className="text-align-right customer-box-padding">
                                              {openingBalance}
                                            </td>
                                          </tr>
                                          {statementList.map((data) => (
                                            <tr className="customer-trclass_oddrow customer-breakrow-inside breakrow-after">
                                              <td className="customer-box-padding">
                                                {data.billsDate}
                                              </td>
                                              <td className="customer-box-padding">
                                                {data.typeBill}
                                              </td>
                                              <td className="customer-box-padding">
                                                {data.billsNumber}{" "}
                                                {data.dueDate}
                                              </td>

                                              <td className="text-align-right customer-box-padding">
                                                {data.totalD || "0"}
                                              </td>
                                              <td className="text-align-right customer-box-padding">
                                                {data.Payment || "0"}
                                              </td>
                                              <td className="text-align-right customer-box-padding">
                                                {data.balance || "0"}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      ) : (
                                        <tbody className="itemBody">
                                          <tr className="customer-trclass_oddrow customer-breakrow-inside breakrow-after">
                                            <td className="customer-box-padding">
                                              -
                                            </td>
                                            <td className="customer-box-padding">
                                              ***Opening Balance***
                                            </td>
                                            <td className="customer-box-padding"></td>

                                            <td className="text-align-right customer-box-padding">
                                              0
                                            </td>
                                            <td className="text-align-right customer-box-padding"></td>
                                            <td className="text-align-right customer-box-padding">
                                              0
                                            </td>
                                          </tr>
                                        </tbody>
                                      )}
                                    </table>
                                    <table
                                      width="100%"
                                      style={{ borderTop: "1px solid #dcdcdc" }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td width="50%">
                                            <table width="100%">
                                              <tbody>
                                                <tr>
                                                  <td
                                                    width="50%"
                                                    className="customer-box-padding"
                                                    align="right"
                                                    valign="middle"
                                                  >
                                                    <b>Balance Due</b>
                                                  </td>
                                                  <td
                                                    className="customer-box-padding"
                                                    align="right"
                                                    valign="middle"
                                                  >
                                                    ₹ {lastBalance || "0"}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <br /> <br />
                                  </div>
                                  <div className="customer-template-footer">
                                    <div></div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="d-md-none"
                                style={{ padding: "20px" }}
                              >
                                <h4 className="text-center pb-2 border-bottom">
                                  Statement Of Accounts
                                </h4>
                                <p className="text-center mb-4">
                                  From {formatDate(fromDate)} To{" "}
                                  {formatDate(toDate)}
                                </p>
                                <div
                                  className="container"
                                  style={{
                                    background: "#F9F9FB",
                                    border: "1px solid #ddd",
                                    marginTop: "20px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <h5>
                                    <strong>Account Summary</strong>
                                  </h5>
                                </div>
                                <div className="container">
                                  <div className="mt-3">
                                    <div className="row mt-2 border-bottom">
                                      <div className="col p-2 text-muted font-weight-sbold">
                                        <span className="text-uppercase">
                                          Currency :{" "}
                                        </span>
                                        <span>INR - Indian Rupee</span>
                                      </div>
                                    </div>
                                    <div className="row mt-2">
                                      <div className="col p-2">
                                        <span>Opening Balance</span>
                                      </div>
                                      <div className="col p-2">
                                        <div className="text-right">₹ 0.00</div>
                                      </div>
                                    </div>
                                    <div className="row mt-2">
                                      <div className="col p-2">
                                        <span>Invoiced Amount</span>
                                      </div>
                                      <div className="col p-2">
                                        <div className="text-right">₹ 0.00</div>
                                      </div>
                                    </div>
                                    <div className="row mt-2">
                                      <div className="col p-2">
                                        <span>Amount Paid</span>
                                      </div>
                                      <div className="col p-2">
                                        <div className="text-right">₹ 0.00</div>
                                      </div>
                                    </div>
                                    <div
                                      className="row mt-2 border"
                                      style={{ background: "#F9F9FB" }}
                                    >
                                      <div className="col p-2">
                                        <span className="font-weight-sbold">
                                          Balance Due
                                        </span>
                                      </div>
                                      <div className="col p-2">
                                        <div className="text-right font-weight-sbold">
                                          ₹ 0.0
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`tab-pane ${
                          selectedTab === "pdf" ? "active" : ""
                        }`}
                      >
                        {/* <h6>Statement</h6> */}
                        <div
                          className="container"
                          style={{ maxHeight: "500px" }}
                        >
                          {pdfLoading ? (
                            <div
                              className="text-center"
                              style={{ marginTop: "50px" }}
                            >
                              {/* Show spinner while loading */}
                              <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            </div>
                          ) : (
                            <embed
                              src={pdfData}
                              type="application/pdf"
                              width="100%"
                              height="700px"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="horizontal-line"></div> */}
              </div>
            </div>
          </div>
        </>
      )}

      {vendorEditPage && (
        <VendorsUpdate id={vendorId} handleCancle={handleCancelPage} />
      )}

      {showPurchaseDetails && (
        <PurchasesOrderDetails
          newinvoiceNumber={purchaseNumber}
          handleCancel={handleCancelPage}
        />
      )}

      {showBillsDetails && (
        <BillsDetails
          newinvoiceNumber={billsNumber}
          handleCancel={handleCancelPage}
        />
      )}
    </>
  );
}

export default VendorDetails;
