import React from "react";
import { Container, Row, Card, Col } from "react-bootstrap";

class ManageProjectCard extends React.Component {
  render() {
    return (
      <div className="col-lg-12">
        <Container fluid={true}>
          <Row>
            <Col>
              <Card>
                <div className="table-responsive">
                  <table class="table table-bordered table-striped mb-0">
                    <thead style={{ fontSize: "12px" }}>
                      <tr>
                        <th scope="col">SR NO</th>
                        <th scope="col">PROJECT NAME</th>
                        <th scope="col">TYPE</th>
                        <th scope="col">CUSTOMER NAME</th>
                        <th scope="col">TECHNOLOGY</th>
                        <th scope="col">DATE START</th>
                        <th scope="col">AMC DATE</th>
                        <th scope="col">AMC AMOUNT</th>
                        <th scope="col">SERVER NAME</th>
                        <th scope="col">END DATE</th>
                        <th scope="col">ACTION UNIT</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>yash</td>
                        <td>none</td>
                        <td>yash123@ dudhankar</td>
                        <td>none</td>
                        <td>2023-07-29</td>
                        <td>2023-07-29</td>
                        <td>Xyz</td>
                        <td>yash</td>
                        <td>2023-07-30</td>
                        <td>
                          {" "}
                          <button
                            className="btn btn-primary btn-sm mr-2"
                            onClick={() => this.handleEdit(2)}
                          >
                            <i className="fa fa-edit"></i>
                          </button>
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => this.handleDelete(2)}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default ManageProjectCard;
