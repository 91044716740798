import React from "react";
import "../../asset/css/inbox.css";
import { Container, Card, Row, Col } from "react-bootstrap";

class ManageRecipeCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCompose: false,
    };
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <Container fluid={true}>
            <Row>
              <Col>
                <Card>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped mb-0">
                      <thead className="text-center">
                        <tr>
                          <th scope="col">SERIAL NO</th>
                          <th scope="col">PRODUCT NAME</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
export default ManageRecipeCard;
