import React, { useEffect, useState } from "react";
import { XCircle, User } from "phosphor-react";
import swal from "sweetalert";
import Select from "react-select";
// import { fetchData } from "../helper";

function ManageProjectDataNew(props) {
  const handleCancleClick = () => {
    props.handleCancle();
  };

  const projectNameRecords = [{ value: "customer 1 ", label: "customer 1" }];

  const [serverData, setServerData] = useState({
    projectName: "",
    dataType: "",
    link: "",
    loginId: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setServerData({
      ...serverData,
      [name]: value,
    });
  };

  const handleSaveClick = () => {
    const apiUrl = "http://localhost:8096/api/insertProjectData";
    const headers = {
      "Content-Type": "application/json",
    };
    const requestBody = JSON.stringify(serverData);
    fetch(apiUrl, {
      method: "POST",
      headers,
      body: requestBody,
    })
      .then((response) => {
        if (response.status === 200) {
          swal({
            icon: "success",
            title: "Project Data Inserted Successfully",
          });
        } else {
          console.error("Error adding project");
          swal({
            icon: "error",
            title: "Failed to insert data",
          });
        }
      })
      .catch((error) => {
        console.error("Error adding project", error);
      });
  };

  return (
    <div className="container my-3">
      <div className="border border-2 p-2">
        <div className="px-2 d-flex justify-content-between">
          <h4 className="mt-3 mx-2" style={{ letterSpacing: "1px" }}>
            Add New Project Data
          </h4>
          <button className="btn" onClick={handleCancleClick}>
            <XCircle size={32} weight="fill" />
          </button>
        </div>
        <hr />
        <div className="container">
          <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label mg-b-0">
                <span className="text-dashed-underline">Project Name </span>
              </label>
            </div>
            <div className="" style={{ width: "190px" }}>
              <Select
                options={projectNameRecords}
                value={projectNameRecords.find(
                  (option) => option.value === serverData.projectName
                )}
                onChange={(selectedOption) =>
                  setServerData({
                    ...serverData,
                    projectName: selectedOption.value,
                  })
                }
                placeholder="Select Server..."
              />
              {/* <select className="form-control" name="projectName" value={serverData.projectName} onChange={handleInputChange}>
                                <option value="1">1</option>
                            </select> */}
            </div>
          </div>

          <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label required mg-b-0">Datatype </label>
            </div>
            <div>
              <input
                className="form-control"
                placeholder="Datatype"
                name="dataType"
                value={serverData.dataType}
                onChange={handleInputChange}
                type="text"
              />
            </div>
          </div>

          <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label required mg-b-0">Link </label>
            </div>
            <div>
              <input
                className="form-control"
                placeholder="Link"
                name="link"
                value={serverData.link}
                onChange={handleInputChange}
                type="text"
              />
            </div>
          </div>

          <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label required mg-b-0">Login Id </label>
            </div>
            <div>
              <input
                className="form-control"
                placeholder="Login Id"
                name="loginId"
                value={serverData.loginId}
                onChange={handleInputChange}
                type="text"
              />
            </div>
          </div>

          <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label required mg-b-0">Password</label>
            </div>
            <div>
              <input
                className="form-control"
                placeholder="Password"
                name="password"
                value={serverData.password}
                onChange={handleInputChange}
                type="text"
              />
            </div>
          </div>

          <div className="row mg-t-15 pt-3 align-items-center mg-b-20"></div>
          <div className="my-2 text-right">
            <button
              className="btn btn-primary px-2 py-1"
              style={{ backgroundColor: "var(--primary-color)" }}
              onClick={handleSaveClick}
            >
              Save
              {"  "}
              <i className="fa fa-save"></i>
            </button>
            <button
              className="btn btn-secondary mx-2 px-2 py-1"
              onClick={handleCancleClick}
            >
              Cancel <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageProjectDataNew;
