import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { BASE_URL } from "../../screens/Dashboard/CommonApi";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";

function RazorpayCard(props) {
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const jwtToken = localStorage.getItem("jwtToken");
  const [secret , setSecret] = useState("");
  const [key , setKey] = useState("");

  const [formData, setFormData] = useState({
    razorPayKey: key,
    razorPaySecret: secret,
    uniqueId: decryptedUniqueId,
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSaveClick = () => {
    fetch(BASE_URL + `/updateRazorPay`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          swal({
            icon: "success",
            title: "Updated Successfully",
            text: "",
          });
        } else {
          swal({
            icon: "error",
            title: "Failed to Updated employee data",
            text: "",
          });
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
        swal({
          icon: "error",
          title: "Network Error",
          text: "An error occurred while communicating with the server.",
        });
      });
  };

  const fetchInvoices = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchRazorPay`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization" : `Bearer ${jwtToken}`
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const Data = data.responseMessage1;
          setFormData({
            uniqueId: decryptedUniqueId,
            razorPayKey: Data.razorPayKey,
            razorPaySecret: Data.razorPaySecret,
          });          
          setSecret(Data.razorPaySecret);
          setKey(Data.razorPayKey);
        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  };

  useEffect(() => {
    fetchInvoices();
  }, []);
  return (
    <>
      <div className="col-lg-12">
        <Container fluid={true}>
          <Row>
            <Col>
              <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                <div className="col-md-2 ">
                  <label className="form-label required mg-b-0">
                    {" "}
                    Razorpay Key{" "}
                  </label>
                </div>
                <div className="col-md-6">
                  <input
                    className="form-control"
                    placeholder="Razorpay Key"
                    name="razorPayKey"
                    type="text"
                    value={formData.razorPayKey}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                <div className="col-md-2">
                  <label className="form-label required mg-b-0">
                    Razorpay Secret
                  </label>
                </div>
                <div className="col-md-6">
                  <input
                    className="form-control"
                    placeholder="Razorpay Secret"
                    name="razorPaySecret"
                    type="text"
                    value={formData.razorPaySecret}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <div className="my-2 text-left">
            <button
              className="btn "
              style={{ backgroundColor: "#06699d", color: "white" }}
              onClick={handleSaveClick}
            >
              Save <i className="fa fa-save" style={{ color: "white" }}></i>
            </button>
          </div>
        </Container>
      </div>
    </>
  );
}

export default RazorpayCard;
