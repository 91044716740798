import React, { useState } from "react";
import { XCircle, User } from "phosphor-react";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";

function ManageSalesPersonNew(props) {
  const handleCancleClick = () => {
    props.handleCancle();
  };

  const encryptedUniqueId = localStorage.getItem("uniqueId1");

  // Decrypt the uniqueId using your decryption method
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [serverData, setServerData] = useState({
    name: "",
    email: "",
    uniqueId: decryptedUniqueId,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setServerData({
      ...serverData,
      [name]: value,
    });
  };

  const handleSaveClick = () => {
    handleCancleClick();
    // Define the URL of your API endpoint
    const apiUrl = "http://app.zeroserp.in/api/createSalesperson";

    // Define the request headers
    const headers = {
      "Content-Type": "application/json",
    };

    // Create the request body by converting the serverData object to JSON
    const requestBody = JSON.stringify(serverData);

    // Send a POST request to the API
    fetch(apiUrl, {
      method: "POST",
      headers,
      body: requestBody,
    })
      .then((response) => {
        if (response.ok) {
          swal({
            icon: "success",
            title: "Estimate Data Inserted Successfully",
          });
        } else {
          swal({
            icon: "error",
            title: "Failed to insert data",
          });
        }
      })
      .catch((error) => {
        console.error("Error adding server", error);
      });
  };

  return (
    <div className="container my-3">
      <div className="border border-2 p-2">
        <div className="px-2 d-flex justify-content-between">
          <h4 className="mt-3 mx-2">
            <b>New Sales Person</b>
          </h4>
          <button className="btn" onClick={handleCancleClick}>
            <XCircle size={32} weight="fill" />
          </button>
        </div>
        <hr />
        <div className="container">
          <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2 ">
              <label className="form-label required mg-b-0">
                <b>Name : </b>
              </label>
            </div>
            <div className="col-md-6">
              <input
                className="form-control"
                placeholder="Name"
                name="name"
                value={serverData.name}
                onChange={handleInputChange}
                type="text"
              />
            </div>
          </div>

          <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label required mg-b-0">
                <b>Email : </b>
              </label>
            </div>
            <div className="col-md-6">
              <input
                className="form-control"
                placeholder="Email"
                name="email"
                value={serverData.email}
                onChange={handleInputChange}
                type="text"
              />
            </div>
          </div>

          <div className="my-2 text-right">
            <button className="btn btn-success" onClick={handleSaveClick}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageSalesPersonNew;
