import React from "react";
import ReactEcharts from "echarts-for-react";
import "./Analytical.css";
const CashFlowChart = ({ lineChartData }) => {

  return (
    <>
      <div style={{ marginLeft: "10px", marginTop: "10px" }}>
        <h5 style={{ color: "black", fontSize: "20px" }}>Cash Flow</h5>
      </div>
      <div className="row">
        <div className="col-md-9">
          <div>
            <ReactEcharts option={{ tooltip: {trigger: "axis",backgroundColor: "#fff",borderColor: "#ccc",borderWidth: 1,textStyle: {color: "#333",},
                  formatter: function (params) {
                    const dataIndex = params[0].dataIndex;
                    const month = lineChartData.xAxisData[dataIndex];
                    const amount = lineChartData.seriesData[dataIndex];
                    return `<div style="padding: 10px; font-size: 14px;"> <strong>${month}</strong><br/> INR : ₹${amount.toLocaleString('en-IN')}</div>`;},},
                xAxis: { type: "category", data: lineChartData.xAxisData,},
                yAxis: { type: "value",},
                series: [{ data: lineChartData.seriesData, type: "line",label: { show: false,},emphasis: { focus: "series", label: { show: false, position: "top", formatter: "{c}",},},},],}}
              opts={{ renderer: "svg" }}
              style={{ height: "500px", width: "100%", }} />
          </div>
        </div>
        <div className="vertical-line1"></div>
        <div className="col-md-3 current-column2">
          <br />
          <div className="row">
            <div className="col">
              <span style={{ color: "MediumSeaGreen", fontSize: "16px" }} className="text-center"> Incoming </span>
            </div>
            <div className="col mt-2"> <h6>₹ 0.00 +</h6> </div>
          </div>
          <br />
          <div className="row">
            <div className="col">
              <span style={{ color: "red", fontSize: "16px" }} className="text-center"> Outgoing </span>
            </div>
            <div className="col mt-2"> <h6>₹ 0.00</h6> </div>
          </div>
          <br />
          <div className="row">
            <div className="col">
              <span style={{ color: "green", fontSize: "16px" }} className="text-center"> Cash as on </span>
            </div>
            <div className="col mt-2"> <h6>₹ 0.00</h6> </div>
          </div>
          <br />
        </div>
      </div>
    </>
  );};
export default CashFlowChart;