import React, { useState } from "react";
import "./Reports.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';

function Reports({ navigation }) {
  const fontsize1 = "1.5rem";

  const openProfit = () => {
    handleNavigation("ProfitAndLoss");
  };
  const cancle = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      handleNavigation("dashboard");
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const openBalanceSheet = () => {
    handleNavigation("BalanceSheet");
  };

  const openProfitAndLoss3 = () => {
    handleNavigation("ProfitAndLossShedual3");
  };

  const openHorizontalProfit = () => {
    handleNavigation("HorizontalProfit");
  };

  const opencashStatement = () => {
    handleNavigation("CashStatement");
  };

  const openSalesCustomer = () => {
    handleNavigation("SalesCustomer");
  };

  const openSalesItems = () => {
    handleNavigation("SalesItems");
  };

  const openSalesperson = () => {
    handleNavigation("SalesBySalesperson");
  };
  const openItemStocks=() =>{
    handleNavigation("ItemStocks")
  }

  const openSalesByInvoice=() =>{
    handleNavigation("SalesByInvoice")
  }

  const openCustomerBalance = () => {
    handleNavigation("CustomerBalance");
  };

  // ************ Reports Update ******************
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleHorizontalBalanceSheet = () => {
    handleNavigation("HorizontalBalanceSheet");
  };
  const handleBalanceSheetSchedule = () => {
    handleNavigation("BalanceSheetSchedule");
  };
  const handleBusinessPerfomanceRatios = () => {
    handleNavigation("BusinessPerfomanceRatios");
  };
  const handleMovementOfEquity = () => {
    handleNavigation("MovementOfEquity");
  };
  const handleAgingSummary = () => {
    handleNavigation("AgingSummary");
  };
  const handleInvoiceDetails = () => {
    handleNavigation("ReportsInvoiceDetails");
  };
  const handleSalesOrderDetails = () => {
    handleNavigation("SalesOrderDetails");
  };
  const handleCustomerBalanceSummary = () => {
    handleNavigation("CustomerBalanceSummary");
  };
  const handleReceivableSummary = () => {
    handleNavigation("ReceivableSummary");
  };
  const handleReceivableDetails = () => {
    handleNavigation("ReceivableDetails");
  };
  const handlePaymentsReceived = () => {
    handleNavigation("PaymentsReceived");
  };
  const handleGetPaid = () => {
    handleNavigation("GetPaid");
  };
  const handleCreditNoteDetails = ()=>{
    handleNavigation("CreditNoteDetails");
  }
  const handleVendorBalances = () => {
    handleNavigation("VendorBalances");
  };
  const handleVendorBalancesSummary = () => {
    handleNavigation("VendorBalancesSummary");
  };
  const handleApAgingSummary = () => {
    handleNavigation("ApAgingSummary");
  };
  const handleBillsDetails = () => {
    handleNavigation("BillsDetails");
  };
  const handlePaymentsMadeReport = () => {
    handleNavigation("PaymentsMadeReport");
  };
  const handlePayableSummary = () => {
    handleNavigation("PayableSummary");
  };

  const handlePayableDetails = () =>{
    handleNavigation("PayableDetails")
  }
  const handleAccountTransactions = () => {
    handleNavigation("AccountTransactions");
  };
  const handleAccountTypeSummary = () => {
    handleNavigation("AccountTypeSummary");
  };
  const handleAccountTypeTransactions = () => {
    handleNavigation("AccountTypeTransactions");
  };
  const handleDayBook = () => {
    handleNavigation("DayBook");
  };

  //*******  *********/

  return (
    <>
     <div className="d-flex align-items-center mt-4">
        <div
          className="cursor-pointer d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: "white",
            borderRadius: "50%",
            height: "35px",
            width: "38px",
            cursor: "pointer",
          }}
        >
          <ArrowBackIosIcon
            width={2}
            onClick={cancle}
            className="arrow-child"
          />
        </div>
        <div className="headitems ml-2">
          <h5
            className="ml-1 mb-0"
            style={{ letterSpacing: "1px", color: "#086bd5de" }}
          >
            All Reports
          </h5>
        </div>
      </div>
      <div className="report-page mt-2">
        <div className="p-3">
          <div className="row">
            <div className="col-lg-4">
              {/* First Part */}
              <div className="report-section">
                <h5 className="mb-2">
                  <i className="fa fa-building mr-2"></i>Business Overview
                </h5>
                <div className="nav flex-column">
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={openProfit}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Profit and Loss</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={openProfitAndLoss3}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">
                        Profit and Loss (Schedule III)
                      </span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={openHorizontalProfit}
                    >
                      <span>
                        <i
                          className="fa fa-star text-black"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Horizontal Profit and Loss</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={opencashStatement}
                    >
                      <span>
                        <i
                          className="fa fa-star text-black"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Cash Flow Statement</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={openBalanceSheet}
                    >
                      <span>
                        <i
                          className="fa fa-star text-black"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Balance Sheet</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handleHorizontalBalanceSheet}
                    >
                      <span>
                        <i
                          className="fa fa-star text-black"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Horizontal Balance Sheet</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handleBalanceSheetSchedule}
                    >
                      <span>
                        <i
                          className="fa fa-star text-black"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">
                        Balance Sheet (Schedule III)
                      </span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handleBusinessPerfomanceRatios}
                    >
                      <span>
                        <i
                          className="fa fa-star text-black"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Business Performance Ratios</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handleMovementOfEquity}
                    >
                      <span>
                        <i
                          className="fa fa-star text-black"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Movement Of Equity</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              {/* Second Part */}
              <div className="report-section">
                <h5 className="mb-2">
                  <i className="fa fa-shopping-cart mr-2"></i>Sales
                </h5>
                <div className="nav flex-column">
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={openSalesCustomer}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Sales By Customers</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={openSalesItems}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Sales By Items</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={openSalesperson}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Sales By SalesPerson</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={openItemStocks}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Item Stocks</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={openSalesByInvoice}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Sales by Invoice</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="report-section">
                <h5 className="mb-2">
                  <i className="fa fa-money mr-2"></i>Receivables
                </h5>

                <div className="nav flex-column">
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={openCustomerBalance}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Customer Balance</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handleAgingSummary}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Aging Summary</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handleInvoiceDetails}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Invoice Details</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handleSalesOrderDetails}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Sales Order Details</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handleCustomerBalanceSummary}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Customer Balance Summary</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handleReceivableSummary}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Receivable Summary</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handleReceivableDetails}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Receivable Details</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* first section end */}
          <div className="row">
            <div className="col-lg-4">
              {/* First Part */}
              <div className="report-section">
                <h5 className="mb-2">
                  <i className="fa fa-money mr-2"></i>Payments Received
                </h5>
                <div className="nav flex-column">
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handlePaymentsReceived}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Payments Received</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handleGetPaid}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Time to Get Paid</span>
                    </a>
                  </div>
                  {/* <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handleCreditNoteDetails}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Credit Note Details</span>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              {/* Second Part */}
              <div className="report-section">
                <h5 className="mb-2">
                <CurrencyRupeeRoundedIcon  
                    className="icon-menu"
                    style={{ width: "20px" , color:"black"}}
                  />
                   Payables
                </h5>
                <div className="nav flex-column">
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handleVendorBalances}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Vendor Balances</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handleVendorBalancesSummary}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Vendor Balances Summary</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handleApAgingSummary}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">AP Aging Summary</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handleBillsDetails}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Bills Details</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handlePaymentsMadeReport}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Payments Made</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handlePayableSummary}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Payable Summary</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handlePayableDetails}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Payable Details</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="report-section">
                <h5 className="mb-2">
                  <i className="fa fa-calculator mr-2"></i>Accountant
                </h5>
                <div className="nav flex-column">
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handleAccountTransactions}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Account Transactions</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handleAccountTypeSummary}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Account Type Summary</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handleAccountTypeTransactions}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Account Type Transactions</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      href="#"
                      className="reports-border-bottom"
                      style={{ fontsize1 }}
                      onClick={handleDayBook}
                    >
                      <span>
                        <i
                          className="fa fa-star"
                          style={{ background: "transparent" }}
                        ></i>
                      </span>
                      <span className="ml-2 ">Day Book</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Reports;
