import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import { Provider } from 'react-redux'
// import { createStore,applyMiddleware } from 'redux'
// import thunk from 'redux-thunk';
// import reducers from './reducers';

// import './asset/scss/main.scss';
import './asset/vendor/font-awesome/css/font-awesome.min.css';
import './asset/css/main.css';
import './asset/css/blog.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId='162642513424-t0kgf33c6sfcdmgob5samcakcsu9aunq.apps.googleusercontent.com'>
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
