import React, { useState ,useEffect } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import NoDataImage from "../../../asset/images/nodata.jpg"
import swal from "sweetalert"; 
import CreditNotesUpdate from "../SalesNew/CreditNotesUpdate";
import CreditNoteDetails from "../SalesNew/CreditNoteDetails";

function CreditNotes ({navigation}) {
  const [showUpdate, setShowUpdate] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showCard, setShowCard] = useState(true);
  const [creditNotes, setcreditNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [creditNoteNumber, setCreditNoteNumber] = useState([]);
  const [newinvoiceNumber, setnewInvoice] = useState([]);
  const [customerId , setCustomerId] = useState("")
  const jwtToken=localStorage.getItem("jwtToken");
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleCreditClick = () => {
    handleNavigation("CreditNoteNew");
  };

  const fetchCreditNotes = () => {   
    $("#DataTables_Table_0").DataTable();
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchCreditNotes`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization" : `Bearer ${jwtToken}`
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId ,fromDate:"",toDate:"" }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setcreditNotes(data.responseMessage);
          setLoading(false);
        } else {
          console.error("Failed to fetch Credit Notes:", data.responseMessage);
          setLoading(false);
        }
        setLoading(false);
        $("#DataTables_Table_0").DataTable();

      })
      .catch((error) => {
        console.error("Error fetching Credit Notes:", error);
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      });
  };

  useEffect(() => {
    fetchCreditNotes();
  }, []);

  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const handleDelete = (id) => {
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this credit note!"
    );
  
    if (userConfirmed) {
      // Send a DELETE request to your backend API
      fetch(BASE_URL + `/deleteByCreditNoteNumber/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization" : `Bearer ${jwtToken}`
        },
        body: JSON.stringify({ uniqueId: decryptedUniqueId }),
      })
        .then((response) => {
          if (response.status === 200) {
            setcreditNotes((prevCredits) =>
              prevCredits.filter((creditNotes) => creditNotes.creditNoteNumber !== id)
            );
            setLoading(true); 
            fetchCreditNotes()            
            swal({
              icon: "success",
              title: "Credit Notes Deleted Successfully",
            });
          } else {
            console.error("Failed to delete Credit Notes");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error deleting Credit Notes:", error);
        });
    } else {
      alert("Credit Note deletion canceled!");
    }
  };
  
  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }
  const handleEdit = (creditNoteNumber) => {
    setCreditNoteNumber(creditNoteNumber);
    setShowUpdate(true)
    setShowCard(false)
  };
  const handleCancelUpdate = () => {
    fetchCreditNotes();
    setShowUpdate(false);
    setShowCard(true);
  };
  const handleCancelDetails = () => {
    setShowDetails(false);
    setShowCard(true);
    setShowUpdate(false);
    fetchCreditNotes();
  };
  const handleInvoice = (creditNoteNumber ,customerId) => {
    setnewInvoice(creditNoteNumber);
    setCustomerId(customerId)
    setShowDetails(true)
    setShowCard(false)
  };

    return (
      <div className="container-fluid">
      {!showUpdate && !showDetails && (
        <div className="item-page">
          <div className="d-flex justify-content-between">
            <div className="mt-2" style={{color: "#3C7BD4",fontSize: 20,fontWeight: "600",wordWrap: "break-word",textAlign: "left",}}>Credit Notes</div>
            <div className="d-flex">
              <div className="">
                <button className="btn btn-primary px-1 py-2 new-purchase" onClick={handleCreditClick}>+ New CreditNote</button>
              </div>
              <div className="ml-2 px-1 py-2 icon-dot">
                <i className="fa fa-ellipsis-v " style={{ fontSize: 18, color: "gray" }}></i>
              </div>
            </div>
          </div>
        </div>
      )}        

      {showCard && (
        <div className="container-fluid">
        <div className="page-content">
          <Container fluid={true}>
            <Row>
              <Col>
              <Card style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                  {creditNotes && creditNotes.length > 0 ? (
                    <>                    
                      <div className="body">
                        <div className="table-responsive1">
                          <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer" >
                            <div className="row">
                              <div className="col-sm-12">
                                <table className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info" >
                                  <thead className="heading-text">
                                    <tr role="row">
                                      <th className="sorting_asc" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="Date: activate to sort column descending">
                                        Date
                                      </th>
                                      <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Type: activate to sort column ascending" >
                                        Credit note#
                                      </th>
                                      <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Amount BTC: activate to sort column ascending">
                                        Reference 
                                      </th>
                                      <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="BTC Remaining: activate to sort column ascending">
                                        Customer name
                                      </th>
                                      <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Price: activate to sort column ascending">
                                        Status
                                      </th>
                                      <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Price: activate to sort column ascending">
                                        Total
                                      </th>
                                      <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Price: activate to sort column ascending">
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="text1">
                                    {creditNotes.map((credit) => (
                                      <tr role="row" className="odd" key={credit.id}>
                                        <td>{credit.creditNoteDate}</td>
                                        <td className="sorting_1">
                                            {" "}
                                            <a href="#" onClick={() =>handleInvoice(credit.creditNoteNumber,credit.customerId)}>
                                              {credit.creditNoteNumber}
                                            </a>
                                        </td>
                                        <td>{credit.reference}</td>
                                        <td>{credit.customerName}</td>
                                        <td>{credit.status}</td>
                                        <td>₹{credit.total}</td>
                                        <td>                                          
                                          <button className="btn icon-border btn-sm mr-2" onClick={() =>handleEdit(credit.creditNoteNumber)}>
                                            <i className="fa fa-edit text1"></i>
                                          </button>
                                          <button className="btn icon-border btn-sm" onClick={() =>handleDelete(credit.creditNoteNumber)}>
                                            <i className="fa fa-trash text1"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="body">
                      <div className="table-responsive1">
                        <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                          <div className="row">
                            <div className="col-sm-12">
                              <table className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
                                <thead className="heading-text">
                                  <tr role="row">
                                    <th className="sorting_asc" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="Date: activate to sort column descending">
                                      Date
                                    </th>
                                    <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Type: activate to sort column ascending">
                                      Credit note#
                                    </th>
                                    <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Amount BTC: activate to sort column ascending">
                                      Reference
                                    </th>
                                    <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="BTC Remaining: activate to sort column ascending">
                                      Customer name
                                    </th>
                                    <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Price: activate to sort column ascending">
                                      Status
                                    </th>
                                    <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Price: activate to sort column ascending">
                                      Amount
                                    </th>
                                    <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Price: activate to sort column ascending">                                     
                                    </th>
                                  </tr>
                                </thead>
                                <tbody></tbody>
                              </table>
                              <h6 className="text-center">
                                <img src={NoDataImage} style={{ width: "50%", height: "50%" }} alt="No Data Logo"/>{" "}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>           
          </Container>
        </div>
      </div>
      )}
      {showUpdate && ( <CreditNotesUpdate creditNoteNumber={creditNoteNumber} handleCancel={handleCancelUpdate}/>)}
      {showDetails && ( <CreditNoteDetails newinvoiceNumber={newinvoiceNumber} custId ={customerId} handleCancel={handleCancelDetails}/>)}
        </div>
    );
}
export default CreditNotes