import React from "react";
import { createNavigator, SwitchRouter } from "@react-navigation/core";
import { createBrowserApp } from "@react-navigation/web";
import SidebarView from "./Components/SidebarView";
import login from "./screens/auth/Login";
import dashboard from "./screens/Dashboard/index";
import demographic from "./screens/Dashboard/index";
import crypto from "./screens/Dashboard/index";
import eCommerce from "./screens/Dashboard/index";
import bankacc from "./screens/Dashboard/index";
import chat from "./screens/Dashboard/index";
import calendar from "./screens/Dashboard/index";
import contactList from "./screens/Dashboard/index";
import contactCard from "./screens/Dashboard/index";
import taskbar from "./screens/Dashboard/index";
import blogDashboard from "./screens/Dashboard/index";
import blogPost from "./screens/Dashboard/index";
import blogList from "./screens/Dashboard/index";
import blogDetails from "./screens/Dashboard/index";
import manageRecipe from "./screens/Dashboard/index";
import documents from "./screens/Dashboard/index";
import fileMedia from "./screens/Dashboard/index";
import fileImages from "./screens/Dashboard/index";
import manageEmployee from "./screens/Dashboard/index";
import dataWidgets from "./screens/Dashboard/index";
import chartWidgets from "./screens/Dashboard/index";
import weatherWidget from "./screens/Dashboard/index";
import socialWidget from "./screens/Dashboard/index";
import blogWidgets from "./screens/Dashboard/index";
import eCommerceWidgets from "./screens/Dashboard/index";
import typographyUI from "./screens/Dashboard/index";
import tabsUI from "./screens/Dashboard/index";
import buttonsUI from "./screens/Dashboard/index";
import bootstrapUI from "./screens/Dashboard/index";
import iconsUI from "./screens/Dashboard/index";
import notificationsUI from "./screens/Dashboard/index";
import colorsUI from "./screens/Dashboard/index";
import dialogsUI from "./screens/Dashboard/index";
import listGroupUI from "./screens/Dashboard/index";
import mediaObjectUI from "./screens/Dashboard/index";
import modalsPopupsUI from "./screens/Dashboard/index";
import sortableNestableUI from "./screens/Dashboard/index";
import progressBarsUI from "./screens/Dashboard/index";
import rangeSlidersUI from "./screens/Dashboard/index";
import treeviewUI from "./screens/Dashboard/index";
import Proforma from "./screens/Dashboard/index";
// Sales Start //
import Customers from "./screens/Dashboard/index";
import Estimates from "./screens/Dashboard/index";
import Order from "./screens/Dashboard/index";
import Challans from "./screens/Dashboard/index";
import Invoices from "./screens/Dashboard/index";
import Payment from "./screens/Dashboard/index";
import Credit from "./screens/Dashboard/index";
import RecurringInvoices from "./screens/Dashboard/index";
import EstimatesNewButton from "./screens/Dashboard/index";
import DeliveryChallansNew from "./screens/Dashboard/index";
// import InvoicesNew from "../src/screens/Dashboard/SalesNew/InvoicesNew";
// Sales End //

// Purchase Starts //
import bills from "./screens/Dashboard/index";
import expenses from "./screens/Dashboard/index";
import purchaseOrder from "./screens/Dashboard/index";
import vendors from "./screens/Dashboard/index";
// shop //
import SalesPerson from "./screens/Dashboard/index";
import items from "./screens/Dashboard/index";
import manageSalesPerson from "./screens/Dashboard/index";
import manageCategory from "./screens/Dashboard/index";
import manageStock from "./screens/Dashboard/index";
import students from "./screens/Dashboard/index";
import fees from "./screens/Dashboard/index";
import razorpay from "./screens/Dashboard/index";
import manageServer from "./screens/Dashboard/index";
import manageProject from "./screens/Dashboard/index";
import managePortfolio from "./screens/Dashboard/index";
import manageProjectData from "./screens/Dashboard/index";
import timeSheetLog from "./screens/Dashboard/index";
import Setting from "./screens/Dashboard/index";
import ProformaNew from "./screens/Dashboard/index";
import profile from "./screens/Dashboard/index";
import templates from "./screens/Dashboard/index";
import taxes from "./screens/Dashboard/index";
import reports from "./screens/Dashboard/index";
import invoicedetails from "./screens/Dashboard/index"; // Adjust the import path
import paymentsMade from "./screens/Dashboard/index";
import Users from "./screens/Dashboard/index";
import CustomerNew from "./screens/Dashboard/index";
import ItemsNew from "./screens/Dashboard/index";
import InvoicesNew from "./screens/Dashboard/index";
import BankAccNew from "./screens/Dashboard/index";
import SalesOrderNewButton from "./screens/Dashboard/index";
import PaymentRecivedNew from "./screens/Dashboard/index";
import CreditNoteNew from "./screens/Dashboard/index";
import VendorsNew from "./screens/Dashboard/index";
import BillsNew from "./screens/Dashboard/index";
import PaymentsMadeNew from "./screens/Dashboard/index";
import EwayBills from "./screens/Dashboard/index";
import EwayBillsNew from "./screens/Dashboard/index";
import ExpensesNew from "./screens/Dashboard/index";
import PurchaseOrderNew from "./screens/Dashboard/index";
import RecurringInvoiceNew from "./screens/Dashboard/index";
import quotes from "./screens/Dashboard/index";
import quotesnew from "./screens/Dashboard/index";
import CRMPage from "./screens/Dashboard/index";
import QuotesDetails from "./screens/Dashboard/index";
// **********Reports Updates***********
import HorizontalBalanceSheet from "./screens/Dashboard/index";
import BalanceSheetSchedule from "./screens/Dashboard/index";
import BusinessPerfomanceRatios from "./screens/Dashboard/index";
import MovementOfEquity from "./screens/Dashboard/index";
import AgingSummary from "./screens/Dashboard/index";
import ReportsInvoiceDetails from "./screens/Dashboard/index";
import SalesOrderDetails from "./screens/Dashboard/index";
import CustomerBalanceSummary from "./screens/Dashboard/index";
import ReceivableSummary from "./screens/Dashboard/index";
import ReceivableDetails from "./screens/Dashboard/index";
import PaymentsReceived from "./screens/Dashboard/index";
import GetPaid from "./screens/Dashboard/index";
import VendorBalances from "./screens/Dashboard/index";
import VendorBalancesSummary from "./screens/Dashboard/index";
import ApAgingSummary from "./screens/Dashboard/index";
import BillsDetails from "./screens/Dashboard/index";
import PaymentsMadeReport from "./screens/Dashboard/index";
import AccountTransactions from "./screens/Dashboard/index";
import AccountTypeSummary from "./screens/Dashboard/index";
import AccountTypeTransactions from "./screens/Dashboard/index";
import DayBook from "./screens/Dashboard/index";
import ProfitAndLoss from "./screens/Dashboard/index";
import BalanceSheet from "./screens/Dashboard/index";
import ProfitAndLossShedual3 from "./screens/Dashboard/index";
import HorizontalProfit from "./screens/Dashboard/index";
import CashStatement from "./screens/Dashboard/index";
import SalesCustomer from "./screens/Dashboard/index";
import SalesItems from "./screens/Dashboard/index";
import SalesBySalesperson from "./screens/Dashboard/index";
import CustomerBalance from "./screens/Dashboard/index";
import PayableSummary from "./screens/Dashboard/index";
import PayableDetails from "./screens/Dashboard/index";
import CreditNoteDetails from "./screens/Dashboard/index";
import ItemStocks from "./screens/Dashboard/index";
import SalesByInvoice from "./screens/Dashboard/index";
import GstFilling from "./screens/Dashboard/index";
import GstR1 from "./screens/Dashboard/index";
import GstR2 from "./screens/Dashboard/index";

const AuthLoadingScreen = ({ navigation }) => {
  React.useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem("jwtToken");
      const storedLoginDate = localStorage.getItem("loginDate");

      const currentDate = new Date();
      const formattedCurrentDate = `${String(currentDate.getDate()).padStart(
        2,
        "0"
      )}-${String(currentDate.getMonth() + 1).padStart(
        2,
        "0"
      )}-${currentDate.getFullYear()}`;

      if (!token) {
        navigation.navigate("login");
      } else {
        if (storedLoginDate !== formattedCurrentDate) {
          // Refresh the dashboard page
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          // Update the login date in local storage
          localStorage.setItem("loginDate", formattedCurrentDate);
        } else {
          navigation.navigate("dashboard");
        }
      }
    };

    checkToken();
  }, [navigation]);

  return null; // You can add a loading spinner here if needed
};
window.__DEV__ = true;
const AppNavigator = createNavigator(
  SidebarView,
  SwitchRouter({
    //Start //
    // AuthLoading: AuthLoadingScreen,
    login,
    dashboard,
    vendors,
    purchaseOrder,
    expenses,
    bills,
    Customers,
    Estimates,
    Setting,
    Order,
    RecurringInvoiceNew,
    Challans,
    Invoices,
    PurchaseOrderNew,
    ItemsNew,
    InvoicesNew,
    RecurringInvoices,
    paymentsMade,
    GstR1,
    GstR2,
    Proforma,
    Payment,
    ProformaNew,
    CRMPage,
    Credit,
    BalanceSheet,
    GstFilling,
    reports,
    EwayBills,
    SalesPerson,
    EwayBillsNew,
    BillsNew,
    Users,
    ItemStocks,
    SalesByInvoice,
    PaymentsMadeNew,
    BankAccNew,
    EstimatesNewButton,
    SalesOrderNewButton,
    DeliveryChallansNew,
    PaymentRecivedNew,
    CreditNoteNew,
    VendorsNew,
    ExpensesNew,
    ProfitAndLoss,
    ProfitAndLossShedual3,
    HorizontalProfit,
    SalesCustomer,
    SalesItems,
    SalesBySalesperson,
    CustomerBalance,
    CashStatement,
    //Settings
    profile,
    templates,
    taxes,
    // End //
    students,
    quotes,
    quotesnew,
    QuotesDetails,
    fees,
    razorpay,
    demographic,
    crypto,
    eCommerce,
    bankacc,
    items,
    manageSalesPerson,
    manageCategory,
    manageStock,
    chat,
    calendar,
    contactList,
    contactCard,
    taskbar,
    blogDashboard,
    blogPost,
    blogList,
    blogDetails,
    manageRecipe,
    documents,
    fileMedia,
    fileImages,
    manageEmployee,
    manageServer,
    manageProject,
    managePortfolio,
    manageProjectData,
    timeSheetLog,
    dataWidgets,
    chartWidgets,
    weatherWidget,
    socialWidget,
    blogWidgets,
    eCommerceWidgets,
    typographyUI,
    tabsUI,
    buttonsUI,
    bootstrapUI,
    iconsUI,
    notificationsUI,
    colorsUI,
    dialogsUI,
    listGroupUI,
    mediaObjectUI,
    modalsPopupsUI,
    sortableNestableUI,
    progressBarsUI,
    rangeSlidersUI,
    treeviewUI,
    invoicedetails,
    CustomerNew,
    // **********Reports Updates***********
    HorizontalBalanceSheet,
    BalanceSheetSchedule,
    BusinessPerfomanceRatios,
    MovementOfEquity,
    AgingSummary,
    ReportsInvoiceDetails,
    SalesOrderDetails,
    CustomerBalanceSummary,
    ReceivableSummary,
    ReceivableDetails,
    PaymentsReceived,
    GetPaid,
    VendorBalances,
    VendorBalancesSummary,
    ApAgingSummary,
    BillsDetails,
    PaymentsMadeReport,
    AccountTransactions,
    AccountTypeSummary,
    AccountTypeTransactions,
    DayBook,
    PayableSummary,
    PayableDetails,
    CreditNoteDetails,
    // *******************
  }),
  {
    initialRouteName: "login",
  }
);

const App = createBrowserApp(AppNavigator);

export default App;
