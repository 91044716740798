import React, { useState, useEffect } from "react";
import { Row, Col, Card, Modal } from "react-bootstrap";
import Select from "react-select";
import "./popup.css";
import { addDays } from "date-fns";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Alert from "@mui/material/Alert";
import TinyMceEditor from "../Settings/TinyMceEditor";

function DeliveryChallansNew({ navigation }) {
  const encryptedUniqueId = localStorage.getItem("uniqueId1");

  // Decrypt the uniqueId using your decryption method
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [row, setRow] = useState([
    {
      itemDetails: "",
      quantity: "1",
      rate: "0.0",
      discount: "0.0",
      discountType: "₹",
      tax: "",
      description1: "",
      description2: "",
    },
  ]);

  const jwtToken = localStorage.getItem("jwtToken");
  const [showChallanPage, setShowChallanPage] = useState(true);
  const [showMailPage, setShowMailPage] = useState(false);
  const [userDetail, setUserDetail] = useState("");
  const [userEmail, setUserEmail] = useState([]);
  const [custEmail, setCustomerEmail] = useState([]);
  const [subjectMail, setSubjectMail] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [manualCheck, setManualCheck] = useState("No");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);

  const handleSubjectChange = (event) => {
    setSubjectMail(event.target.value);
  };

  const handleUserEmail = (event) => {
    setUserEmail(event.target.value);
  };

  const handleCustomerEmail = (event) => {
    setCustomerEmail(event.target.value);
  };
  const handleMailClick = () => {
    setShowChallanPage(false);
    setShowMailPage(true);
  };

  const handleAdd = () => {
    const newItem = {
      itemDetails: "",
      quantity: "1",
      rate: "0.0",
      discount: "0.0",
      discountType: "₹",
      tax: "",
    };
    const newRow = [...row, newItem];
    setRow(newRow);
  };

  const handleDeleteRow = (i) => {
    const updatedRow = [...row];
    updatedRow.splice(i, 1);
    setRow(updatedRow);
    updateTotal(updatedRow);
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      handleChallansClick();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const [customerFormData, setCustomerFormData] = useState({
    uniqueId: decryptedUniqueId,
    customerType: "Business",
    status: "Active",
    someName: "Mr.",
    firstName: "",
    lastName: "",
    companyName: "",
    customerName: "",
    email: "",
    workphone: "",
    mobile: "",
    designation: "",
    department: "",
    website: "",
    currency: "INR- Indian Rupee",
    openingBalance: "0",
    paymentTerms: "",
    facebook: "",
    twitter: "",
    billAttention: "",
    billCountry: "India",
    billAddress1: "",
    billAddress2: "",
    billCity: "",
    billState: "Maharashtra",
    billZipcode: "",
    billPhone: "",
    billFax: "",
    shipAttention: "",
    shipCountry: "India",
    shipAddress1: "",
    shipAddress2: "",
    shipCity: "",
    shipState: "Maharashtra",
    shipZipcode: "",
    shipPhone: "",
    shipFax: "",
    gstin: "",
    pan: "",
    placeOfSupply: "[MH] - Maharashtra",
    taxable: "",
    reason: "N/A",
    activeStatus: "Active",
    gstTreatment: "",
    contactPerson: [
      {
        other: "",
        otherFirstname: "",
        otherLastname: "",
        otherEmail: "",
        otherWorkphone: "",
        otherMobile: "",
      },
    ],
  });

  const [show1, setShow1] = useState(false);
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleChallansClick = () => {
    handleNavigation("Challans");
  };

  const handleDiscountChange = (e, i) => {
    const { name, value } = e.target;
    const newValues =
      name === "discount" ? { discount: value } : { discountType: value };
    const newRow = [...row];
    newRow[i] = { ...newRow[i], ...newValues };
    setRow(newRow);
    updateTotal(newRow);
  };

  const handleQuantityChange = (e, i) => {
    const newQuantity = e.target.value;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], quantity: newQuantity };
    setRow(newRow);
    updateTotal(row);
  };

  const handleHsnSacChange = (e, i) => {
    const { value } = e.target;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], hsnSac: value };
    setRow(newRow);
    updateTotal(row);
  };

  const handleSizeChange = (e, i) => {
    const { value } = e.target;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], size: value };
    setRow(newRow);
    updateTotal(row);
  };

  const handleRateChange = (e, i) => {
    const newRate = e.target.value;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], rate: newRate };
    setRow(newRow);
    updateTotal(row);
  };

  const updateTotal = (rowData) => {
    let calculatedTotal = 0;
    rowData.forEach((data) => {
      const amount = calculateAmount(data);
      calculatedTotal += parseFloat(amount);
    });
    setTotal(calculatedTotal.toFixed(2));
  };
  const [total, setTotal] = useState(0);

  const calculateAmount = (data, tax) => {
    const { quantity, rate, discount, discountType } = data;
    let amount = quantity * rate;

    if (discount) {
      amount -= discountType === "%" ? (amount * discount) / 100 : discount;
    }

    return amount;
  };

  const [selectedTaxRate, setSelectedTaxRate] = useState(0);
  const [discountCheck, setDiscountCheck] = useState("");
  const [disAllValue, setDisAllValue] = useState("");
  const [discountType, setDiscountType] = useState("₹");
  const [disValue, setDisValue] = useState("");

  const calculateDiscount = () => {
    const amount = parseFloat(calculateSubTotal()).toFixed(2);

    if (discountType === "₹") {
      setDisValue(disAllValue);
    } else if (discountType === "%") {
      const percentageDiscount = (amount * disAllValue) / 100;
      setDisValue(percentageDiscount.toFixed(2));
    }
  };

  const handleDiscountTypeChange = (e) => {
    setDiscountType(e.target.value);
  };

  const handleDisAllValueChange = (e) => {
    setDisAllValue(e.target.value);
  };
  useEffect(() => {
    calculateDiscount();
  }, [disAllValue, discountType]);

  const calculateSubTotal = () => {
    let subTotal = 0;
    row.forEach((data) => {
      const amount = calculateAmount(data);
      subTotal += parseFloat(amount);
    });
    return subTotal.toFixed(2);
  };

  const handleTaxChange = (e, index) => {
    setSelectedTaxRate(e.target.value);
    const newTaxRate = parseFloat(e.target.value);
    const updatedRow = [...row];
    updatedRow[index].tax = newTaxRate;
    setRow(updatedRow);
  };

  const calculateIGST = () => {
    let totalIGST = 0;

    row.forEach((data) => {
      const taxRate = parseFloat(data.tax || 0);
      if (taxRate > 0) {
        const amount = calculateAmount(data, taxRate);
        const increasedTaxAmount = (parseFloat(amount) * taxRate) / 100;
        totalIGST += increasedTaxAmount;
      }
    });

    return totalIGST.toFixed(2);
  };

  const calculateSGSTandCGST = () => {
    let totalSGST = 0;
    let totalCGST = 0;
    row.forEach((data) => {
      const taxRate = parseFloat(data.tax || 0);
      const amount = calculateAmount(data, taxRate);
      const increasedTaxAmount = (parseFloat(amount) * taxRate) / 100;
      const sgst = increasedTaxAmount / 2;
      const cgst = increasedTaxAmount / 2;
      totalSGST += sgst;
      totalCGST += cgst;
    });
    return {
      totalSGST: totalSGST.toFixed(2),
      totalCGST: totalCGST.toFixed(2),
    };
  };

  const calculateIGSTForTax = () => {
    const taxResults = [];

    row.forEach((data) => {
      const taxRate = parseFloat(data.tax / 100 || 0);
      if (taxRate > 0) {
        let existingTaxResult = taxResults.find(
          (result) => result.taxRate === taxRate
        );
        if (!existingTaxResult) {
          existingTaxResult = { taxRate, totalIGST: 0 };
          taxResults.push(existingTaxResult);
        }
        const amount = calculateAmount(data, taxRate);
        const increasedTaxAmount = amount * taxRate;
        existingTaxResult.totalIGST += increasedTaxAmount;
      }
    });
    return taxResults;
  };

  const calculateGroupedSGSTandCGST = () => {
    const taxGroups = row.reduce((acc, data) => {
      const taxRate = parseFloat(data.tax / 100 || 0);
      if (taxRate === 0) return acc; // Skip 0% GST

      const amount = calculateAmount(data, taxRate);
      const increasedTaxAmount = amount * taxRate;
      const sgst = increasedTaxAmount / 2;
      const cgst = increasedTaxAmount / 2;

      if (!acc[taxRate]) {
        acc[taxRate] = { totalSGST: 0, totalCGST: 0 };
      }

      acc[taxRate].totalSGST += sgst;
      acc[taxRate].totalCGST += cgst;

      return acc;
    }, {});

    return taxGroups;
  };

  const [adjustmentValue, setAdjustmentValue] = useState(""); // Step 1: State for adjustment value
  const [oppositeValue, setOppositeValue] = useState(""); // State for opposite value

  useEffect(() => {
    const parsedValue = parseFloat(adjustmentValue);
    if (!isNaN(parsedValue)) {
      const opposite = parsedValue * 1;
      setOppositeValue(opposite.toFixed(2));
    } else {
      // If adjustmentValue is not a valid number, set the opposite value to an empty string
      setOppositeValue("");
    }
  }, [adjustmentValue]);

  const calculateTotal = () => {
    const subTotal = parseFloat(calculateSubTotal());
    const igst = parseFloat(calculateIGST());
    const adjustment = parseFloat(adjustmentValue) || 0; // Use the adjustment value if it's a valid number, otherwise, default to 0
    const total1 = subTotal + igst + adjustment;
    const total = total1 - disValue;
    return total.toFixed(2);
  };

  const [selectedTerms, setSelectedTerms] = useState("");

  const [deliveryChallan, setDeliveryChallan] = useState("");
  const [reference, setReference] = useState("");
  const [subject, setSubject] = useState("");
  const [adjustmentLabel, setAdjustmentLabel] = useState("");
  const [customerNotes, setCustomerNotes] = useState("");
  const [termsAndCondition, setTermsAndCondition] = useState("");
  const [deliveyNumberPrefix, setdeliveyNumberPrefix] = useState([]);
  const [deliveyNumberNext, setdeliveyNumberNext] = useState([]);

  const [userState, setUserState] = useState([]);
  const [customerState, setCustomerState] = useState("");

  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const userId = localStorage.getItem("userId");

  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const userState = data.responseMessage;
          setUserDetail(data.responseMessage);
          setUserEmail(data.responseMessage.email);

          setUserState(userState);
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  useEffect(() => {
    fetchData();
    fetchTermsData();
  }, []);

  const [validationMessage1, setValidationMessage1] = useState("");

  const insertDataIntoDatabase = async () => {
    const itemDetails = row.map((data) => {
      return {
        itemDetails: data.itemDetails || "",
        hsnSac: data.hsnSac,
        size: data.size,
        quantity: data.quantity,
        rate: data.rate || 0,
        discount: data.discount || 0,
        discountType: data.discountType,
        tax: data.tax || 0,
        amount: calculateAmount(data, selectedTaxRate),
        description1: data.description1 || "",
        description2: data.description2 || "",
        customerTaxType: userState.state === customerState ? 1 : 2,
        cgst:
          userState.state === customerState
            ? (calculateAmount(data, selectedTaxRate) * data.tax) / 100 / 2
            : 0,
        sgst:
          userState.state === customerState
            ? (calculateAmount(data, selectedTaxRate) * data.tax) / 100 / 2
            : 0,
        igst:
          userState.state !== customerState
            ? (calculateAmount(data, selectedTaxRate) * data.tax) / 100
            : 0,
      };
    });
    const customerFormData = {
      uniqueId: decryptedUniqueId,
      userId: userId,
      customerName: selectedCustomer,
      customerId: selectedCustomerId,
      deliveryChallan: deliveryChallan,
      deliveryChallanDate: dateValue || normalDateFormat(startDate),
      terms: selectedTerms,
      reference: reference,
      challanType: selectedTerms,
      items: itemDetails,
      subtotal: calculateSubTotal(),
      adjustment: parseFloat(adjustmentValue) || 0,
      adjustmentLabel: adjustmentLabel,
      total: calculateTotal(),
      customerNotes: customerNotes,
      termsAndConditions: termsAndCondition,
      totalAmount: calculateTotal(),
      overallDiscount: disAllValue || 0,
      taxCheck: bussinessRegistered || 0,
      totalDiscount: disValue || 0,
      totalDiscountType: discountType || 0,
      status: "Sent",
      manualCheck: manualCheck,
      state: customerState,
      hsnCheck: discountCheck.hsnCheck,
      sizeCheck: discountCheck.sizeCheck,
      discountCheck: discountCheck.discountCheck,
      gstNumber: gstNumber,
    };

    let newValidationMessage = "";
    if (!customerFormData.customerName) {
      newValidationMessage += "Please select a customer \n";
    } else if (!customerFormData.deliveryChallan) {
      newValidationMessage += "Please enter challan number.\n";
    } else if (!customerFormData.deliveryChallanDate) {
      newValidationMessage += "Please select a challan date \n";
    }
    row.forEach((item, index) => {
      if (
        item.itemDetails.trim() === "" ||
        item.rate.trim() === "" ||
        item.rate.trim() === "0.0" ||
        item.quantity.trim() === ""
      ) {
        newValidationMessage +=
          "Enter the valid item name, rate and quantity.\n";
      }
    });

    setValidationMessage1(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }
    setIsLoading(true);
    const response = await fetch(BASE_URL + `/insertDeliveryChallan`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(customerFormData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Delivery Challan Created",
          });
          handleMailClick();
          setIsLoading(false);
        } else if (data.status === "error") {
          swal({
            icon: "error",
            title: data.responseMessage,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.message,
        });
        setIsLoading(false);
      });
  };

  const copyAdd = () => {
    if (show1) {
      // If auto-generating is selected, set the generatedInvoiceNumber
      setDeliveryChallan(deliveyNumberPrefix + deliveyNumberNext);
      setManualCheck("No");
    } else {
      // If manually adding is selected, set it to null
      setDeliveryChallan("");
      setManualCheck("Yes");
    }
  };
  const handleChallanNumberChange = (e) => {
    setDeliveryChallan(e.target.value);
    setManualCheck("Yes");
  };
  const setBill = () => {
    setShow1(true);
    fetchBill();
  };

  const fetchBill = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    // Prepare the data to send in the POST request
    const requestData = {
      // Include any other data you need to send in the request
      uniqueId: decryptedUniqueId,
      type: "deliverychallan",
    };

    // Define the POST request options
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
        // Add any other headers if required
      },
      body: JSON.stringify(requestData), // Convert the data to JSON format
    };

    // Make the POST request to your API endpoint
    fetch(BASE_URL + `/getCurruntNumberList2`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setdeliveyNumberPrefix(data.responseMessage.prefix);
        setdeliveyNumberNext(data.responseMessage.numbers);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }; // Empty dependency array to fetch data once when the component mounts

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/getCurruntNumberList`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        type: "deliverychallan",
        uniqueId: decryptedUniqueId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setDeliveryChallan(data.responseMessage);
        } else {
          console.error("Failed to fetch numberList:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Number List:", error);
      });
  }, []);

  const [custNames, setCustNames] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState(""); // State to store the selected customer's ID

  // Fetch customer data
  const fetchAllCustomers = () => {
    fetch(BASE_URL + `/getAllCustomerName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setCustNames(data.responseMessage);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };
  useEffect(() => {
    fetchAllCustomers();
  }, []);

  // Handle customer selection
  const handleCustomerChange = (value, id) => {
    const selectedValue = value;
    const selectedValueId = id;
    setSelectedCustomer(selectedValue);
    setSelectedCustomerId(selectedValueId);

    // Find the selected customer object in the fetched data
    const selectedCustomerObject = custNames.find(
      (person) => person.customerName === selectedValue
    );

    if (selectedCustomerObject) {
      const customerState = selectedCustomerObject.billState;
      const custEmail = selectedCustomerObject.email;
      setCustomerEmail(custEmail);
      setCustomerState(customerState);
      setSelectedCustomerId(selectedCustomerObject.id);
    } else {
      console.log("Selected Customer: (Not found)");
    }
  };
  const [data1, setData1] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState([]);
  const [selectedData, setSelectedData] = useState([]); // Initialize as an empty array
  const [bussinessRegistered, setBussinessRegistered] = useState("");
  const [gstNumber, setGstNumber] = useState("");

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/getAllItemData`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        type: "deliverychallan",
        uniqueId: decryptedUniqueId,
        vendorId: "",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setData1(data.responseMessage);
        } else {
          console.error("Failed to fetch numberList:", data.responseMessage);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Number List:", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchGst`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const Data = data.responseMessage[0].businessRegistered;
          setBussinessRegistered(Data);
          setGstNumber(data.responseMessage[0].gstin);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  const [showSalesModal, setShowSalesModal] = useState(false);
  const handleSalesClose = () => setShowSalesModal(false);
  const handleSalesShow = () => setShowSalesModal(true);

  const [showItemModal, setShowItemModal] = useState(false);
  const [itemName, setItemName] = useState("");

  const [itemData, setItemData] = useState({
    uniqueId: decryptedUniqueId,
    description: "",
  });

  const handleItemChange = (event) => {
    const { name, value } = event.target;
    setItemData({ ...itemData, [name]: value });
  };

  const handleItemClose = () => {
    setShowItemModal(false);
    setItemData({
      ...itemData,
      description: "",
    });
  };

  const [itemDescriptions, setItemDescriptions] = useState([]);

  const handleItem = (event) => {
    event.preventDefault();

    const newItemDescription = {
      itemDetails: itemName,
      description: itemData.description,
    };

    setItemDescriptions((prevDescriptions) => {
      const existingIndex = prevDescriptions.findIndex(
        (desc) => desc.itemDetails === itemName
      );

      if (existingIndex !== -1) {
        const updatedDescriptions = [...prevDescriptions];
        updatedDescriptions[existingIndex] = newItemDescription;
        return updatedDescriptions;
      } else {
        return [...prevDescriptions, newItemDescription];
      }
    });

    setRow((prevRows) => {
      return prevRows.map((row) =>
        row.itemDetails === itemName
          ? { ...row, description2: itemData.description }
          : row
      );
    });

    setShowItemModal(false);
  };

  const handleItemShow = (itemDetails) => {
    const itemDescription = itemDescriptions.find(
      (desc) => desc.itemDetails === itemDetails
    );
    if (itemDescription) {
      setItemData({ description: itemDescription.description });
    } else {
      setItemData({ description: "" });
    }
    setItemName(itemDetails);
    setShowItemModal(true);
  };

  const [dropdownData, setDropdownData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const fetchItemData = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const jwtToken = localStorage.getItem("jwtToken");

    const postApiUrl = BASE_URL + `/getAllItemData`;

    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, vendorId: "" }),
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = data.responseMessage;
        setDropdownData(responseData.map((item) => item));
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  useEffect(() => {
    fetchItemData();
  }, []);

  const [searchIndex, setSearchIndex] = useState(0);

  useEffect(() => {
    const results = dropdownData.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm, dropdownData]);

  const handleSearchChange = (e, index) => {
    const value = e.target.value;
    const newRow = [...row];
    newRow[index].itemDetails = value;
    setSearchTerm(value);
    setSearchPerformed(value !== "");
    setRow(newRow);
    setSearchIndex(index);
  };

  const handleItemDetailsChange = (e, index) => {
    const value = e.target.value;
    const newRow = [...row];
    newRow[index].itemDetails = value;
    setRow(newRow);
    setSearchTerm(value);
    setSearchPerformed(value !== "");
    setSearchIndex(index);
    if (value) {
      fetchItemRate(value, index);
    } else {
      handleSearchChange(value, index);
    }
  };

  const handleItemSelect = (selectedItem, selectedItemId, index) => {
    setSearchResults([]);
    const newRow = [...row];
    newRow[index].itemDetails = selectedItem;
    setRow(newRow);
    fetchItemRate(selectedItemId, index);
  };

  const fetchItemRate = (itemId, index) => {
    const jwtToken = localStorage.getItem("jwtToken");
    const getApiUrl = BASE_URL + `/getItemData/${itemId}`;

    fetch(getApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const rate = data.data.taxableAmount;
        const hsnSac = data.data.hsnCode;
        const description1 = data.data.description;
        const itemName = data.data.name;
        const tax = data.data.intraStateTaxRate;
        const updatedRow = [...row];
        updatedRow[index].rate = rate;
        updatedRow[index].hsnSac = hsnSac;
        updatedRow[index].description1 = description1;
        updatedRow[index].itemDetails = itemName;
        updatedRow[index].tax = tax;
        setRow(updatedRow);
        setSearchResults([]);
        handleAdd();
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    fetch(BASE_URL + `/fetchDiscountCheck`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const Data = data.responseMessage;
          setDiscountCheck(Data);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    setStartDate(new Date());
  }, []);

  const [dateValue, setDateValue] = useState("");

  const handleDate = (date) => {
    setStartDate(date);
    let normalFormat = date === null ? "" : normalDateFormat(date);
    setDateValue(normalFormat);
  };

  const normalDateFormat = (date) => {
    if (date) {
      return (
        ("0" + date.getDate()).slice(-2) +
        "-" +
        ("0" + (Number(date.getMonth()) + 1)).slice(-2) +
        "-" +
        date.getFullYear()
      );
    }
    return date;
  };

  const optionsCustomers = custNames.map((customer) => ({
    value: customer.id,
    label: customer.customerName,
  }));

  const optionsChallanType = [
    { value: "Supply Of Liquid Gas", label: "Supply Of Liquid Gas" },
    { value: "Job work", label: "Job work" },
    { value: "Supply On Approval", label: "Supply On Approval" },
    { value: "Other", label: "Other" },
  ];

  const handleChallanType = (selectedOption) => {
    setSelectedTerms(selectedOption.value);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [validationMessage, setValidationMessage] = useState("");

  const handleSaveClick = () => {
    let newValidationMessage = "";

    if (!customerFormData.customerName) {
      newValidationMessage += "Please enter a customer display name.\n";
    } else if (
      customerFormData.email &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(customerFormData.email)
    ) {
      newValidationMessage += "Please enter a valid email address.\n";
    }

    setValidationMessage(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      // alert(newValidationMessage.trim()); // Trim trailing newline for better formatting
      return; // Exit function if validation fails
    }
    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + "/createCustomer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(customerFormData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          swal({
            icon: "success",
            title: "Customer Created",
            text: "",
          });
          setShow(false);
          fetchAllCustomers();
        } else if (data.responseCode === "400") {
          swal({
            icon: "error",
            title: "This email already exists, please insert another email.",
            text: "",
          });
          setShow(true);
        } else {
          swal({
            icon: "error",
            title: "Failed to Create Customer!",
            text: "",
          });
          setShow(true);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Network Error",
          text: "An error occurred while communicating with the server.",
        });
        setShow(true);
      });
  };
  const handleChange = (event) => {
    // setSelectedOption(event.target.value);
    const { name, value } = event.target;
    setCustomerFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const generateNamePairs = () => {
    const { someName, firstName, lastName, companyName } = customerFormData;
    const pairs = [];

    if (firstName && lastName) {
      pairs.push(`${someName} ${firstName} ${lastName}`);
      pairs.push(`${firstName} ${lastName}`);
      pairs.push(`${lastName} , ${firstName}`);
      pairs.push(`${companyName}`);
    }

    return pairs;
  };

  const initialContent = `
  <div class="main-container" style="margin: 10px; padding: 10px; text-align: center; background-color: #4190f2;">
  <h2 style="margin: 10px; padding: 10px; text-align: center;">${deliveryChallan}</h2>
</div>

<div class="content-container">
<h4 style="margin: 20px; padding: 20px;"> Dear  ${selectedCustomer},</h4>
  <p style="margin: 20px; padding: 20px;" class="my-custom-paragraph">Thank you for your business. Your delivery challan can be viewed, printed and downloaded as PDF from the link below. You can also choose to pay it online.</p>
  <div class="card" style="border: 1px solid #e8deb5; border-radius: 3px; padding: 26px; margin: 26px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); width: 50%; margin: 0 auto;  background-color: #fefff1;">
      <h4 style="margin: 5px; padding: 5px; text-align: center;">DELIVERY CHALLAN AMOUNT</h4>
      <h4 style=" text-align: center;">₹ ${calculateTotal()}</h4>
      <table style="width: 100%; text-align: center;">
      <tr>
          <td>Dlivery Challan No</td>
          <td>${deliveryChallan}</td>
      </tr>
      <tr>
          <td>Delivery Challan Date</td>
          <td>${dateValue || normalDateFormat(startDate)}</td>
      </tr>
     

     
  
  </table>
  <div style="text-align: center; padding-top:5px;">
  </div>

  </div>

    <div style=" padding: 10px; width: 80%; margin: 0 auto;">
      <h4>Regards</h3>
      <p>${userDetail.companyName}</p>
    </div>
</div>`;

  const handleMailSubmit = async () => {
    const itemDetails = row.map((data) => {
      return {
        items: data.itemDetails || "",
        quantity: data.quantity || "",
        rate: data.rate || "",
        discount: data.discount || "",
        discountType: data.discountType || "",
        // tax: data.tax || "", // Assuming the tax rate is the same for all items
        amount: calculateAmount(data, selectedTaxRate),
      };
    });
    const postData = {
      to: custEmail,
      from: userEmail,
      subject: subject,
      body: editorContent,
      total: calculateTotal(),
      deliveryChallanNumber: deliveryChallan,
      deliveryChallanDate: dateValue || normalDateFormat(startDate),
      payButtonNow: "Pay Now",
      organizationName: userDetail.companyName,
      customerName: selectedCustomer,
      companyName: userDetail.companyName,
      terms: "Terms",
      items: itemDetails,

      // name: bankData.name,
      // bank: bankData.bankName,
      // accountNo: bankData.accountNumber,
      // ifscCode: bankData.ifsc,
      // type: bankData.accountType,
      // totalInWords: "One Thousand Dollars",
      subtotal: calculateSubTotal(),
      adjustment: parseFloat(adjustmentValue) || 0,
      adjustmentLabel: adjustmentLabel,
      totalDue: calculateTotal(),
      notes: customerNotes,
      state: customerState,
      address: userDetail.address1,
      imageName: "kunal.PNG",
      uniqueId: decryptedUniqueId,
    };
    setIsLoading3(true);
    const response = await fetch(BASE_URL + "/send-delivery-email-with-pdf", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Mail Send Successfully",
          });
          handleChallansClick();
          setIsLoading3(false);
        } else if (data.status === "error") {
          swal({
            icon: "error",
            title: data.responseMessage,
          });
          setIsLoading3(false);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.message,
        });
        setIsLoading3(false);
      });
  };

  const fetchTermsData = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const jwtToken = localStorage.getItem("jwtToken");

    const postApiUrl = BASE_URL + `/fetchTermsAndConditions`;

    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = data.responseMessage;
        // setDropdownData(responseData.map((item) => item));
        setTermsAndCondition(responseData.termsAndCondition);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };
  return (
    <>
      {showChallanPage && (
        <>
          <div className="my-3">
            <div className="d-flex align-items-center ml-1 mt-4">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIosIcon
                  width={2}
                  onClick={handleCancleClick}
                  className="arrow-child"
                />
              </div>
              <div className="headitems ml-2">
                <h5
                  className="ml-1 mb-0"
                  style={{ letterSpacing: "1px", color: "#086bd5de" }}
                >
                  New Delivery Challan
                </h5>
              </div>
            </div>
          </div>
          {validationMessage1 && (
            <Alert severity="error" className="mt-0">
              {validationMessage1.split("\n").map((message, index) => (
                <div key={index}>{message}</div>
              ))}
            </Alert>
          )}{" "}
          <div className="card border-2 mt-3">
            <div className="pl-4 pr-4">
              <div className="row pt-3">
                <div className="salespersonbox">
                  <div className="text-style">
                    Customer Name <span className="valid"> *</span>
                  </div>

                  <Select
                    options={optionsCustomers}
                    value={optionsCustomers.find(
                      (option) => option.value === selectedCustomer
                    )}
                    onChange={(selectedOption) =>
                      handleCustomerChange(
                        selectedOption.label,
                        selectedOption.value
                      )
                    }
                    placeholder="Select Customer"
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        marginTop: "10px",
                        width: "250px",
                      }),
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "#F6F6F6",
                        borderRadius: "7px",
                        height: "46px",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        height: "19px",
                      }),
                    }}
                  />
                  <button type="button" class="btn p-0" onClick={handleShow}>
                    <span
                      class="text-primary"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      New Customer
                    </span>
                  </button>

                  <Modal size="lg" show={show} onHide={handleClose}>
                    <div class="modal-header d-flex align-items-center pb-2 pt-2">
                      <h5 class="modal-title fs-5" id="newcustomerPopup">
                        New Customer
                      </h5>
                      <button
                        type="button"
                        className="btn pr-0"
                        onClick={handleClose}
                      >
                        <ion-icon
                          name="close-circle-outline"
                          size="large"
                          style={{ width: "28px" }}
                        ></ion-icon>
                      </button>
                    </div>
                    <Modal.Body className="p-0 pb-3">
                      {validationMessage && (
                        <Alert severity="error" className="mt-0">
                          {validationMessage
                            .split("\n")
                            .map((message, index) => (
                              <div key={index}>{message}</div>
                            ))}
                        </Alert>
                      )}{" "}
                      <div className="container">
                        <div className="row d-inline-flex">
                          <div className="modalinputchild">
                            <div className="text-style"> Salutation </div>
                            <select
                              className="form-control"
                              name="someName"
                              value={customerFormData.someName}
                              onChange={handleChange}
                              style={{
                                borderRadius: "9px",
                                backgroundColor: "#f5f5f5",
                                width: "200px",
                                height: "35px",
                                fontSize: "13px",
                                marginTop: "10px",
                              }}
                            >
                              <option value="Mr.">Mr.</option>
                              <option value="Mrs.">Mrs.</option>
                              <option value="Ms.">Ms.</option>
                              <option value="Miss.">Miss.</option>
                              <option value="Dr.">Dr.</option>
                            </select>
                          </div>
                          <div className="modalinputchild">
                            <div className="text-style">First Name</div>
                            <input
                              className="form-control"
                              placeholder="First Name"
                              name="firstName"
                              id="firstName"
                              value={customerFormData.firstName}
                              onChange={handleChange}
                              type="text"
                            />
                          </div>
                          <div className="modalinputchild">
                            <div className="text-style">Last Name</div>
                            <input
                              className="form-control"
                              placeholder="Last Name"
                              id="lastName"
                              name="lastName"
                              value={customerFormData.lastName}
                              onChange={handleChange}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="row d-inline-flex pt-2">
                          <div className="modalinputchild">
                            <div className="text-style"> Customer Email</div>
                            <input
                              className="form-control"
                              placeholder="Customer Email"
                              name="email"
                              type="text"
                              value={customerFormData.email}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="modalinputchild">
                            <div className="text-style">
                              {" "}
                              Customer Display Name
                              <span style={{ color: "red" }}> *</span>
                            </div>
                            <input
                              className="form-control"
                              placeholder="Customer Display Name"
                              list="customerNames"
                              name="customerName"
                              value={customerFormData.customerName}
                              onChange={handleChange}
                              style={{
                                borderRadius: "7px",
                                backgroundColor: "#f5f5f5",
                                width: "200px",
                                height: "40px",
                                fontSize: "13px",
                                marginTop: "10px",
                              }}
                            />
                            <datalist id="customerNames">
                              {generateNamePairs().map((pair, index) => (
                                <option key={index} value={pair} />
                              ))}
                            </datalist>{" "}
                          </div>
                          <div className="modalinputchild">
                            <div className="text-style">Company Name</div>
                            <input
                              className="form-control"
                              placeholder="Add company name"
                              name="companyName"
                              type="text"
                              value={customerFormData.companyName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="row d-inline-flex pt-2">
                          <div className="modalinputchild">
                            <div className="text-style">Customer Phone</div>
                            <input
                              className="form-control"
                              placeholder="Work Phone"
                              name="workphone"
                              type="text"
                              value={customerFormData.workphone}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="modalinputchild">
                            <div className="text-style">Mobile</div>
                            <input
                              className="form-control"
                              placeholder="Mobile"
                              name="mobile"
                              type="text"
                              value={customerFormData.mobile}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer className="p-2">
                      <div className="modal-bottombtns">
                        <button
                          type="button"
                          class="modalbtn-1 btn mt-0"
                          onClick={handleClose}
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="modalbtn-3 btn btn-primary"
                          onClick={handleSaveClick}
                        >
                          Create Customer
                        </button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>
                {/* <div className="my-component pl-0">
              <button className="popup-button" onClick={openPopup}>
                + New Coustomer
              </button>
            </div> */}

                <div className="inputchild">
                  <div className="text-style">
                    Delivery Challan <span className="valid"> *</span>
                  </div>
                  <input
                    className="form-control"
                    placeholder="Delivery Challan"
                    id=""
                    name="deliveryChallan"
                    value={deliveryChallan}
                    onChange={handleChallanNumberChange}
                    type="text"
                    style={{
                      padding: "12px",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "7px",
                      color: "black",
                    }}
                  />
                  <button
                    type="button"
                    className="btn p-0"
                    data-bs-toggle="modal"
                    data-bs-target="#generatePop"
                  >
                    <span
                      className="text-primary"
                      style={{ fontSize: "12px", fontWeight: "500" }}
                    >
                      Generate
                    </span>
                  </button>

                  {/* Invoice generate popup*/}
                  <div
                    className="modal fade"
                    id="generatePop"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header d-flex align-items-center pb-2 pt-2">
                          <h5
                            className="modal-title fs-5"
                            id="generateinvoiceHead"
                          >
                            Configure Delivery Challan# Preferences
                          </h5>
                          <button
                            type="button"
                            className="btn pr-0"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <ion-icon
                              name="close-circle-outline"
                              size="large"
                              style={{ width: "28px" }}
                            ></ion-icon>
                          </button>
                        </div>
                        <div className="modal-body pt-1 pb-1">
                          <div className="popupinvoice-container ml-2 mt-2 mr-2 mb-0">
                            <div className="row headline">
                              <div className="customer-headline-label">
                                <label className="form-label">
                                  <span className="headline">
                                    Your delivery challan numbers are set on
                                    auto-generate mode to <br />
                                    save your time. Are you sure about changing
                                    this setting?
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <label className="customer-rdiobox-label d-flex align-items-center">
                                <input
                                  className="mr-2"
                                  type="radio"
                                  name="cus_type"
                                  id="cus_bus"
                                  value="Business"
                                  onClick={setBill}
                                />
                                <span className="customer-option">
                                  Continue auto-generating delivery challan
                                  numbers{" "}
                                </span>
                              </label>
                            </div>
                            {show1 && (
                              <div className="row ml-3 mb-3">
                                <div className="customer-generate-inputfield">
                                  <span>Prefix</span>
                                  <input
                                    className="form-control mt-1"
                                    placeholder="Prefix"
                                    value={deliveyNumberPrefix}
                                    onChange={(e) =>
                                      setdeliveyNumberPrefix(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="customer-generate-inputfield2">
                                  <span>Next Number</span>
                                  <input
                                    className="form-control mt-1"
                                    placeholder="Next Number"
                                    value={deliveyNumberNext}
                                    onChange={(e) =>
                                      setdeliveyNumberNext(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            <div className="row mt-1">
                              <label className="customer-rdiobox-label d-flex align-items-center">
                                <input
                                  className="mr-2"
                                  type="radio"
                                  name="cus_type"
                                  id="cus_bus"
                                  value="Business"
                                  onClick={() => {
                                    setShow1(false);
                                  }}
                                />
                                <span className="customer-option">
                                  Enter delivery challan numbers manually
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <div className="modal-bottombtns">
                            <button
                              type="button"
                              class="modalbtn-1 btn mt-0"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              class="modalbtn-3 btn btn-primary"
                              onClick={() => {
                                copyAdd();
                              }}
                              data-bs-dismiss="modal"
                            >
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="inputchild">
                  <div className="text-style">Reference</div>
                  <input
                    className="form-control"
                    placeholder="Reference"
                    name="reference"
                    value={reference}
                    onChange={(e) => setReference(e.target.value)}
                    type="text"
                    style={{
                      padding: "12px",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "7px",
                      color: "black",
                    }}
                  />
                </div>
              </div>

              <div className="row pt-2">
                <div className="inputchild">
                  <div className="text-style">
                    Delivery Challan Date <span className="valid"> *</span>
                  </div>
                  <div className="customDatePickerWidth">
                    <DatePicker
                      className="form-control"
                      selected={startDate}
                      dateFormat="dd-MM-yyyy"
                      onChange={(date) => handleDate(date)}
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",
                        borderRadius: "7px",
                        color: "black",
                      }}
                    />
                  </div>
                </div>
                <div className="salespersonbox">
                  <div className="text-style">Challan Type</div>
                  {/* <select
                className="form-control"
                value={selectedTerms}
                onChange={(e) => {
                  setSelectedTerms(e.target.value);
                }}
              >
                <option value="">Select Challan Type</option>
                <option value="Supply Of Liquid Gas">Supply Of Liquid Gas</option>
                <option value="Job work">Job work</option>
                <option value="Supply On Approval">Supply On Approval</option>
                <option value="Other">Other</option>
              </select> */}
                  <Select
                    // className="dropdown-field"
                    options={optionsChallanType}
                    value={optionsChallanType.find(
                      (option) => option.value === selectedTerms
                    )}
                    onChange={handleChallanType}
                    placeholder="Select a Challan Type"
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        marginTop: "10px",
                        width: "250px",
                      }),
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "#F6F6F6",
                        borderRadius: "7px",
                        height: "46px",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        height: "19px",
                      }),
                    }}
                  />
                </div>
              </div>

              <>
                <Row className="mt-4">
                  <Col md="3">
                    <label
                      className="text-primary"
                      style={{ fontSize: 14, fontWeight: "600" }}
                    >
                      ITEM INFO
                    </label>
                  </Col>
                </Row>
                <div className="">
                  <div>
                    <Row>
                      <Col>
                        <Card className="mb-3">
                          <div className="">
                            <table className="table table-bordered mb-0">
                              <thead className="text-center">
                                <tr>
                                  <th scope="col" style={{ color: "grey" }}>
                                    ITEM DETAILS
                                  </th>
                                  {discountCheck.hsnCheck === "YES" && (
                                    <th scope="col" style={{ color: "grey" }}>
                                      HSN/SAC
                                    </th>
                                  )}
                                  {discountCheck.sizeCheck === "YES" && (
                                    <th scope="col" style={{ color: "grey" }}>
                                      SIZE
                                    </th>
                                  )}
                                  <th scope="col" style={{ color: "grey" }}>
                                    QUANTITY
                                  </th>
                                  <th scope="col" style={{ color: "grey" }}>
                                    RATE
                                  </th>
                                  {discountCheck.discountCheck === "YES" && (
                                    <th scope="col" style={{ color: "grey" }}>
                                      DISCOUNT
                                    </th>
                                  )}
                                  {bussinessRegistered === "YES" && (
                                    <th scope="col" style={{ color: "grey" }}>
                                      TAX
                                    </th>
                                  )}
                                  <th scope="col" style={{ color: "grey" }}>
                                    AMOUNT
                                  </th>
                                  <th scope="col" style={{ color: "grey" }}>
                                    ACTION
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {row.map((data, i) => {
                                  const amount = calculateAmount(
                                    data,
                                    data.tax
                                  );
                                  const totalAmountWithTax = parseFloat(amount);
                                  const itemDescription = itemDescriptions.find(
                                    (desc) =>
                                      desc.itemDetails === data.itemDetails
                                  );
                                  return (
                                    <tr key={i}>
                                      <td style={{ width: "35%" }}>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <input
                                            className="form-control noBorder"
                                            placeholder="Search items..."
                                            value={data.itemDetails}
                                            onChange={(e) =>
                                              handleItemDetailsChange(e, i)
                                            }
                                            style={{ flex: 1 }}
                                          />
                                          {data.itemDetails && (
                                            <button
                                              type="button"
                                              className="btn p-0"
                                              onClick={() =>
                                                handleItemShow(data.itemDetails)
                                              }
                                              style={{ marginLeft: "10px" }}
                                            >
                                              <span
                                                className="text-primary"
                                                style={{
                                                  fontSize: "12px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Edit
                                              </span>
                                            </button>
                                          )}
                                        </div>
                                        {itemDescription && (
                                          <div
                                            style={{
                                              marginTop: "10px",
                                              color: "#6c757d",
                                              whiteSpace: "pre-line",
                                            }}
                                          >
                                            {itemDescription.description}
                                          </div>
                                        )}
                                        {searchPerformed &&
                                          data.itemDetails &&
                                          i === searchIndex && (
                                            <div
                                              style={{
                                                position: "relative",
                                                marginTop: "10px",
                                              }}
                                            >
                                              {searchResults.length === 0 && ""}
                                              {searchResults.length > 0 && (
                                                <ul
                                                  style={{
                                                    listStyleType: "none",
                                                    padding: 0,
                                                    position: "absolute",
                                                    top: "calc(100% + 10px)",
                                                    left: 0,
                                                    zIndex: 1000,
                                                    backgroundColor: "#FFF",
                                                    boxShadow:
                                                      "0px 0px 10px rgba(0, 0, 0, 0.2)",
                                                    borderRadius: "5px",
                                                    width: "100%",
                                                    maxHeight: "200px", // Set a max height for the list
                                                    overflowY: "auto", // Enable vertical scrolling
                                                  }}
                                                >
                                                  {searchResults.map(
                                                    (result, index) => (
                                                      <li
                                                        key={index}
                                                        onClick={() =>
                                                          handleItemSelect(
                                                            result.name,
                                                            result.id,
                                                            i
                                                          )
                                                        }
                                                        onMouseEnter={(e) => {
                                                          e.target.style.backgroundColor =
                                                            "#408dfb";
                                                          e.target.style.color =
                                                            "#FFF";
                                                          e.target.style.borderRadius =
                                                            "5px"; // Apply border radius on hover
                                                        }}
                                                        onMouseLeave={(e) => {
                                                          e.target.style.backgroundColor =
                                                            "";
                                                          e.target.style.color =
                                                            "#000";
                                                          e.target.style.borderRadius =
                                                            ""; // Reset border radius
                                                        }}
                                                        style={{
                                                          padding: "12px",
                                                          cursor: "pointer",
                                                          whiteSpace: "nowrap", // Prevent text from overflowing
                                                          overflow: "hidden", // Hide overflow text
                                                          textOverflow:
                                                            "ellipsis", // Add ellipsis for overflowing text
                                                        }}
                                                      >
                                                        {result.name}
                                                        <br />
                                                        Rate : ₹
                                                        {result.taxableAmount}
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              )}
                                            </div>
                                          )}
                                      </td>
                                      <Modal
                                        show={showItemModal}
                                        onHide={handleItemClose}
                                      >
                                        <div class="modal-header d-flex align-items-center pb-2 pt-2">
                                          <h5
                                            class="modal-title fs-5"
                                            id="salespersonHead"
                                          >
                                            Add Item Description
                                          </h5>
                                          <button
                                            type="button"
                                            className="btn pr-0"
                                            onClick={handleItemClose}
                                          >
                                            <ion-icon
                                              name="close-circle-outline"
                                              size="large"
                                              style={{ width: "28px" }}
                                            ></ion-icon>
                                          </button>
                                        </div>
                                        <Modal.Body>
                                          <Row>
                                            <Col md="12">
                                              <div className="row pt-2">
                                                <div className="col-lg-6">
                                                  <label
                                                    className="form-label"
                                                    style={{
                                                      fontSize: "14px",
                                                      color: "grey",
                                                      borderRadius: "7px",
                                                    }}
                                                  >
                                                    Item
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="col-lg-12 pl-0 pr-0">
                                                <input
                                                  className="form-control"
                                                  placeholder="Name"
                                                  id="name"
                                                  name="name"
                                                  type="text"
                                                  value={itemName}
                                                  readOnly
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col md="12">
                                              <div className="row pt-2">
                                                <div className="col-lg-6">
                                                  <label
                                                    className="form-label"
                                                    style={{
                                                      fontSize: "14px",
                                                      color: "grey",
                                                      borderRadius: "7px",
                                                    }}
                                                  >
                                                    Description
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="col-lg-12 pl-0 pr-0">
                                                <textarea
                                                  className="cn-textarea"
                                                  type="text"
                                                  placeholder="Item Description"
                                                  name="description"
                                                  value={itemData.description}
                                                  onChange={handleItemChange}
                                                  rows={2}
                                                  style={{
                                                    backgroundColor: "#F6F6F6",
                                                    borderRadius: "9px",
                                                    padding: "12px",
                                                    width: "100%",
                                                  }}
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                        </Modal.Body>
                                        <Modal.Footer className="p-2">
                                          <div className="modal-bottombtns">
                                            <button
                                              type="button"
                                              class="modalbtn-1 btn mt-0"
                                              onClick={handleItemClose}
                                            >
                                              Close
                                            </button>
                                            <button
                                              type="button"
                                              class="modalbtn-3 btn btn-primary"
                                              onClick={handleItem}
                                              data-bs-dismiss="modal"
                                            >
                                              Add Description
                                            </button>
                                          </div>
                                        </Modal.Footer>
                                      </Modal>
                                      {discountCheck.hsnSacCheck === "YES" && (
                                        <td style={{ width: "10%" }}>
                                          <input
                                            className="form-control noArrowNumber noBorder"
                                            value={data.hsnSac || ""}
                                            name="hsnSac"
                                            type=""
                                            placeholder="hsnSac"
                                            onChange={(e) =>
                                              handleHsnSacChange(e, i)
                                            }
                                          />
                                        </td>
                                      )}

                                      {discountCheck.sizeCheck === "YES" && (
                                        <td style={{ width: "10%" }}>
                                          <input
                                            className="form-control noArrowNumber noBorder"
                                            value={data.size || ""}
                                            name="size"
                                            type=""
                                            placeholder="size"
                                            onChange={(e) =>
                                              handleSizeChange(e, i)
                                            }
                                          />
                                        </td>
                                      )}

                                      <td style={{ width: "10%" }}>
                                        <input
                                          className="form-control noArrowNumber noBorder"
                                          value={data.quantity || ""}
                                          type="text"
                                          onChange={(e) =>
                                            handleQuantityChange(e, i)
                                          }
                                          style={{ backgroundColor: "white" }}
                                        />
                                      </td>

                                      <td style={{ width: "10%" }}>
                                        <input
                                          className="form-control noArrowNumber noBorder"
                                          value={data.rate}
                                          type="text"
                                          onChange={(e) =>
                                            handleRateChange(e, i)
                                          }
                                          style={{ backgroundColor: "white" }}
                                        />
                                      </td>
                                      {discountCheck.discountCheck ===
                                        "YES" && (
                                        <td
                                          style={{
                                            width: "10%",
                                            padding: "0px",
                                          }}
                                        >
                                          <div className="d-flex justify-content-center">
                                            <input
                                              className="form-control col-md-6 noBorder"
                                              value={data.discount || ""}
                                              type="text"
                                              onChange={(e) =>
                                                handleDiscountChange(e, i)
                                              }
                                              name="discount"
                                              style={{
                                                textAlign: "center",
                                                padding: "0px",
                                                marginRight: "5px",
                                                backgroundColor: "white",
                                              }}
                                            />
                                            <select
                                              className="border-0"
                                              value={data.discountType}
                                              onChange={(e) =>
                                                handleDiscountChange(e, i)
                                              }
                                              name="discountType"
                                            >
                                              <option value="₹">₹</option>
                                              <option value="%">%</option>
                                            </select>
                                          </div>
                                        </td>
                                      )}
                                      {bussinessRegistered === "YES" && (
                                        <td style={{ width: "10%" }}>
                                          <select
                                            className="form-control"
                                            value={data.tax}
                                            onChange={(e) =>
                                              handleTaxChange(e, i)
                                            }
                                          >
                                            <option value="0">N/A</option>
                                            <option value="5">
                                              5 GST [5%]
                                            </option>
                                            <option value="12">
                                              12 GST [12%]
                                            </option>
                                            <option value="18">
                                              18 GST [18%]
                                            </option>
                                            <option value="28">
                                              28 GST [28%]
                                            </option>
                                          </select>
                                        </td>
                                      )}
                                      <td style={{ width: "10%" }}>
                                        <span>
                                          {parseFloat(
                                            totalAmountWithTax
                                          ).toFixed(2)}
                                        </span>
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{ width: "7%" }}
                                      >
                                        <button
                                          className="btn px-2 btn-light icon-border btn-sm"
                                          onClick={() => handleDeleteRow(i)}
                                        >
                                          <i className="fa fa-trash text-danger"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                    <button className="btn addline-btn" onClick={handleAdd}>
                      + Add Another Line
                    </button>
                  </div>

                  <div className="grid-container">
                    <div className="flex-item d-flex flex-column-reverse p-0">
                      <div className="row pt-3">
                        <div className="col-lg-6">
                          <label
                            className="form-label required mg-b-0"
                            style={{ fontSize: "14px", color: "grey" }}
                          >
                            Customer Notes
                          </label>
                        </div>
                        <div className="col-lg-12">
                          <textarea
                            className="cn-textarea"
                            placeholder="Customer Notes..."
                            name="customerNotes"
                            value={customerNotes}
                            onChange={(e) => setCustomerNotes(e.target.value)}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="subtotal-box">
                      <div className="flex-item">
                        <div className="row">
                          <div className="col-lg-8">
                            <label className="form-label required">
                              Sub Total
                            </label>
                          </div>
                          <div className="col-lg-4 text-end pl-0">
                            <label className="form-label required">
                              {calculateSubTotal()}
                            </label>
                          </div>
                        </div>
                        {discountCheck.discountCheck === "YES" && (
                          <div className="row d-flex align-items-baseline mt-1">
                            <div className="col-lg-5 pr-0">
                              <label>Discount</label>
                            </div>
                            <div className="col-lg-3 pl-0 d-flex">
                              <input
                                className="form-control"
                                value={disAllValue || "0"}
                                type="number"
                                name="discount"
                                onChange={handleDisAllValueChange}
                                style={{
                                  height: "36px",
                                  padding: "3px 8px",
                                  backgroundColor: "#F6F6F6",
                                }}
                              />
                              <select
                                className="form-control ml-1 pl-0 rounded"
                                name="discountType"
                                value={discountType}
                                onChange={handleDiscountTypeChange}
                                style={{
                                  height: "36px",
                                  width: "50px",
                                  padding: "3px 8px",
                                  backgroundColor: "#F6F6F6",
                                }}
                              >
                                <option disabled>Select</option>
                                <option value="₹">₹</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                            <div className="col-lg-4 text-end pl-0">
                              <label className="form-label textColor-red">
                                {isNaN(parseFloat(disValue).toFixed(2))
                                  ? "0.00"
                                  : -parseFloat(disValue).toFixed(2)}
                              </label>
                            </div>
                          </div>
                        )}

                        {bussinessRegistered === "YES" && (
                          <div className="row mt-3">
                            {userState.state !== customerState && (
                              <>
                                {calculateIGSTForTax().map(
                                  (taxResult, index) => (
                                    <React.Fragment key={index}>
                                      <div className="col-lg-8">
                                        <label className="form-label required">
                                          IGST (
                                          {(taxResult.taxRate * 100).toFixed(0)}
                                          %)
                                        </label>
                                      </div>
                                      <div className="col-lg-4 text-end pl-0">
                                        <label className="form-label required">
                                          {taxResult.totalIGST.toFixed(2)}
                                        </label>
                                      </div>
                                    </React.Fragment>
                                  )
                                )}
                              </>
                            )}

                            {userState.state === customerState && (
                              <>
                                {Object.entries(
                                  calculateGroupedSGSTandCGST()
                                ).map(([taxRate, taxData], index) => (
                                  <React.Fragment key={index}>
                                    <div className="col-lg-8">
                                      <label className="form-label required">
                                        CGST (
                                        {((parseFloat(taxRate) * 100) / 2)
                                          .toFixed(1)
                                          .replace(/\.0$/, "")}
                                        %)
                                      </label>
                                    </div>
                                    <div className="col-lg-4 text-end pl-0">
                                      <label className="form-label required">
                                        {taxData.totalCGST.toFixed(2)}
                                      </label>
                                    </div>
                                    <div className="col-lg-8 mt-2">
                                      <label className="form-label required">
                                        SGST (
                                        {((parseFloat(taxRate) * 100) / 2)
                                          .toFixed(1)
                                          .replace(/\.0$/, "")}
                                        %)
                                      </label>
                                    </div>
                                    <div className="col-lg-4 text-end pl-0 mt-2">
                                      <label className="form-label required">
                                        {taxData.totalSGST.toFixed(2)}
                                      </label>
                                    </div>
                                  </React.Fragment>
                                ))}
                              </>
                            )}
                          </div>
                        )}

                        <div className="row mt-3 mb-3">
                          <div className="col-lg-5 pr-0">
                            <input
                              className="form-control col-md-10"
                              type="text"
                              placeholder="Adjustment"
                              value={adjustmentLabel}
                              onChange={(e) =>
                                setAdjustmentLabel(e.target.value)
                              }
                              name="adjustmentLabel"
                              style={{
                                backgroundColor: "#F6F6F6",
                                borderRadius: "7px",
                                color: "black",
                              }}
                            />
                          </div>
                          <div className="col-lg-5 pl-0">
                            <input
                              className="form-control col-md-10"
                              type="text"
                              value={adjustmentValue}
                              onChange={(e) =>
                                setAdjustmentValue(e.target.value)
                              }
                              style={{
                                backgroundColor: "#F6F6F6",
                                borderRadius: "7px",
                                color: "black",
                              }}
                            />
                          </div>
                          <div className="col-lg-2 text-end pl-0">
                            <label className="form-label mt-2">
                              {oppositeValue}
                            </label>
                          </div>
                        </div>
                        <hr className="mt-0" />
                        <div className="row mt-3">
                          <div className="col-lg-8">
                            <label className="form-label required ">
                              Total (₹) :
                            </label>
                          </div>
                          <div className="col-lg-4 text-end pl-0">
                            <label className="form-label required">
                              {calculateTotal()}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-4" />

                  <div className="row pt-2 align-items-center">
                    <div className="col-lg-6">
                      <label
                        className="form-label required mg-b-0"
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Terms & Condition
                      </label>
                    </div>
                    <div className="col-lg-12">
                      <textarea
                        className="invoicetextarea"
                        placeholder="Add your terms & condition"
                        name="termsAndCondition"
                        value={termsAndCondition}
                        onChange={(e) => setTermsAndCondition(e.target.value)}
                        type="text"
                        rows={4}
                        style={{
                          backgroundColor: "#F6F6F6",
                          borderRadius: "9px",
                          padding: "12px",
                          height: "100%",
                        }}
                      />
                      <i className="text-muted">
                        This Terms and Condition will be displayed on the
                        challan
                      </i>
                    </div>
                  </div>
                </div>
              </>
            </div>
            <div
              className="text-right mt-5 mb-4"
              style={{ marginRight: "1.5rem" }}
            >
              <button
                type="button"
                className="btn-1 btn mt-0"
                onClick={handleCancleClick}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn-3 btn btn-primary"
                onClick={insertDataIntoDatabase}
              >
                {isLoading ? "Saving..." : "Create Challan"}
              </button>
            </div>
          </div>
        </>
      )}
      {showMailPage && (
        <>
          <div className="d-flex align-items-center ml-1 mt-4">
            <div
              className="cursor-pointer d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "white",
                borderRadius: "50%",
                height: "35px",
                width: "38px",
                cursor: "pointer",
              }}
            >
              <ArrowBackIosIcon
                width={2}
                onClick={handleChallansClick}
                className="arrow-child"
              />
            </div>
            <div className="headitems ml-2">
              <h5
                className="ml-1 mb-0"
                style={{ letterSpacing: "1px", color: "#086bd5de" }}
              >
                Mail
              </h5>
            </div>
          </div>
          <div
            className="profit-loss-section my-3 mx-0"
            style={{
              padding: "30px",
              backgroundColor: "#fff",
              borderRadius: "9px",
            }}
          >
            <br />
            <div className="radio row mg-t-15 pt-1 align-items-center mg-b-20">
              <div className="col-md-1">
                <label className="form-label">From</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="from"
                  id=""
                  name="from"
                  type="text"
                  value={userEmail}
                  onChange={handleUserEmail}
                />
              </div>
            </div>
            <br />
            <div className="radio row mg-t-10  align-items-left mg-b-20">
              <div className="col-md-1">
                <label className="form-label">Send To</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="Send To"
                  id=""
                  name="to"
                  type="text"
                  value={custEmail}
                  onChange={handleCustomerEmail}
                />
              </div>
            </div>
            <br />
            <div className="radio row mg-t-15 align-items-center mg-b-20">
              <div className="col-md-1">
                <label className="form-label">CC</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="CC"
                  id=""
                  name=""
                  type="text"
                />
              </div>
            </div>
            <br />
            <div className="radio row mg-t-15  align-items-center">
              <div className="col-md-1">
                <label className="form-label">Subject</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="Subject"
                  id=""
                  value={subjectMail}
                  name="subject"
                  type="text"
                  onChange={handleSubjectChange}
                />
              </div>
            </div>
            <br />
            <div className="radio col-md-12 row mg-t-15 align-items-center">
              <div className="col-md-1 pl-0">
                {/* <label className="form-label">
<b>Subject : </b>
</label> */}
              </div>
              <div className="col-md-10 pl-0">
                <div className="body">
                  <TinyMceEditor
                    onChange={setEditorContent}
                    initialContent={initialContent}
                  />

                  <div className="mx-10 mt-4">
                    <button
                      className="btn-1 btn mt-0"
                      onClick={handleChallansClick}
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="btn-3 btn btn-primary"
                      onClick={handleMailSubmit}
                    >
                      {isLoading3 ? "Sending..." : "Send"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default DeliveryChallansNew;
